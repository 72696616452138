import {forwardRef, useImperativeHandle, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import * as Yup from 'yup'

import {useFormik} from 'formik'

import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'

import {
  FullscreenControl,
  Map,
  Placemark,
  SearchControl,
  YMaps,
  ZoomControl,
} from '@pbe/react-yandex-maps'

export const MapModal = forwardRef((props: any, ref: any) => {
  const intl = useIntl()

  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)

  const [coords, setCoords] = useState<any>([])
  const [defaultMapLocation, setDefaultMapLocation] = useState({
    latitude: 41.31065,
    longitude: 69.279608,
  })

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  useImperativeHandle(ref, () => ({
    showModal() {
      handleShow()
    },
  }))

  const validationSchema = Yup.object().shape({})

  const initialValues = {}

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      props.formik?.setFieldValue('latitude', coords[0])
      props.formik?.setFieldValue('longitude', coords[1])

      setShow(false)
    },
  })

  const mapOnClick = async (e: any) => {
    if (!e) return

    let coords = await e.get('coords')
    setCoords([coords[0], coords[1]])
  }

  return (
    <Modal className='es-modal' size='lg' show={show} onHide={handleClose} centered>
      <Modal.Header>
        <h2 className='modal-title'>{intl.formatMessage({id: 'MENU.LOCATION'})}</h2>
        <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={handleClose}>
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            height: '600px',
            width: '100%',
          }}
        >
          <YMaps
            query={{
              apikey: process.env.REACT_APP_YMAPS_KEY,
              lang: 'ru_RU',
              load: 'package.full',
            }}
          >
            <Map
              onClick={mapOnClick}
              defaultState={{
                center: [defaultMapLocation.latitude, defaultMapLocation.longitude],
                zoom: 11,
                controls: [],
              }}
              options={{
                suppressMapOpenBlock: true,
              }}
              style={{width: '100%', height: '100%'}}
            >
              <FullscreenControl />
              <SearchControl
                options={{
                  float: 'left',
                }}
              />
              <ZoomControl />
              {coords && (
                <Placemark
                  geometry={coords}
                  options={{
                    preset: 'islands#icon',
                  }}
                />
              )}
            </Map>
          </YMaps>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type='button' className='btn btn-light' onClick={handleClose}>
          {intl.formatMessage({id: 'COMMON.CANCEL'})}
        </button>
        <button
          type='button'
          className='btn btn-success'
          onClick={() => {
            formik.handleSubmit()
          }}
          disabled={loading}
          data-kt-indicator={loading === true ? 'on' : 'off'}
        >
          <span className='indicator-label'>{intl.formatMessage({id: 'COMMON.SUBMIT'})}</span>

          <span className='indicator-progress'>
            {intl.formatMessage({id: 'COMMON.PLS_WAIT'})}{' '}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        </button>
      </Modal.Footer>
    </Modal>
  )
})
