import {FC} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'

import {toAbsoluteUrl} from '../../../helpers'
import {getMediaUrl} from '../../../helpers/general'
import {Languages} from './Languages'
import {useAuth} from '../../../../app/modules/auth/useAuth'

const HeaderUserMenu: FC = () => {
  const intl = useIntl()

  const {user, logOut}: any = useAuth()
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary  py-4 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img
              style={{objectFit: 'cover'}}
              src={
                user.image != ''
                  ? getMediaUrl('users', (user || {}).image)
                  : toAbsoluteUrl('/avatar.png')
              }
              alt={user.name}
            />
          </div>

          <div className='d-flex flex-column'>
            <div className='er d-flex align-items-center fs-5'>{user?.name}</div>
            <div className=' text-muted fs-5'>{user?.role || 'Admin'}</div>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/profile'} className='menu-link px-5'>
          {intl.formatMessage({id: 'COMMON.MY_PROFILE'})}
        </Link>
      </div>

      <Languages />

      <div className='menu-item px-5'>
        <a onClick={logOut} className='menu-link px-5'>
          {intl.formatMessage({id: 'COMMON.SIGN_OUT'})}
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
