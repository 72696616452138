import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {swalDefaultConfig} from '../../../../_metronic/helpers/constants'
import {getMediaUrl} from '../../../../_metronic/helpers/general'
import {swal} from '../../../../_metronic/helpers/swal'
import {PageTitle, useLayout} from '../../../../_metronic/layout/core'
import Loader from '../../../components/Loader'
import FooterCopyright from '../../../modules/FooterCopyright'
import {dateTimeFormat} from '../../../utils/helpers'
import {useAuth} from '../../../modules/auth/useAuth'
import axiosInstance from '../../../utils/axiosInstance'

export function CouriersItemIndex() {
  const intl = useIntl()
  const {classes} = useLayout()
  const {hasAccess} = useAuth()

  let {id} = useParams()

  // LOCAL STATE
  const [data, setData] = React.useState<any>({})
  const [isLoading, setIsLoading] = useState<boolean>(true)

  async function getData() {
    try {
      const res = await axiosInstance.get(`courier/${id}/`)
      setData(res.data)
      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      if (err.response.status === 404) {
        setIsLoading(false)
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
      {
        title: intl.formatMessage({id: 'COMMON.COURIER'}),
        path: '/couriers',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }

  if (isLoading) return <Loader />

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>
        {data?.name || intl.formatMessage({id: 'MENU.USER'})}
      </PageTitle>
      <div className='h-100 d-flex flex-column flex-column-fluid'>
        <div className='d-flex flex-column flex-xl-row'>
          <div className='flex-column flex-lg-row-auto w-100 w-xl-350px mb-10'>
            <div className='card mb-5 mb-xl-8'>
              <div className='card-body pt-15'>
                <div className='d-flex flex-center flex-column mb-5'>
                  <div className='symbol symbol-100px symbol-circle mb-7'>
                    <img
                      src={
                        data?.image
                          ? getMediaUrl('users', data?.image)
                          : toAbsoluteUrl('/avatar.png')
                      }
                      alt={data?.name}
                    />
                  </div>
                  <div className='fs-3 fw-bold mb-1'>{data?.name}</div>
                </div>
                <div className='d-flex flex-stack fs-4 py-3'>
                  <div
                    className=' rotate collapsible'
                    data-bs-toggle='collapse'
                    role='button'
                    aria-expanded='false'
                    aria-controls='kt_customer_view_details'
                  >
                    {intl.formatMessage({id: 'COMMON.DETAILS'})}
                  </div>
                  <div
                    className={clsx(
                      'badge badge-light-success ',
                      {'badge-light-success': data?.is_active},
                      {
                        'badge-light-danger': !data?.is_active,
                      }
                    )}
                  >
                    {data?.is_active
                      ? intl.formatMessage({id: 'COMMON.ACTIVE'})
                      : intl.formatMessage({id: 'COMMON.NOT_ACTIVE'})}
                  </div>
                </div>
                <div className='separator separator-dashed mb-5' />
                <div id='kt_customer_view_details' className='collapse show'>
                  <div className='fs-6'>
                    <div className='d-none'>
                      <div className='mb-5'>{intl.formatMessage({id: 'COMMON.ACCOUNT_ID'})}</div>
                      <div className='text-gray-600'>ID-{data?.id}</div>
                    </div>
                    <div className=''>{intl.formatMessage({id: 'COMMON.PHONE'})}</div>
                    <div className='mb-5 text-gray-600'>{data?.phone || '-'}</div>
                    <div className=''>{intl.formatMessage({id: 'COMMON.BRANCH'})}</div>
                    <div className='mb-5 text-gray-600'>{data?.terminal?.name_ru || '-'}</div>
                    <div className=''>{intl.formatMessage({id: 'COMMON.PIN_CODE'})}</div>
                    <div className='mb-5 text-gray-600'>{data?.terminal?.name_ru || '-'}</div>
                    <div className=''>{intl.formatMessage({id: 'COMMON.PASSWORD'})}</div>
                    <div className='mb-5 text-gray-600'>{data?.password}</div>
                    <div className=''>{intl.formatMessage({id: 'COMMON.LAST_VISIT'})}</div>
                    <div className='mb-5 text-gray-600'>{dateTimeFormat(data?.last_location)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* FOOTER */}
        <div className='card card-stretch py-4 d-flex flex-lg-column' id='kt_footer'>
          <div
            className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
          >
            <div className='order-1 d-flex align-items-center mb-2 mb-md-0'></div>

            {<FooterCopyright />}
          </div>
        </div>
      </div>
    </>
  )
}
