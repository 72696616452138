import React, {forwardRef, useImperativeHandle, useState} from 'react'
import Modal from 'react-bootstrap/Modal'

import 'react-datepicker/dist/react-datepicker.css'

import {useIntl} from 'react-intl'

export const ProductModifierModal = forwardRef((props: any, ref: any) => {
  const intl = useIntl()

  const [modifiersArr, setModifiersArr] = useState([])

  const [show, setShow] = useState(false)

  const handleClose = () => {
    setShow(false)
  }
  const handleShow = () => {
    setShow(true)

    props.setSelectedModifiers([])
  }

  useImperativeHandle(ref, () => ({
    showModal() {
      handleShow()
    },
  }))

  if (!props.productWithModifier) return <div></div>

  const onModSelect = (modifier: {group_id: any; modifier_id: any; max_amount: number}) => {
    let currentModifiersGroups = props.selectedModifiers.filter(
      (x: {group_id: any}) => x.group_id == modifier.group_id
    )
    let otherModifiers = props.selectedModifiers.filter(
      (x: {group_id: any}) => x.group_id != modifier.group_id
    )
    let isModAdded = currentModifiersGroups.find(
      (x: {modifier_id: any}) => x.modifier_id == modifier.modifier_id
    )
    if (!!isModAdded) {
      currentModifiersGroups = currentModifiersGroups.filter(
        (x: {modifier_id: any}) => x.modifier_id != modifier.modifier_id
      )
      props.setSelectedModifiers([...otherModifiers, ...currentModifiersGroups])

      return
    }

    currentModifiersGroups.push({...modifier, amount: 1})
    let currentModifiers = currentModifiersGroups.filter(
      (x: {group_id: any}) => x.group_id == modifier.group_id
    )
    currentModifiers = currentModifiers.slice(-(modifier.max_amount || 1))

    currentModifiersGroups = currentModifiersGroups.filter(
      (x: {modifier_id: any}) => x.modifier_id != modifier.modifier_id
    )
    currentModifiersGroups.push(...currentModifiers)

    // group max amount
    let modifiergroup = props.productWithModifier.group_modifiers.find(
      (x: any) => x.id == modifier.group_id
    )
    currentModifiersGroups = currentModifiersGroups.slice(-((modifiergroup || {}).max_amount || 1))

    props.setSelectedModifiers([...otherModifiers, ...currentModifiersGroups])

    return
  }

  const addModifierToList = (mod = {modifier_id: '', amount: 0, max_amount: 1}, arr: any) => {
    const modArr = arr.map((x: any) => x)
    const itemIdx = modArr.findIndex((x: any) => x.id == mod.modifier_id)
    if (!modArr.length || itemIdx < 0) {
      modArr.push({
        id: mod.modifier_id,
        amount: 1,
      })
      setModifiersArr(modArr)
      return
    }

    if (!(mod.max_amount > mod.amount)) return

    modArr[itemIdx].amount += 1

    setModifiersArr(modArr)
    return
  }

  const removeModifierFromList = (mod = {modifier_id: '', amount: 0, min_amount: 0}, arr: any) => {
    let modArr = arr.map((x: any) => x)

    const itemIdx = modArr.findIndex((x: any) => x.id === mod.modifier_id)

    if (itemIdx < 0) return

    if (mod.min_amount > modArr[itemIdx].amount - 1) return
    modArr[itemIdx].amount -= 1

    modArr = modArr.filter((x: any) => x.amount !== 0)
    setModifiersArr(modArr)
    return
  }

  const getModifierQty = (
    mod: {modifier_id: any; min_amount: number},
    modArr: {id: any; amount: number}[] = []
  ) => {
    if (!mod.modifier_id) return 0
    const modCurrAmount = (modArr.find((x: any) => x.id === mod.modifier_id) || {amount: 0}).amount
    if (mod.min_amount > modCurrAmount) {
      modArr.push({
        id: mod.modifier_id,
        amount: mod.min_amount,
      })
      getModifierQty(mod, modArr)
    }
    return modCurrAmount
  }

  return (
    <Modal size='lg' show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{(props.productWithModifier || {}).name_ru}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {props.productWithModifier.group_modifiers.map(
            (
              y: {
                id: any
                modifier_id: any
                group_id: string
                name_ru:
                  | boolean
                  | React.ReactChild
                  | React.ReactFragment
                  | React.ReactPortal
                  | null
                  | undefined
                child: any[]
              },
              index: number
            ) => {
              return (
                <div key={'modifier-group' + y.group_id}>
                  <h2>{y.name_ru}</h2>
                  <div className='row mb-5'>
                    {y.child &&
                      (y.child || []).map(
                        (x: {
                          id: any
                          amount: number
                          max_amount: number
                          min_amount: number
                          cartIdx: any
                          modifier_id: string
                          name_ru:
                            | boolean
                            | React.ReactChild
                            | React.ReactFragment
                            | React.ReactPortal
                            | null
                            | undefined
                        }) => {
                          return (
                            <div key={'mod-child' + x.modifier_id} className='col-md-4 mb-2'>
                              <label
                                className='cursor-pointer ps-2'
                                htmlFor={`pr-modifier-id-${x.modifier_id}`}
                              >
                                <input
                                  id={`pr-modifier-id-${x.modifier_id}`}
                                  name={`mName-${y.group_id}`}
                                  type='checkbox'
                                  value={`mValue-${x.modifier_id}`}
                                  className='me-2'
                                  checked={
                                    !!props.selectedModifiers.find(
                                      (sMod: {modifier_id: string}) =>
                                        sMod.modifier_id == x.modifier_id
                                    )
                                  }
                                  onChange={() => {
                                    // @ts-ignore:
                                    onModSelect(x)
                                  }}
                                />
                                {x.name_ru}
                              </label>
                              {/* <div className='d-flex'>
                          <div>
                            <button
                              className='btn btn-primary p-1 px-3'
                              onClick={() => removeModifierFromList(x, modifiersArr)}
                            >
                              -
                            </button>
                          </div>
                          <div className='d-flex align-items-center justify-content-center px-3'>
                            {getModifierQty(x, modifiersArr)}
                          </div>
                          <div>
                            <button
                              className='btn btn-primary p-1 px-3'
                              onClick={() => addModifierToList(x, modifiersArr)}
                            >
                              +
                            </button>
                          </div>
                        </div> */}
                            </div>
                          )
                        }
                      )}
                  </div>
                  <hr />
                </div>
              )
            }
          )}
          <button
            className='btn btn-primary'
            onClick={() => {
              props.addToCart(props.productWithModifier.id, 1, props.selectedModifiers)
              handleClose()
            }}
          >
            {intl.formatMessage({id: 'COMMON.ADD'})}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
})
