import {useIntl} from 'react-intl'
import {Link, useNavigate, useParams} from 'react-router-dom'

import {lazy, useState} from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import {useQuery} from 'react-query'

import {FullscreenControl, Map, Placemark, YMaps, ZoomControl} from '@pbe/react-yandex-maps'
import {swalDefaultConfig} from '../../../_metronic/helpers/constants'
import {swal} from '../../../_metronic/helpers/swal'
import {SYSTEMS} from '../../utils/constants'
import {
  dateTimeFormat,
  getDistanceInKm,
  getOrderTypeText,
  getPriceFormatted,
  getTwoPointCenter,
} from '../../utils/helpers'

import {PageTitle} from '../../../_metronic/layout/core'
import FormErrorComponent from '../../components/FormErrorComponent'
import FooterCopyright from '../../modules/FooterCopyright'
import axiosInstance from '../../utils/axiosInstance'

const LoadingDataComponent = lazy(() => import('../../components/LoadingDataComponent'))
const AlertModal = lazy(() => import('../../components/AlertModal'))
const OrderLogs = lazy(() => import('./components/Modals/OrderLogs'))

export function ViewOrder() {
  const {id} = useParams()
  const intl = useIntl()
  const navigate = useNavigate()

  if (!id) navigate('/orders')

  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [showLogsModal, setShowLogsModal] = useState(false)

  const getData = async () => {
    const response = await axiosInstance.get(`/orders/${id}/`)
    return response.data
  }

  const {data, isLoading, error} = useQuery({
    queryKey: ['order', id],
    queryFn: getData,
    keepPreviousData: true,
  })

  const cancelOrder = async () => {
    setShowDeleteAlert(false)
    try {
      const response = await axiosInstance.patch(`/orders/cancel/${data.id}/`)
    } catch (err: any) {
      swal.fire(
        swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_UPDATE'}), 'success')
      )
    }
  }

  const handelCancelOrder = async () => {
    setShowDeleteAlert(true)
  }

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
      {
        title: intl.formatMessage({id: 'MENU.ORDERS'}),
        path: '/orders',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }
  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>
        {`${intl.formatMessage({id: 'COMMON.ORDER'})} #${id}`}
      </PageTitle>
      <div className='h-100'>
        <FormErrorComponent error={error ? error.toString() : null} />
        <LoadingDataComponent show={isLoading} />

        {data ? (
          <>
            <div className='row'>
              <div className='col-md-4'>
                <div className='card card-stretch mb-3'>
                  <div className='card-header'>
                    <h2 className='card-title'>Информация</h2>
                    <div className='card-toolbar'>
                      {data && data.order_logs && (
                        <button
                          className='btn btn-info btn-sm'
                          onClick={() => setShowLogsModal(true)}
                        >
                          Журнал событий
                        </button>
                      )}
                    </div>
                  </div>
                  <div className='card-body'>
                    <table className='table table-hover table-row-dashed table-row-gray-300'>
                      <tbody>
                        <tr>
                          <td className='fw-semibold'>Филиал</td>
                          <td>{data.terminal.name_ru}</td>
                        </tr>
                        <tr>
                          <td className='fw-semibold'>Номер чека</td>
                          <td>{data.number}</td>
                        </tr>
                        <tr>
                          <td className='fw-semibold'>Клиент</td>
                          <td>
                            <Link to={`/account/${data.account_id}`}>{data.name}</Link>
                          </td>
                        </tr>
                        <tr>
                          <td className='fw-semibold'>Номер телефона</td>
                          <td>
                            <a href={`tel:+${data.phone}`}>{data.phone}</a>
                          </td>
                        </tr>
                        <tr>
                          <td className='fw-semibold'>Второй номер</td>
                          <td>{data.second_phone}</td>
                        </tr>
                        <tr>
                          <td className='fw-semibold'>Тип заказа</td>
                          <td>{getOrderTypeText(data.is_self_service)}</td>
                        </tr>
                        <tr>
                          <td className='fw-semibold'>Дата поставки</td>
                          <td>{dateTimeFormat(data.delivery_date)}</td>
                        </tr>
                        <tr>
                          <td className='fw-semibold'>Дата оформления</td>
                          <td>{dateTimeFormat(data.created_at)}</td>
                        </tr>
                        <tr>
                          <td className='fw-semibold'>Способ оплаты</td>
                          <td>{data.payment_type.name_ru}</td>
                        </tr>
                      </tbody>
                    </table>

                    {data.status === 0 && (
                      <button className='btn btn-danger w-100 mt-3' onClick={handelCancelOrder}>
                        Отменить
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div className='col-md-4'>
                <div className='card card-stretch mb-3'>
                  <div className='card-header'>
                    <h2 className='card-title'>Детали</h2>
                  </div>
                  <div className='card-body'>
                    <table className='table table-hover table-row-dashed table-row-gray-300'>
                      <tbody>
                        <tr>
                          <td className='fw-semibold'>Статус</td>
                          <td>{data.statuses.description_ru}</td>
                        </tr>
                        {!data.is_self_service && (
                          <>
                            <tr>
                              <td colSpan={2}>
                                <p className='mb-1 fw-semibold'>Адрес</p>
                                {data.commentary}
                              </td>
                            </tr>
                            <tr>
                              <td className='fw-semibold'>Ориентир</td>
                              <td>{data.point}</td>
                            </tr>
                            <tr>
                              <td className='fw-semibold'>Дистанция</td>
                              <td>{getDistanceInKm(data.distance)} км</td>
                            </tr>
                            <tr>
                              <td className='fw-semibold'>Курьер</td>
                              <td>{data.courier ? data.courier.name : '-'}</td>
                            </tr>
                          </>
                        )}
                        <tr>
                          <td className='fw-semibold'>Система</td>
                          <td>{SYSTEMS[data.system]}</td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <p className='mb-1 fw-semibold'>Комментарий</p>
                            {data.comment}
                          </td>
                        </tr>
                        {data.claim_id && (
                          <tr>
                            <td colSpan={2}>
                              <p className='mb-1 fw-semibold'>Яндекс Доставка</p>
                              {data.claim_id}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {!data.is_self_service && !data.claim_id && (
                      <button className='btn btn-warning w-100 mt-3'>
                        Отправить в Яндекс Доставка
                      </button>
                    )}
                  </div>
                </div>
              </div>

              {!data.is_self_service && data.latitude && data.longitude ? (
                <div className='col-md-4'>
                  <div className='card card-stretch mb-3'>
                    <div className='card-header'>
                      <h2 className='card-title'>
                        {intl.formatMessage({id: 'COMMON.DELIVERY_MAPS'})}
                      </h2>
                    </div>
                    <div className='card-body p-0'>
                      <YMaps
                        query={{
                          apikey: process.env.REACT_APP_YMAPS_KEY,
                          lang: 'ru_RU',
                          load: 'package.full',
                        }}
                      >
                        <Map
                          defaultState={{
                            center: getTwoPointCenter(
                              data.terminal.latitude,
                              data.terminal.longitude,
                              data.latitude,
                              data.longitude
                            ),
                            zoom: 12,
                            controls: [],
                          }}
                          options={{
                            suppressMapOpenBlock: true,
                          }}
                          modules={['geocode']}
                          style={{width: '100%', height: '450px'}}
                        >
                          <FullscreenControl />
                          <ZoomControl />
                          <Placemark
                            geometry={[data.terminal.latitude, data.terminal.longitude]}
                            options={{
                              preset: 'islands#redStretchyIcon',
                            }}
                            properties={{
                              iconContent: `${data.terminal.name_ru}`,
                              hintContent: `${data.terminal.address_ru}`,
                            }}
                          />
                          <Placemark
                            geometry={[data.latitude, data.longitude]}
                            options={{
                              preset: 'islands#blueStretchyIcon',
                            }}
                            properties={{
                              iconContent: `${data.name}`,
                              hintContent: `${data.commentary}`,
                            }}
                          />
                        </Map>
                      </YMaps>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>

            <div className='card card-stretch'>
              <div className='card-header'>
                <h2 className='card-title'>{intl.formatMessage({id: 'COMMON.PRODUCTS'})}</h2>
              </div>
              <div className='card-body'>
                <table className='table table-hover table-row-dashed table-row-gray-300'>
                  <thead className='bg-light'>
                    <tr className='fw-bold'>
                      <th className='w-25px'>#</th>
                      <th>Товар</th>
                      <th>Опции</th>
                      <th>Комментарий</th>
                      <th className='text-center'>Количество</th>
                      <th className='text-end'>Цена</th>
                      <th className='text-end'>Сумма</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.items.map((item, index) => (
                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td>{item.name}</td>
                        <td>
                          {item.modifiers.map((m, idx) => (
                            <div key={m.id}>
                              {m.name} - {m.price}
                            </div>
                          ))}
                        </td>
                        <td>{item.comment}</td>
                        <td className='text-center'>{item.amount}</td>
                        <td className='text-end'>{getPriceFormatted(item.price)}</td>
                        <td className='text-end fw-bold'>
                          {getPriceFormatted(item.price * item.amount)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot className='bg-light fw-bold'>
                    <tr>
                      <td></td>
                      <td className='text-end' colSpan={5}>
                        Сумма заказа:
                      </td>
                      <td className='text-end'>{getPriceFormatted(data.full_sum)}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td className='text-end' colSpan={5}>
                        Стоимость доставки:
                      </td>
                      <td className='text-end'>{getPriceFormatted(data.delivery_price)}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td className='text-end' colSpan={5}>
                        Всего к оплате:
                      </td>
                      <td className='text-end'>
                        {getPriceFormatted(data.delivery_price + data.full_sum)}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <AlertModal
        isOpen={showDeleteAlert}
        type='danger'
        title='Отмена'
        message='Вы действительно хотите отменить этот заказ?'
        onClose={() => {
          setShowDeleteAlert(false)
        }}
        onConfirm={cancelOrder}
      />
      {data && data.order_logs.length > 0 && (
        <OrderLogs
          show={showLogsModal}
          logs={data.order_logs}
          onClose={() => {
            setShowLogsModal(false)
          }}
        />
      )}

      <div className='card card-stretch shadow py-4 d-flex flex-lg-column' id='kt_footer'>
        <div
          className={`container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between`}
        >
          {<FooterCopyright />}
        </div>
      </div>
    </>
  )
}
