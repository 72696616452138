export interface IUser {
  id: number
  name: string
  phone?: string
  username: string
  email?: string
  image?: string
}

export const getStorageItem = (key: string) => {
  return localStorage.getItem(key) || ''
}
export const setStorageItem = (key: string, value: string) => {
  localStorage.setItem(key, value)
}
export const removeStorageItem = (key: string) => {
  localStorage.removeItem(key)
}
