import moment from 'moment'
import React, {FC, useRef} from 'react'
import ReactApexChart from 'react-apexcharts'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import {useIntl} from 'react-intl'
import {useSearchParams} from 'react-router-dom'
import {daysOfWeek, monthNames, swalDefaultConfig} from '../../../_metronic/helpers/constants'
import {convertToPrice} from '../../../_metronic/helpers/general'
import {swal} from '../../../_metronic/helpers/swal'
import {PageTitle, useLayout} from '../../../_metronic/layout/core'
// @ts-ignore
import * as XLSX from 'xlsx'
import Loader from '../../components/Loader'
import FooterCopyright from '../../modules/FooterCopyright'
import axiosInstance from '../../utils/axiosInstance'

const StatisticsOrderTypeIndex: FC = () => {
  const intl = useIntl()
  const {classes} = useLayout()
  let [searchParams, setSearchParams] = useSearchParams()
  const windowSize = useRef([window.innerWidth, window.innerHeight])

  // FILTERS
  let dateFrom = searchParams.get('dateFrom') || moment().format('YYYY-MM-DD')
  let dateTo = searchParams.get('dateTo') || moment().format('YYYY-MM-DD')

  const [data, setData] = React.useState<any>([])
  const [total, setTotal] = React.useState(0)
  const [isLoading, setIsLoading] = React.useState(true)
  const [error, setError] = React.useState(null)

  const [showTableBottonId, setShowTableBottonId] = React.useState(null)
  const [apexSellingCountByDay, setApexSellingCountByDay] = React.useState<any>([])

  const getQueryObject = (): any => {
    let obj: {
      dateFrom?: string
      dateTo?: string
    } = {}

    if (!!dateFrom) obj.dateFrom = dateFrom
    if (!!dateTo) obj.dateTo = dateTo

    return obj
  }
  const buildQueryParams = () => {
    let query = `?`

    if (!!dateFrom) query += `start_date=${dateFrom}`
    if (!!dateTo) query += `&end_date=${dateTo}`

    return query
  }

  async function getData() {
    try {
      const res = await axiosInstance.get(`/stats/order-type/${buildQueryParams()}`)
      setApexSellingCountByDay([])
      setData(res.data || [])
      getCartPrice(res.data, 'total')

      data?.map((item: any, index: number) => {
        // SELLING COUNT BY DAY CHART
        let newApexSellingCountByDay: any = {
          series: [
            {
              name: `${intl.formatMessage({id: 'COMMON.AMOUNT'})}`,
              data: [],
            },
          ],
          options: {
            colors: ['#17a2b8'],
            chart: {
              height: 350,
              type: 'bar',
              toolbar: {show: !1},
            },
            plotOptions: {
              bar: {
                borderRadius: 10,
                columnWidth: '50%',
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              width: 2,
            },

            grid: {
              row: {
                colors: ['#fff', '#f2f2f2'],
              },
            },
            xaxis: {
              labels: {
                rotate: -45,
              },
              categories: [],
              tickPlacement: 'on',
            },
            yaxis: {
              title: {
                text: `${intl.formatMessage({id: 'COMMON.AMOUNT'})}`,
              },
            },
            // fill: {
            //   type: 'gradient',
            //   gradient: {
            //     shade: 'light',
            //     type: 'horizontal',
            //     shadeIntensity: 0.25,
            //     gradientToColors: undefined,
            //     inverseColors: true,
            //     opacityFrom: 0.85,
            //     opacityTo: 0.85,
            //     stops: [50, 0, 100],
            //   },
            // },
          },
        }

        newApexSellingCountByDay.series[0].data = item.apex_chart.map((x: any) => x.count)
        newApexSellingCountByDay.options.xaxis.categories = item.apex_chart.map((x: any) =>
          moment(x.date).format('DD/MM/YYYY')
        )
        setApexSellingCountByDay((prev: any) => [...prev, {...newApexSellingCountByDay}])
      })
      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      if (err.response.status === 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      }
      if (err.response.status === 500) {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
      setError(err)
    }
  }

  React.useEffect(() => {
    setData([])
    getData()
  }, [searchParams])

  const handleDateRangePickerCallback = (start: any, end: any, label: any) => {
    setSearchParams({
      ...getQueryObject(),
      dateFrom: start.format('YYYY-MM-DD'),
      dateTo: end.format('YYYY-MM-DD'),
    })
  }

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
      {
        title: intl.formatMessage({id: 'MENU.STATS'}),
        path: '',
        isSeparator: false,
        isActive: false,
      },
      {
        title: intl.formatMessage({id: 'COMMON.CURRENT'}),
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }

  const getCartPrice = (arr: any = [], key = 'total') => {
    let sum = arr.reduce((acc: any, curr: any) => {
      if (!!curr[key]) {
        return acc + curr[key]
      }
      return acc + curr.full_sum
    }, 0)
    return setTotal(sum)
  }

  const getDataContent = (arr: any) => {
    if (!arr || !arr.length) return []
    let content = []
    for (const [i, value] of arr.entries()) {
      content.push(
        <>
          <tr
            key={'list-' + value.services?.id}
            className='c-pointer'
            onClick={() => {
              if (i === showTableBottonId) {
                setShowTableBottonId(null)
                return
              }
              setShowTableBottonId(i)
            }}
          >
            <td style={{width: 40}}>{i + 1}</td>
            <td style={{width: '40%'}}>{value.name || ''}</td>
            <td className='text-center'>{convertToPrice(value?.count || 0)}</td>
            <td className='text-center'>{convertToPrice(Math.round(value?.avg || 0))}</td>
            <td className='text-end'>
              {convertToPrice(value?.full_sum)} {intl.formatMessage({id: 'COMMON.SUM'})}
            </td>
          </tr>
          {showTableBottonId === i ? (
            <tr>
              <td colSpan={8} className=''>
                <table className='table table-rounded bg-gray-100'>
                  <thead>
                    <tr className=' bg-light'>
                      <th style={{width: 40}}>#</th>
                      <th style={{width: '35%'}}>{intl.formatMessage({id: 'COMMON.DATE'})}</th>
                      <th className='text-center'>{intl.formatMessage({id: 'COMMON.AMOUNT'})}</th>
                      <th className='text-center'>{intl.formatMessage({id: 'COMMON.AVG'})}</th>
                      <th className='text-end'>{intl.formatMessage({id: 'COMMON.TOTAL'})}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {value.apex_chart.map((v: any, i: number) => (
                      <tr key={'list-' + i + 60} className='c-pointer'>
                        <td style={{width: 40}}>{`${i + 1}.${i + 1}`}</td>
                        <td style={{width: '40%'}}>{moment(v.date).format('DD/MM/YYYY') || ''}</td>
                        <td className='text-center'>{convertToPrice(v?.count || 0)}</td>
                        <td className='text-center'>{convertToPrice(Math.round(v?.avg || 0))}</td>
                        <td className='text-end'>
                          {convertToPrice(v?.full_sum)} {intl.formatMessage({id: 'COMMON.SUM'})}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </td>
            </tr>
          ) : null}
        </>
      )
    }

    return content
  }

  const exportToExcel = () => {
    let newData: any = []
    data.map((d: any) => {
      newData.push({
        Название: d.name,
        Количество: d.count,
        Сред: d.avg || 0,
        Итого: d.full_sum,
      })
      d.apex_chart.map((s: any) => {
        newData.push({
          Название: moment(s.date).format('DD/MM/YYYY') || '',
          Количество: s.count,
          Сред: s.avg || 0,
          Итого: s.full_sum,
        })
      })
    })
    newData.push({
      "Итого'": convertToPrice(total),
    })

    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const fileName = 'Выручка'

    const ws = XLSX.utils.json_to_sheet(newData)
    const wb = {Sheets: {data: ws}, SheetNames: ['data']}
    const excelBuffer = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array',
    })
    const dataBlob = new Blob([excelBuffer], {type: fileType})
    const url = URL.createObjectURL(dataBlob)
    const link = document.createElement('a')
    link.href = url
    link.download = fileName + fileExtension
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  if (isLoading) return <Loader />

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>
        {intl.formatMessage({id: 'MENU.ORDER_TYPE'})}
      </PageTitle>
      <div className='h-100 d-flex flex-column flex-column-fluid'>
        <div className='card card-stretch mb-2'>
          <div className='card-header'>
            <div className='d-flex align-items-center justify-content-center'>
              <div className='input-group-sm cs-daterangepicker-wrapper'>
                <DateRangePicker
                  initialSettings={{
                    startDate: moment(),
                    endDate: moment(),
                    ranges: {
                      [intl.formatMessage({id: 'COMMON.TODAY'})]: [moment(), moment()],
                      [intl.formatMessage({id: 'COMMON.YESTERDAY'})]: [
                        moment().subtract(1, 'days'),
                        moment().subtract(1, 'days'),
                      ],
                      [intl.formatMessage({id: 'COMMON.LAST_7_DAYS'})]: [
                        moment().subtract(6, 'days'),
                        moment(),
                      ],
                      [intl.formatMessage({id: 'COMMON.LAST_30_DAYS'})]: [
                        moment().subtract(29, 'days'),
                        moment(),
                      ],
                      [intl.formatMessage({id: 'COMMON.THIS_MONTH'})]: [
                        moment().startOf('month'),
                        moment().endOf('month'),
                      ],
                      [intl.formatMessage({id: 'COMMON.LAST_MONTH'})]: [
                        moment().subtract(1, 'month').startOf('month'),
                        moment().subtract(1, 'month').endOf('month'),
                      ],
                    },
                    locale: {
                      format: 'DD/MM/YYYY',
                      separator: ' - ',
                      applyLabel: 'Подтвердить',
                      cancelLabel: 'Отменить',
                      fromLabel: 'From',
                      toLabel: 'To',
                      customRangeLabel: 'Пользовательский',
                      daysOfWeek: daysOfWeek,
                      monthNames: monthNames,
                      firstDay: 1,
                    },
                  }}
                  onCallback={handleDateRangePickerCallback}
                >
                  <input type='text' className='form-control' />
                </DateRangePicker>
              </div>
            </div>
            <div className='card-toolbar'>
              <div className='ms-3'>
                <button className='btn btn-success' data-bs-toggle='modal' onClick={exportToExcel}>
                  {intl.formatMessage({id: 'COMMON.DOWNLOAD'})}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className='card card-stretch mb-2 flex-column-fluid main-overflow-x'
          style={{height: windowSize.current[1] - 320}}
        >
          <div className='card-body'>
            <div className='table-responsive'>
              <table className='table border table-rounded table-sm table-row-dashed table-row-gray-300 gy-3 gs-2'>
                <thead>
                  <tr className=' bg-light'>
                    <th style={{width: 40}}>#</th>
                    <th>{intl.formatMessage({id: 'COMMON.NAME'})}</th>
                    <th className='text-center'>{intl.formatMessage({id: 'COMMON.AMOUNT'})}</th>
                    <th className='text-center'>{intl.formatMessage({id: 'COMMON.AVG'})}</th>
                    <th className='text-end'>{intl.formatMessage({id: 'COMMON.TOTAL'})}</th>
                  </tr>
                </thead>
                <tbody>{getDataContent(data)}</tbody>
                <tfoot className=' bg-light'>
                  <tr>
                    <td></td>
                    <td>{intl.formatMessage({id: 'COMMON.TOTAL'})}</td>
                    <td></td>
                    <td></td>
                    <td className='text-end'>
                      {convertToPrice(total)} {intl.formatMessage({id: 'COMMON.SUM'})}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div className='row'>
              <div className='col-md-3 offset-md-9'>
                <div className='col-md-12 mb-5 mt-5'>
                  <div className='d-flex justify-content-between'>
                    <span>{intl.formatMessage({id: 'COMMON.TOTAL'})}</span>
                    <span>
                      {convertToPrice(total)} {intl.formatMessage({id: 'COMMON.SUM'})}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row gy-5 g-xl-8'>
            {data.map((d: any, index: number) => (
              <div className='col-xxl-12'>
                <div className='card card-flush'>
                  <div className='card-body pt-2 ps-6'>
                    <div className='d-flex flex-stack flex-wrap flex-grow-1 pt-5'>
                      <div className='me-2'>
                        <span className=' text-gray-800 d-block fs-3'>
                          {intl.formatMessage({id: 'COMMON.NUMBER_OF_SALES_BY_DAY'})} - {''}
                          {d.name}
                        </span>

                        <span className='text-gray-500 '>
                          {moment(dateFrom).format('DD/MM/YYYY')} -{' '}
                          {moment(dateTo).format('DD/MM/YYYY')}
                        </span>
                      </div>
                    </div>

                    {apexSellingCountByDay.length > 0 && (
                      <>
                        <ReactApexChart
                          options={apexSellingCountByDay[index]?.options || {}}
                          series={apexSellingCountByDay[index]?.series || {}}
                          type='bar'
                          height={450}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* FOOTER */}
        <div className='card card-stretch py-4 d-flex flex-lg-column' id='kt_footer'>
          <div
            className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
          >
            <div className='order-1 d-flex align-items-center mb-2 mb-md-0'>
              {/* <div className='dataTables_length' id='kt_ecommerce_report_sales_table_length'>
                <label>
                  <select
                    name='kt_ecommerce_report_sales_table_length'
                    aria-controls='kt_ecommerce_report_sales_table'
                    className='form-select form-select-sm form-select-solid'
                    value={page_size}
                    onChange={(e) =>
                      setSearchParams({...getQueryObject(), page_size: e.target.value})
                    }
                  >
                    <option value='10'>10</option>
                    <option value='25'>25</option>
                    <option value='50'>50</option>
                    <option value='100'>100</option>
                  </select>
                </label>
              </div> */}

              {/* <ReactPaginate
                breakLabel='...'
                nextLabel='>'
                previousLabel='<'
                onPageChange={handlePaginationClick}
                initialPage={page - 1}
                pageRangeDisplayed={2}
                pageCount={paginationData.totalPageCount}
                renderOnZeroPageCount={null}
                containerClassName='pagination'
                pageClassName='page-item'
                pageLinkClassName='page-link'
                breakClassName='page-item'
                breakLinkClassName='page-link'
                activeClassName='active'
                previousClassName='page-item previous'
                previousLinkClassName='page-link'
                nextClassName='page-item next'
                nextLinkClassName='page-link'
                disabledClassName='disabled'
              /> */}
            </div>

            {<FooterCopyright />}
          </div>
        </div>
      </div>
    </>
  )
}

export {StatisticsOrderTypeIndex}
