import React, {useCallback, useState} from 'react'
import {useIntl} from 'react-intl'
import ImageViewer from 'react-simple-image-viewer'
import {KTSVG} from '../../../../_metronic/helpers'

import {buildQueryData, convertToPrice, getMediaUrl} from '../../../../_metronic/helpers/general'

import ReactPaginate from 'react-paginate'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {swalDefaultConfig} from '../../../../_metronic/helpers/constants'
import {swal} from '../../../../_metronic/helpers/swal'
import {PageTitle, useLayout} from '../../../../_metronic/layout/core'
import Loader from '../../../components/Loader'
import FooterCopyright from '../../../modules/FooterCopyright'
import {AddModal} from './components/AddModal'
import {ItemModal} from './components/ItemModal'
import axiosInstance from '../../../utils/axiosInstance'
import {useAuth} from '../../../modules/auth/useAuth'

export function GroupsProductsIndex() {
  const intl = useIntl()
  const {classes} = useLayout()
  const {hasAccess} = useAuth()
  let navigate = useNavigate()
  let [searchParams, setSearchParams] = useSearchParams()
  const windowSize = React.useRef([window.innerWidth, window.innerHeight])

  let {org_id, parent_group} = useParams()

  // FILTERS
  let page = parseInt(searchParams.get('page') as string, 10) || 1
  let page_size = parseInt(searchParams.get('page_size') as string, 10) || 50
  let multi_search = searchParams.get('multi_search') || ''

  // LOCAL STATE
  const [data, setData] = React.useState([])
  const [choosenItem, setChoosenItem] = React.useState(null)
  const [group, setGroup] = React.useState({name_ru: ''})
  const [allGroups, setAllGroups] = React.useState([])
  const [organization, setOrganization] = React.useState({id: '', name: ''})
  const [error, setError] = React.useState(null)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isRefetching, setIsRefetching] = useState(false)
  const [paginationData, setPaginationData] = React.useState({
    count: 1,
    page: 1,
    page_size: 50,
    totalPageCount: 1,
  })

  const [selectedItems, setSelectedItems] = React.useState<any>([])

  const addModalRef = React.useRef()
  const itemModalRef = React.useRef()

  // ImageViewer
  const [currentImage, setCurrentImage] = useState(0)
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  // @ts-ignore
  let viewerImages = data.map((x) => getMediaUrl('products', x.image))
  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }, [])
  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }
  // End of ImageViewer

  const getQueryObject = (): any => {
    let obj: {
      page?: number
      page_size?: number
      multi_search?: string
    } = {}

    if (!!page) obj.page = page
    if (!!page_size) obj.page_size = page_size
    if (!!multi_search) obj.multi_search = multi_search

    return obj
  }
  const buildQueryParams = () => {
    let query = `?parent_id=${parent_group}&page=${page}&page_size=${page_size}`

    if (!!multi_search) query += `&multi_search=${multi_search}`

    return query
  }

  async function getData() {
    try {
      const res = await axiosInstance.get(`products/${buildQueryParams()}`)

      setData(res.data.data || [])
      setPaginationData({
        count: res.data.count,
        page: res.data.page,
        page_size: res.data.page_size,
        totalPageCount: Math.ceil(res.data.count / res.data.page_size),
      })
      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      if (err.response.status === 404) {
        setIsLoading(false)
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
      setError(err)
    }
  }

  async function getGroup(parentGroup: string) {
    if (!parentGroup) return
    try {
      const res = await axiosInstance.get(`groups/${parentGroup}`)
      setGroup(res.data)
    } catch (err: any) {
      if (err.response.status === 404) {
        setIsLoading(false)
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
      setError(err)
    }
  }

  async function getAllGroups() {
    // @ts-ignore
    // if (!group.region_id) return
    try {
      // @ts-ignore
      let postData = {org_id: parseInt(org_id, 10)}
      let queryData = buildQueryData(postData)

      const res = await axiosInstance.get(`groups/?${queryData}`)
      setAllGroups(res.data.data || [])
    } catch (err: any) {
      console.log('error', err)
      if (err.response.status === 404) {
        setIsLoading(false)
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
      setError(err)
    }
  }

  async function getOrganization(orgId: string) {
    if (!orgId) return
    try {
      const res = await axiosInstance.get(`organization/${orgId}/`)
      setOrganization(res.data)
    } catch (err: any) {
      if (err.response.status === 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
      setError(err)
    }
  }

  React.useEffect(() => {
    getData()
  }, [searchParams])

  const refreshData = async () => {
    await getData()
  }

  React.useEffect(() => {
    // @ts-ignore
    if (!!choosenItem && !!choosenItem.id) {
      // @ts-ignore
      setChoosenItem(data.find((x) => x.id === choosenItem.id) || null)
    }
  }, [data])

  React.useEffect(() => {
    // @ts-ignore
    getGroup(parent_group)
    // @ts-ignore
    getOrganization(org_id)
    getData()
  }, [])
  React.useEffect(() => {
    getAllGroups()
  }, [group])

  const itemClick = (item: any) => {
    setChoosenItem(item)
  }

  const getDataContent = (arr: any) => {
    if (!arr || !arr.length) return []

    let content = []

    for (const [i, value] of arr.entries()) {
      content.push(
        <tr
          key={'list-' + value.id}
          className='c-pointer'
          onClick={(e) => {
            e.stopPropagation()
            navigate(`/groups/menu/${org_id}/products/${parent_group}/${value.id}/`)
            return
          }}
        >
          <td>
            <div className='form-check form-check-sm form-check-custom form-check-solid'>
              <input
                className='form-check-input widget-9-check'
                type='checkbox'
                value='1'
                checked={selectedItems.includes(value.id)}
                onClick={(e) => {
                  e.stopPropagation()
                  changeSelectedItems([value])
                }}
              />
            </div>
          </td>
          <td>{i + 1}</td>
          <td>
            <img
              className='row-img'
              src={getMediaUrl('products', value.image)}
              alt=''
              onClick={(e) => {
                e.stopPropagation()
                openImageViewer(i)
              }}
            />
          </td>
          <td>{value.name_ru}</td>
          <td>{value.url}</td>
          <td className='text-nowrap'>
            {convertToPrice(value?.price || 0) + ' ' + intl.formatMessage({id: 'COMMON.SUM'})}
          </td>
          <td>{value.position || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}</td>
          <td className='text-center'>
            {value.is_active ? (
              <i className='fa-regular fa-square-check text-success'></i>
            ) : (
              <i className='far fa-window-close text-danger'></i>
            )}
          </td>
          <td className='text-center'>
            {value.is_active_online ? (
              <i className='fa-regular fa-square-check text-success'></i>
            ) : (
              <i className='far fa-window-close text-danger'></i>
            )}
          </td>
          {hasAccess('groups-edit') && (
            <td>
              <button
                className='btn link btn-sm p-0'
                data-bs-toggle='modal'
                onClick={(e) => (
                  // @ts-ignore
                  itemClick(value), itemModalRef.current.showModal(), e.stopPropagation()
                )}
              >
                {/* <KTSVG
                  path='/media/icons/duotune/art/art005.svg'
                  className='svg-icon-1x svg-icon-primary'
                /> */}
                <i className='fa-regular fa-pen-to-square text-primary'></i>
              </button>
            </td>
          )}
        </tr>
      )
    }

    return content
  }

  const handlePaginationClick = (event: any) => {
    setSearchParams({...getQueryObject(), page: event.selected + 1})
  }

  const changeSelectedItems = (array: any, allSelect: boolean = false) => {
    let items: any = []
    items = selectedItems

    setData([...data])

    if (allSelect) {
      if (items.length >= array.length) {
        setSelectedItems([])
        return
      }
      let getAllId: any = []
      array.map((item: any) => {
        getAllId.push(item.id)
      })
      setSelectedItems(getAllId)
      return
    }
    array.map((item: any) => {
      let newSelectedItem = items.filter((selectItem: any) => selectItem === item.id)
      let newSelectedItems = items.filter((selectItem: any) => selectItem !== item.id)

      if (newSelectedItem.length > 0) {
        items = newSelectedItems
        return
      }
      items.push(item.id)
    })
    setSelectedItems(items)
  }

  async function changeStatus(status: boolean) {
    try {
      const res = await axiosInstance.patch(
        '/products/status/',
        {
          is_active: status,
          product_ids: [...selectedItems],
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      setSelectedItems([])
      getData()
      swal.fire(
        swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_UPDATE'}), 'success')
      )
    } catch (err: any) {
      if (err.response.status === 400) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
      return
    }
  }

  async function changeOnlineStatus(status: boolean) {
    try {
      const res = await axiosInstance.patch(
        '/products/status/',
        {
          is_active_online: status,
          product_ids: [...selectedItems],
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      setSelectedItems([])
      getData()
      swal.fire(
        swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_UPDATE'}), 'success')
      )
    } catch (err: any) {
      if (err.response.status === 400) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
      return
    }
  }

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
      {
        title: intl.formatMessage({id: 'MENU.MENU'}),
        path: '/groups',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
      {
        title: (organization || {}).name || intl.formatMessage({id: 'COMMON.NOT_DEFINED'}),
        path: `/groups/menu/${(organization || {}).id}`,
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }

  if (isLoading) return <Loader />

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>{group.name_ru}</PageTitle>
      <div className='h-100 d-flex flex-column flex-column-fluid'>
        <div className='card card-stretch mb-2'>
          <div className='card-header'>
            <div className='d-flex align-items-center position-relative input-group-sm my-1'>
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-1 position-absolute ms-4'
              />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                placeholder={intl.formatMessage({id: 'COMMON.SEARCH'})}
                value={multi_search}
                onChange={(e) =>
                  setSearchParams({...getQueryObject(), multi_search: e.target.value})
                }
              />

              {multi_search.length > 0 && (
                <button
                  className='btn link btn-sm p-0 ms-3'
                  data-bs-toggle='modal'
                  onClick={(e) => setSearchParams({...getQueryObject(), multi_search: ''})}
                >
                  <KTSVG
                    path='/media/icons/duotune/general/gen040.svg'
                    className='svg-icon-2hx svg-icon-danger'
                  />
                </button>
              )}
            </div>
            <div className='card-toolbar'>
              <div className='dropdown'>
                <button
                  className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary me-3'
                  type='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  <i className='fa-solid fa-bars'></i>
                </button>
                <ul className='dropdown-menu'>
                  <li>
                    <button className='btn dropdown-item' onClick={() => changeStatus(true)}>
                      {intl.formatMessage({id: 'COMMON.AVAILABLE'})}
                      {` (${intl.formatMessage({id: 'COMMON.ACTIVE'})})`}
                    </button>
                  </li>
                  <li>
                    <button className='btn dropdown-item' onClick={() => changeStatus(false)}>
                      {intl.formatMessage({id: 'COMMON.AVAILABLE'})}
                      {` (${intl.formatMessage({id: 'COMMON.INACTIVE'})})`}
                    </button>
                  </li>
                  <li>
                    <button className='btn dropdown-item' onClick={() => changeOnlineStatus(true)}>
                      {intl.formatMessage({id: 'COMMON.AVAILABLE_ONLINE'})}
                      {` (${intl.formatMessage({id: 'COMMON.ACTIVE'})})`}
                    </button>
                  </li>
                  <li>
                    <button className='btn dropdown-item' onClick={() => changeOnlineStatus(false)}>
                      {intl.formatMessage({id: 'COMMON.AVAILABLE_ONLINE'})}
                      {` (${intl.formatMessage({id: 'COMMON.INACTIVE'})})`}
                    </button>
                  </li>
                </ul>
              </div>

              {/* <button
                type='button'
                className='btn btn-sm btn-light-primary me-3'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
              >
                <i className='fa-solid fa-filter'></i>
                Filter
              </button> */}
            </div>
          </div>
        </div>

        <div className='card card-stretch mb-2 flex-column-fluid'>
          <div className='card-body main-overflow-x' style={{height: windowSize.current[1] - 320}}>
            <div className='table-responsive'>
              <table className='table table-hover border table-row-dashed table-row-gray-300 gy-2 gs-1'>
                <thead>
                  <tr className=' bg-light'>
                    <th className='w-25px'>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value='1'
                          checked={data.length <= selectedItems.length}
                          onChange={(e) => {
                            changeSelectedItems(data, true)
                          }}
                        />
                      </div>
                    </th>
                    <th>#</th>
                    <th>{intl.formatMessage({id: 'COMMON.IMAGE'})}</th>
                    <th>{intl.formatMessage({id: 'COMMON.NAME'})}</th>
                    <th>{intl.formatMessage({id: 'COMMON.URL'})}</th>
                    <th>{intl.formatMessage({id: 'COMMON.PRICE'})}</th>
                    <th style={{width: '120px'}}>{intl.formatMessage({id: 'COMMON.POSITION'})}</th>
                    <th className={'text-center'} style={{width: '120px'}}>
                      {intl.formatMessage({id: 'COMMON.AVAILABLE'})}
                    </th>
                    <th className={'text-center'} style={{width: '180px'}}>
                      {intl.formatMessage({id: 'COMMON.AVAILABLE_ONLINE'})}
                    </th>
                    {hasAccess('groups-edit') && <th style={{width: '30px'}}></th>}
                  </tr>
                </thead>
                <tbody>{getDataContent(data)}</tbody>
              </table>
            </div>
          </div>
        </div>

        {/* FOOTER */}
        <div className='card card-stretch py-4 d-flex flex-lg-column' id='kt_footer'>
          <div
            className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
          >
            <div className='order-1 d-flex align-items-center mb-2 mb-md-0'>
              <div className='dataTables_length' id='kt_ecommerce_report_sales_table_length'>
                <label>
                  <select
                    name='kt_ecommerce_report_sales_table_length'
                    aria-controls='kt_ecommerce_report_sales_table'
                    className='form-select form-select-sm form-select-solid'
                    value={page_size}
                    onChange={(e) =>
                      setSearchParams({...getQueryObject(), page_size: e.target.value})
                    }
                  >
                    <option value='10'>10</option>
                    <option value='25'>25</option>
                    <option value='50'>50</option>
                    <option value='100'>100</option>
                  </select>
                </label>
              </div>

              {paginationData.totalPageCount > 1 ? (
                <ReactPaginate
                  breakLabel='...'
                  nextLabel='>'
                  previousLabel='<'
                  onPageChange={handlePaginationClick}
                  initialPage={page - 1}
                  pageRangeDisplayed={2}
                  pageCount={paginationData.totalPageCount}
                  renderOnZeroPageCount={null}
                  containerClassName='pagination'
                  pageClassName='page-item'
                  pageLinkClassName='page-link'
                  breakClassName='page-item'
                  breakLinkClassName='page-link'
                  activeClassName='active'
                  previousClassName='page-item previous'
                  previousLinkClassName='page-link'
                  nextClassName='page-item next'
                  nextLinkClassName='page-link'
                  disabledClassName='disabled'
                />
              ) : (
                ''
              )}
            </div>

            {<FooterCopyright />}
          </div>
        </div>
      </div>

      <ItemModal
        item={choosenItem}
        allGroups={allGroups}
        refreshData={refreshData}
        ref={itemModalRef}
      />
      <AddModal
        refreshData={refreshData}
        allGroups={allGroups}
        parent_group={parent_group}
        ref={addModalRef}
      />
      {isViewerOpen && (
        <ImageViewer
          // @ts-ignore
          src={viewerImages}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
          closeOnClickOutside={true}
        />
      )}
    </>
  )
}
