import React, {createContext, useEffect, useState} from 'react'
import {getStorageItem, IUser, removeStorageItem, setStorageItem} from './AuthHelper'
import {ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, ROLES_KEY, USER_KEY} from '../../utils/constants'

interface AuthContextType {
  user: IUser | null
  hasAccess: (action: string) => boolean
  hasAccessByArray: (actions: string[]) => boolean
  logIn: (data: any) => void
  logOut: () => void
  isAuthenticated: boolean
  loading: boolean
}

type Props = {children: React.ReactNode}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

export const useAuth = (): AuthContextType => {
  const context = React.useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}

export const AuthProvider = ({children}: Props) => {
  const [user, setUser] = useState<IUser | null>(null)
  const [roles, setRoles] = useState<string[] | null>(null)
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const storedUser = getStorageItem(USER_KEY)
    const storedRoles = getStorageItem(ROLES_KEY)

    if (storedUser && storedRoles) {
      setUser(JSON.parse(storedUser))
      setRoles(JSON.parse(storedRoles))
      setIsAuthenticated(true)
    }

    setLoading(false)
  }, [])

  const logIn = async (data: any) => {
    try {
      if (data) {
        setStorageItem(USER_KEY, JSON.stringify(data.user))
        setStorageItem(ROLES_KEY, JSON.stringify(data.roles))
        setStorageItem(ACCESS_TOKEN_KEY, data.tokens.access_token)
        setStorageItem(REFRESH_TOKEN_KEY, data.tokens.refresh_token)

        setUser(data.user)
        setRoles(data.roles)
        setIsAuthenticated(true)
      }
    } catch (error) {
      console.error('Login failed:', error)
    }
  }

  const logOut = () => {
    removeStorageItem(USER_KEY)
    removeStorageItem(ROLES_KEY)
    removeStorageItem(ACCESS_TOKEN_KEY)
    removeStorageItem(REFRESH_TOKEN_KEY)

    setUser(null)
    setRoles(null)
    setIsAuthenticated(false)
  }

  const hasAccess = (action: string): boolean => {
    if (!isAuthenticated) {
      return false
    }
    let result = roles?.find((role: string) => role === action)
    return !!result
  }
  const hasAccessByArray = (actions: string[]): boolean => {
    if (!isAuthenticated) {
      return false
    }
    return actions?.some((action) => roles.includes(action))
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        logIn,
        logOut,
        hasAccess,
        hasAccessByArray,
        isAuthenticated,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
