// @ts-nocheck
import {
  FullscreenControl,
  Map,
  Placemark,
  Polygon,
  SearchControl,
  TypeSelector,
  YMaps,
  ZoomControl,
} from '@pbe/react-yandex-maps'
import clsx from 'clsx'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import Flatpickr from 'react-flatpickr'
import {Controller, SubmitHandler, useForm, useWatch} from 'react-hook-form'
import InputMask from 'react-input-mask'
import {useIntl} from 'react-intl'
import {useSearchParams} from 'react-router-dom'
import Select from 'react-select'
import {swalDefaultConfig} from '../../../_metronic/helpers/constants'
import {clearPhoneNumber} from '../../../_metronic/helpers/general'
import {swal} from '../../../_metronic/helpers/swal'
import {PageTitle} from '../../../_metronic/layout/core'
import Loader from '../../components/Loader'
import axiosInstance from '../../utils/axiosInstance'
import {getDistance, getDistanceInKm} from '../../utils/helpers'

interface Modifier {
  id: string
  name: string
  price: number
  amount: number
}

interface Product {
  id: string
  name: string
  price: number
  amount: number
  modifiers: Modifier[]
}

interface Cart {
  products: Product[]
}

interface Order {
  call_id: string
  account_id: number
  account_name: string
  account_phone: string
  account_second_phone: string
  is_delivery: boolean
  terminal_id: string
  organization_id: string
  delivery_date: string
  address_id: number
  address: string
  home: string
  entrance: string
  floor: string
  apartment: string
  latitude: string
  longitude: string
  payment_id: string
  delivery_price: number
  distance: number
  comment: string
  courier_comment: string
}

export function NewOrder() {
  const intl = useIntl()
  const [searchParams] = useSearchParams()

  const mapsRef = React.useRef(null)

  const [phone, setPhone] = useState('')
  const [callId, setCallId] = useState('')
  const [menu, setMenu] = useState([])
  const [mapCenter, setMapCenter] = useState([41.314646, 69.252271])
  const [branches, setBranches] = useState([])
  const [addressList, setAddressList] = useState([])
  const [paymentTypes, setPaymentTypes] = useState([])
  const [settings, setSettings] = useState({
    check_by_polygon: '0',
    yandex_key: '',
    suggest_key: '',
  })
  const [yMapRef, setYMapRef] = useState<any>()
  const [mapZoom, setMapZoom] = useState(13)

  const [userSearchResult, setUserSearchResult] = useState([])
  const [isShowPhoneDrpdwn, setIsShowPhoneDrpdwn] = useState(false)
  const [isShowNameDrpdwn, setIsShowNameDrpdwn] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    getValues,
    formState: {errors, touchedFields},
  } = useForm<Order>({
    mode: 'onBlur',
    defaultValues: {
      call_id: '',
      account_id: null,
      account_name: '',
      account_phone: '',
      account_second_phone: '',
      is_delivery: true,
      terminal_id: '',
      organization_id: '',
      delivery_date: '',
      address_id: '',
      address: '',
      home: '',
      entrance: '',
      floor: '',
      apartment: '',
      latitude: '',
      longitude: '',
      payment_id: '',
      distance: 0,
      delivery_price: 0,
      comment: '',
      courier_comment: '',
    },
  })
  const onSubmit: SubmitHandler<Order> = (data) => console.log(data)

  const accountId = useWatch({
    control,
    name: 'account_id',
  })
  const isDelivery = useWatch({
    control,
    name: 'is_delivery',
  })
  const latitude = useWatch({
    control,
    name: 'latitude',
  })
  const longitude = useWatch({
    control,
    name: 'longitude',
  })

  const getUserByPhone = async (phone) => {
    if (!phone) return null

    setIsLoading(true)
    try {
      const response = await axiosInstance.get(`/accounts/phone/?phone=${phone}`)

      setValue('account_id', response.data.id)
      setValue('account_name', response.data.name)
      setValue('account_phone', response.data.phone)
      setValue('account_second_phone', response.data.second_phone)

      getAddressList()
      // onUserSearchSelect(response.data)
      setIsShowPhoneDrpdwn(false)
    } catch (err) {
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const phoneParam = searchParams.get('phone')
    const callIdParam = searchParams.get('callid')
    if (phoneParam) {
      setPhone(phoneParam)
    }
    if (callIdParam) {
      setCallId(callIdParam)
    }
  }, [searchParams])

  useEffect(() => {
    if (phone) {
      getUserByPhone(phone)
    }
  }, [phone])

  useEffect(() => {
    const getAddressList = async () => {
      if (!accountId) return
      const response = await axiosInstance.get(`/orders/addresses/${accountId}/`)
      setAddressList(response.data)
    }

    getAddressList()
  }, [accountId])

  useEffect(() => {
    setIsLoading(true)
    const getBranches = async () => {
      const response = await axiosInstance.get('/terminals/').catch((error) => console.log(error))
      setBranches(response.data.data)
    }

    const getPaymentTypes = async () => {
      const response = await axiosInstance.get('/payment-types/')
      setPaymentTypes(response.data.data.filter((x) => x.is_active))
    }

    const getSettings = async () => {
      const response = await axiosInstance.get('/orders/settings/')
      setSettings({
        check_by_polygon: response.data.check_by_polygon || '0',
        yandex_key: response.data.yandex_key || '',
        suggest_key: response.data.suggest_key || '',
      })
    }

    getBranches()
    getPaymentTypes()
    getSettings()
    setIsLoading(false)
  }, [])

  const searchUser = async (key: string, value: string) => {
    if (value.length < 3) {
      return setUserSearchResult([])
    }

    if (key === 'phone') value = clearPhoneNumber(value)
    try {
      const res = await axiosInstance.get(`accounts/phone/${value}/`)
      // TODO: backend peredelat' na massiv
      setUserSearchResult(res.data)
      // if (res.data.length === 1 && phoneNumberRegEx.test(value || '')) {
      //   formik.setFieldValue('client_id', res.data[0].id)
      //   formik.setFieldValue('client_name', res.data[0].name)
      //   formik.setFieldValue('client_language', res.data[0].language)
      //   formik.setFieldValue('client_phone', res.data[0].phone)
      // }
    } catch (err: any) {
      if (err.response.status === 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }

  const userSearchDroprown = (isShow: boolean = false) => {
    const getContent = () => {
      if (!userSearchResult)
        return (
          <div className='d-flex justify-content-center'>
            <span>{intl.formatMessage({setIsShowDrpdwnid: 'VALIDATION.ENTER_SEARCH_VALUE'})}</span>
          </div>
        )
      if (!userSearchResult.length)
        return (
          <div className='d-flex justify-content-center'>
            <span>{intl.formatMessage({id: 'VALIDATION.NO_DATA_FOUNDED'})}</span>
          </div>
        )

      return userSearchResult.map((user) => {
        return (
          <button
            key={'userSearchList-' + user.id}
            className='dropdown-item'
            onClick={(e) => {
              e.preventDefault()
              onUserSearchSelect(user)
              setIsShowPhoneDrpdwn(false)
            }}
          >
            {user.name || ''} / {user.phone || ''}
          </button>
        )
      })
    }

    return (
      <div className='dropdown'>
        <div
          aria-labelledby=''
          className={clsx('w-100 dropdown-menu es-client-autocomplete', {show: isShow == true})}
          style={{
            position: 'absolute',
            inset: '0px auto auto 0px',
            transform: 'translate3d(0px, 10px, 0px)',
          }}
        >
          {getContent()}
        </div>
      </div>
    )
  }

  const onUserSearchSelect = (user: any) => {
    let phone = user.phone
    if (!phone.startsWith('+')) phone = '+' + phone

    // formik.setFieldValue('client_id', user.id || null)
    // formik.setFieldValue('client_phone', phone || '+998')
    // formik.setFieldValue('second_phone', user.second_phone || '+998')
    // formik.setFieldValue('client_name', user.name || '')
    // formik.setFieldValue('client_birthday', user.birthday || '')
    // formik.setFieldValue('client_language', user.language || 'ru')
    // formik.values.client_id = user.id || null
    // formik.values.client_phone = phone || '+998'
    // formik.values.client_name = user.name || ''
    // formik.values.client_birthday = user.birthday || ''
    // formik.values.client_language = user.language || 'ru'
  }
  const setIsShowDrpdwn = (key: string = '') => {
    setIsShowPhoneDrpdwn(false)
    setIsShowNameDrpdwn(false)

    if (key == 'phone') setIsShowPhoneDrpdwn(true)
    if (key == 'name') setIsShowNameDrpdwn(true)
  }

  const getAddressByLocation = (location: double[]) => {
    return yMapRef
      .geocode(location)
      .then((result) => {
        return {
          address: result.geoObjects.get(0).getAddressLine(),
          city: result.geoObjects.get(0).getLocalities()[0],
          street: result.geoObjects.get(0).getThoroughfare(),
          home: result.geoObjects.get(0).getPremiseNumber(),
        }
      })
      .catch((error) => {
        return {
          address: 'Не определен',
          city: null,
          street: null,
          home: null,
        }
      })
  }

  const getAddressInfo = async (latitude = null, longitude = null, terminalId, total = 0) => {
    if ((!latitude && !getValues().latitude) || (!latitude && !getValues().longitude)) return

    const data = {
      total: total || 0,
      latitude: latitude || getValues().latitude,
      longitude: longitude || getValues().longitude,
      terminal_id: terminalId || null,
      // terminal_id: null,
    }

    const info = await axiosInstance
      .get(
        '/orders/address/',
        {params: data}
        // total: getItemsTotalPrice(buildCartOverview(cartOverView), 0, false),
      )
      .catch((error) => {
        let m = (((error || {}).response || {}).data || {}).message
        if (!m) m = (error || {}).message || ''
        swal.fire(swalDefaultConfig(m, 'error'))
        return
      })

    setValue('distance', info.data.deliveryDistance)
    setValue('delivery_price', info.data.deliveryPrice)
    setValue('terminal_id', info.data.terminalId)
    setValue('organization_id', info.data.organizationId)
  }

  const mapOnClick = async (e) => {
    if (isLoading) return
    let coords = await e.get('coords')

    mapsRef?.current?.setCenter(coords, 18)
    const addressObj = await getAddressByLocation(coords)

    // formik.setFieldValue('coords', coords)
    setValue('latitude', coords[0])
    setValue('longitude', coords[1])
    setValue('address', addressObj.address)
    setValue('home', addressObj.home)

    await getAddressInfo(coords[0], coords[1], '')
  }

  if (isShowPhoneDrpdwn) {
    document.addEventListener('click', (event) => {
      setIsShowPhoneDrpdwn(false)
    })
  }

  /*async function getMenu() {
    try {
      const res = await axiosInstance.get(
        `/groups/products/?organization_id=${formik.values.organization_id}&terminal_id=${formik.values.terminalId}`
        // `/groups/products/?multi_search`
      )
      setMenu(res.data.data || [])

      // let nextData = res.data.data.map((x) => x)
      // let newData = []
      // let newData1 = []
      // nextData.map((d) => {
      //   if (d.parent_id == '0') {
      //     newData.push({...d, name: d.name_ru, children: []})
      //     newData1.push({...d, name: d.name_ru, children: []})
      //   }
      // })
      // newData.map((nD, nIndex) => {
      //   nextData.map((d) => {
      //     if (d.parent_id != '0' && nD.id == d.parent_id) {
      //       console.log('d', d)
      //       let newProd = d
      //       newProd = Object.assign(d, {name: d.name_ru}, {children: []})
      //       delete newProd.products
      //       // console.log("123", d)
      //       // d.products.map((cD) => {console.log(cD)})
      //       newData1[nIndex].children.push(newProd)
      //     }
      //   })
      // })
      // setMenu2(newData1)

      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      if (err.response.status == 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }

  useEffect(() => {
    getMenu()
  }, [formik.values.organization_id])
*/
  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
      {
        title: intl.formatMessage({id: 'MENU.ORDERS'}),
        path: '/orders',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }

  const getSelectedTerminal = () => {
    const b = branches?.find((branch) => branch.id == getValues('terminal_id'))
    if (!b) return null
    return {
      label: b.name_ru,
      value: b.id,
      organization_id: b.organization.id,
    }
  }

  const getBranchesList = () => {
    return branches
      .map((branch) => {
        const distance = getDistance(
          getValues('latitude'),
          getValues('longitude'),
          branch.latitude,
          branch.longitude
        )
        return {...branch, distance}
      })
      .sort((a, b) => a.distance - b.distance) // Sort branches by distance
  }

  if (isLoading) return <Loader />

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageTitle breadcrumbs={getBreadcrumbs()}>
          {intl.formatMessage({id: 'COMMON.NEW_ORDER'})}
        </PageTitle>
        <div className='row mb-2'>
          <div className='col-md-6'>
            <div className='row'>
              <div className='col-md-6 pe-md-1 mb-2'>
                <div className='card card-stretch'>
                  <div className='card-header'>
                    <h2 className='card-title'>{intl.formatMessage({id: 'COMMON.CLIENT'})}</h2>
                  </div>
                  <div className='card-body'>
                    <div className='mb-3'>
                      <label className='form-label required'>
                        {intl.formatMessage({id: 'COMMON.NAME2'})}
                      </label>
                      <input
                        type='text'
                        {...register('account_name', {required: true})}
                        aria-invalid={errors.account_name ? 'true' : 'false'}
                        className={clsx('form-control', {
                          'is-invalid': errors.account_name && touchedFields.account_name,
                          'is-valid': !errors.account_name && touchedFields.account_name,
                        })}
                      />
                    </div>
                    <div className='mb-3'>
                      <label className='form-label required'>
                        {intl.formatMessage({id: 'COMMON.PHONE'})}
                      </label>
                      <InputMask
                        {...register('account_phone', {required: true})}
                        aria-invalid={errors.account_phone ? 'true' : 'false'}
                        className={clsx('form-control', {
                          'is-invalid': errors.account_phone && touchedFields.account_phone,
                          'is-valid': !errors.account_phone && touchedFields.account_phone,
                        })}
                        mask='+999 (99) 999-99-99'
                        // onChange={(e) => (
                        //   formik.setFieldValue('client_phone', e.currentTarget.value),
                        //   searchUser('phone', e.currentTarget.value),
                        //   setIsShowDrpdwn('phone')
                        // )}
                      />
                      {userSearchDroprown(isShowPhoneDrpdwn)}
                    </div>
                    <div className='mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'COMMON.SECOND_PHONE'})}
                      </label>
                      <InputMask
                        {...register('account_second_phone')}
                        className={clsx('form-control')}
                        mask='+999 (99) 999-99-99'
                        // onChange={(e) => {
                        //   formik.setFieldValue('second_phone', e.currentTarget.value)
                        //   // searchUser('phone', e.currentTarget.value)
                        // }}
                        // value={formik.values.second_phone}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-6 px-md-1 mb-2'>
                <div className='card card-stretch'>
                  <div className='card-header'>
                    <h2 className='card-title'>{intl.formatMessage({id: 'MENU.ORDER_TYPE'})}</h2>
                  </div>
                  <div className='card-body'>
                    <div className='mb-4'>
                      <label className='form-label required'>
                        {intl.formatMessage({id: 'COMMON.ORDER_TYPE'})}
                      </label>
                      <div className='form-check form-check-custom form-check-solid'>
                        <div
                          className='btn-group w-100'
                          data-kt-buttons='true'
                          data-kt-buttons-target='[data-kt-button]'
                        >
                          <label
                            className={clsx('btn btn-color-muted p-2 btn-active-light-success', {
                              active: isDelivery,
                            })}
                            data-kt-button='true'
                          >
                            <Controller
                              name='is_delivery'
                              control={control}
                              render={({field}) => (
                                <input
                                  {...field}
                                  type='radio'
                                  className='btn-check'
                                  value={1}
                                  checked={isDelivery}
                                  onChange={() => setValue('is_delivery', true)}
                                />
                              )}
                            />
                            Доставка
                          </label>

                          <label
                            className={clsx('btn btn-color-muted p-2 btn-active-light-danger', {
                              active: !isDelivery,
                            })}
                            data-kt-button='true'
                          >
                            <Controller
                              name='is_delivery'
                              control={control}
                              render={({field}) => (
                                <input
                                  {...field}
                                  type='radio'
                                  className='btn-check'
                                  value={0}
                                  checked={!isDelivery}
                                  onChange={() => setValue('is_delivery', false)} // Обновление значения
                                />
                              )}
                            />
                            Самовывоз
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className='mb-3'>
                      <label className='form-label required'>
                        {intl.formatMessage({id: 'COMMON.BRANCH'})}
                      </label>
                      <Select
                        {...register('terminal_id', {required: 'Поле обязательно для заполнения'})}
                        aria-invalid={errors.terminal_id ? 'true' : 'false'}
                        placeholder='Выберите филиал'
                        className={clsx('react-select-styled react-select-solid', {
                          'is-invalid': errors.terminal_id && touchedFields.terminal_id,
                          'is-valid': !errors.terminal_id && touchedFields.terminal_id,
                        })}
                        options={branches?.map((x) => ({
                          value: x.id,
                          label: x.name_ru,
                          organization_id: x.organization.id,
                        }))}
                        isSearchable={true}
                        value={getSelectedTerminal()}
                        onChange={(selectedOption) => {
                          setValue('terminal_id', selectedOption?.value)
                          setValue('organization_id', selectedOption?.organization_id)
                        }}
                      />
                    </div>

                    <div className='mb-3'>
                      <div>
                        <label className='form-label'>
                          {intl.formatMessage({id: 'COMMON.RECEIVE_DATE'})}
                        </label>
                      </div>
                      <Flatpickr
                        onChange={([date]) => {
                          setValue('delivery_date', moment(date).format('YYYY-MM-DD HH:mm'))
                        }}
                        options={{
                          time_24hr: true,
                          enableTime: true,
                          dateFormat: 'Y-m-d H:i',
                          disable: [
                            {
                              from: '1970-01-01',
                              to: Date.now(),
                            },
                            {
                              from: '2024-11-30',
                              to: Date.now(),
                            },
                          ],
                        }}
                        className='form-control'
                        placeholder='Дата поставки'
                      />
                    </div>
                  </div>
                </div>
              </div>

              {getValues().is_delivery && (
                <>
                  <div className='card card-stretch'>
                    <div className='card-header'>
                      <h2 className='card-title'>Карты</h2>
                      <div className='card-toolbar'>
                        <div>
                          {intl.formatMessage({id: 'DISTANCE'})}:{' '}
                          {getDistanceInKm(getValues().distance)}{' '}
                          {intl.formatMessage({id: 'KILOMETR_SHORT'})}
                        </div>
                      </div>
                    </div>
                    <div className='card-body'>
                      <div className='p-0' style={{height: '560px'}}>
                        {!!settings.yandex_key && (
                          <YMaps
                            query={{
                              apikey: process.env.REACT_APP_YMAPS_KEY,
                              lang: 'ru_RU',
                              load: 'package.full',
                            }}
                          >
                            <Map
                              onLoad={(ymaps) => setYMapRef(ymaps)}
                              onClick={mapOnClick}
                              instanceRef={mapsRef}
                              defaultState={{
                                center: mapCenter,
                                zoom: mapZoom,
                                controls: [],
                              }}
                              options={{
                                suppressMapOpenBlock: true,
                              }}
                              modules={['geocode']}
                              style={{width: '100%', height: '100%'}}
                            >
                              <FullscreenControl />
                              <TypeSelector />
                              <SearchControl
                                options={{
                                  float: 'left',
                                  provider: 'yandex#search',
                                }}
                              />
                              <ZoomControl />
                              {latitude && longitude && (
                                <Placemark
                                  geometry={[latitude, longitude]}
                                  properties={{
                                    iconContent: `${getValues().address}`,
                                    hintContent: `${getValues().address}`,
                                  }}
                                  options={{
                                    preset: 'islands#redStretchyIcon',
                                  }}
                                />
                              )}

                              {branches.map((x: any) => {
                                return (
                                  <Polygon
                                    key={'restriction-zone-' + x.id}
                                    onClick={mapOnClick}
                                    // geometry={[polygonJson.map((x) => [x[1], x[0]])]}
                                    geometry={[
                                      JSON.parse(x.coordinates || '[]').map((y) => [y[1], y[0]]),
                                    ]}
                                    options={{
                                      fillColor: x.polygon_color || '#0000FF',
                                      strokeColor: x.polygon_color || '#0000FF',
                                      opacity: 0.3,
                                      strokeWidth: 5,
                                    }}
                                  />
                                )
                              })}
                              {(branches || []).map((x: any) => (
                                <Placemark
                                  key={'branchPin-' + x.id}
                                  geometry={[x.latitude, x.longitude]}
                                  options={{
                                    preset: 'islands#blueStretchyIcon',
                                  }}
                                  properties={{
                                    iconContent: `${x.name_ru}`,
                                    hintContent: `${x.address_ru}`,
                                  }}
                                />
                              ))}
                            </Map>
                          </YMaps>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className='col-md-6'>
            <div className='card card-stretch'>
              <div className='card-header'>
                <h2 className='card-title'>Филиалы</h2>
              </div>
              <div className='card-body'>
                <ul class='list-group'>
                  {getBranchesList().map((branch, idx) => (
                    <li class='list-group-item'>
                      <a href='#' className={`fs-${idx + 1} text-dark`}>
                        {branch.name_ru}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {getValues().is_delivery && (
              <div className='card card-stretch mb-2'>
                <div className='card-header'>
                  <h2 className='card-title'>
                    {intl.formatMessage({id: 'COMMON.DELIVERY_ADDRESS'})}
                  </h2>
                </div>
                <div className='card-body'>
                  {addressList.map((x: any, idx: string) => {
                    return (
                      <div
                        key={idx}
                        className='border-bottom py-1 c-pointer'
                        onClick={async () => {
                          setValue('address', x.address)
                          setValue('home', x.home)
                          setValue('entrance', x.entrance)
                          setValue('floor', x.floor)
                          setValue('latitude', x.latitude)
                          setValue('longitude', x.longitude)
                          setValue('terminal_id', x.terminalId)
                          setValue('delivery_price', x.deliveryPrice)
                          setValue('distance', x.deliveryDistance)

                          if (x.latitude && x.longitude) {
                            await getAddressInfo(x.latitude, x.longitude)
                            mapsRef.current.setCenter([x.latitude, x.longitude], 18)
                          }
                        }}
                      >
                        {x.address}
                      </div>
                    )
                  })}
                  <hr />
                  <div className='row'>
                    <div className='col-md-12 mb-2'>
                      <label className='form-label required'>
                        {intl.formatMessage({id: 'ITEM.ADDRESS_RU'})}
                      </label>
                      <input
                        type='text'
                        {...register('address', {required: true})}
                        aria-invalid={errors.address ? 'true' : 'false'}
                        className={clsx('form-control', {
                          'is-invalid': errors.address && touchedFields.address,
                          'is-valid': !errors.address && touchedFields.address,
                        })}
                      />
                    </div>
                    <div className='col-md-3 mb-2'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'COMMON.HOUSE'})}
                      </label>
                      <input type='text' {...register('home')} className={clsx('form-control')} />
                    </div>
                    <div className='col-md-3 mb-2'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'COMMON.ENTRANCE'})}
                      </label>
                      <input
                        type='text'
                        {...register('entrance')}
                        className={clsx('form-control')}
                      />
                    </div>
                    <div className='col-md-3 mb-2'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'COMMON.FLOOR'})}
                      </label>
                      <input type='text' {...register('floor')} className={clsx('form-control')} />
                    </div>
                    <div className='col-md-3 mb-2'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'COMMON.APT_OFFICE'})}
                      </label>
                      <input
                        type='text'
                        {...register('apartment')}
                        className={clsx('form-control')}
                      />
                    </div>
                    <div className='col-md-12 mb-2'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'COMMON.POINT'})}
                      </label>
                      <input type='text' {...register('point')} className={clsx('form-control')} />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <hr />
            <button className='btn btn-success btn-lg' onClick={async () => {}}>
              Оформить заказ
            </button>
          </div>
        </div>
      </form>
    </>
  )
}
