import {format, parseISO} from 'date-fns'

export const dateTimeFormat = (date: string): string => {
  if (!date || date === '') {
    return '-'
  }
  const dateISO = parseISO(date)
  return format(dateISO, 'dd.MM.yyyy HH:mm')
}
export const dateFormat = (date: string): string => {
  if (!date || date === '') {
    return '-'
  }
  const dateISO = parseISO(date)
  return format(dateISO, 'dd.MM.yyyy')
}
export const timeFormat = (date: string): string => {
  if (!date || date === '') {
    return '-'
  }
  const dateISO = parseISO(date)
  return format(dateISO, 'HH:mm')
}

export const getOrderTypeText = (type: boolean): string => {
  return type ? 'Самовывоз' : 'Доставка'
}

export const getPriceFormatted = (price: number): string => {
  return new Intl.NumberFormat('ru-RU').format(price)
}
export const getDistanceInKm = (distance: number): string => {
  return (Math.round((distance / 1000 + Number.EPSILON) * 100) / 100).toString()
}
export const getTwoPointCenter = (
  fromLatitude,
  fromLongitude,
  toLatitude,
  toLongitude
): number[] => {
  if (!fromLongitude || !fromLongitude) {
    return [toLatitude, toLongitude]
  }
  return [
    (parseFloat(fromLatitude) + parseFloat(toLatitude)) / 2,
    (parseFloat(fromLongitude) + parseFloat(toLongitude)) / 2,
  ]
}

export const getDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371
  const dLat = (lat2 - lat1) * (Math.PI / 180)
  const dLon = (lon2 - lon1) * (Math.PI / 180)

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return R * c * 1000
}
