import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {PageTitle, useLayout} from '../../../_metronic/layout/core'
import moment from 'moment'

// COMPONENTS
import {KTSVG} from '../../../_metronic/helpers'
import {swalDefaultConfig} from '../../../_metronic/helpers/constants'
import {swal} from '../../../_metronic/helpers/swal'
import Loader from '../../components/Loader'
import FooterCopyright from '../../modules/FooterCopyright'
import {AddModal} from './components/AddModal'
import {ItemModal} from './components/ItemModal'
import {useAuth} from '../../modules/auth/useAuth'
import axiosInstance from '../../utils/axiosInstance'

const COLORS_DICT = [
  {label: 'Синий', value: '#0000FF'},
  {label: 'Зелёный', value: '#008000'},
  {label: 'Красный', value: '#FF0000'},
  {label: 'Аква', value: '#00FFFF'},
  {label: 'Индиго', value: '#4B0082'},
  {label: 'Розовый', value: '#800080'},
  {label: 'Коричневый', value: '#8B4513'},
  {label: 'Чёрный', value: '#000000'},
  {label: 'Жёлтый', value: '#FFFF00'},
  {label: 'Оливковый', value: '#808000'},
  {label: 'Лайм', value: '#00FF00'},
]

export function BranchesIndex() {
  const intl = useIntl()

  const {classes} = useLayout()
  const {hasAccess} = useAuth()

  let navigate = useNavigate()
  let [searchParams, setSearchParams] = useSearchParams()
  const windowSize = React.useRef([window.innerWidth, window.innerHeight])

  // FILTERS
  let multi_search = searchParams.get('multi_search') || ''
  let not_active = searchParams.get('not_active') === 'true' ? true : false
  let with_delete = searchParams.get('with_delete') === 'true' ? true : false

  // LOCAL STATE
  const [data, setData] = React.useState([])
  const [cities, setCities] = React.useState([])
  const [choosenItem, setChoosenItem] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const [selectedItems, setSelectedItems] = React.useState<any>([])

  const addModalRef = React.useRef()
  const itemModalRef = React.useRef()

  const getQueryObject = (): any => {
    let obj: {
      multi_search?: string
      with_delete?: boolean
      not_active?: boolean
    } = {}

    if (!!multi_search) obj.multi_search = multi_search
    if (!!with_delete) obj.with_delete = with_delete
    if (!!not_active) obj.not_active = not_active

    return obj
  }
  const buildQueryParams = () => {
    let query = `?all=${not_active}`

    if (!!multi_search) query += `&multi_search=${multi_search}`
    if (!!with_delete) query += `&with_delete=${with_delete}`

    return query
  }

  async function getData() {
    try {
      const res = await axiosInstance.get(`terminals/${buildQueryParams()}`)
      setData(res.data.data || [])

      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      if (err.response.status === 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }

  async function getCities() {
    try {
      const result = await axiosInstance.get('/regions/?is_page_all=true')
      setCities(result.data.data)
    } catch (err: any) {
      if (err.response.status === 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }

  useEffect(() => {
    getData()
  }, [searchParams])

  useEffect(() => {
    setIsLoading(true)
    getData()
    getCities()
  }, [])

  const refreshData = async () => {
    await getData()
  }

  React.useEffect(() => {
    // @ts-ignore
    if (!!choosenItem && !!choosenItem.id) {
      // @ts-ignore
      setChoosenItem(data.find((x) => x.id === choosenItem.id) || null)
    }
  }, [data])

  const itemClick = (item: any) => {
    setChoosenItem(item)
  }

  const handlePaginationClick = (event: any) => {
    setSearchParams({...getQueryObject(), page: event.selected + 1})
  }

  const changeSelectedItems = (array: any, allSelect: boolean = false) => {
    let items: any = []
    items = selectedItems

    setData([...data])

    if (allSelect) {
      if (items.length >= array.length) {
        setSelectedItems([])
        return
      }
      let getAllId: any = []
      array.map((item: any) => {
        getAllId.push(item.id)
      })
      setSelectedItems(getAllId)
      return
    }
    array.map((item: any) => {
      let newSelectedItem = items.filter((selectItem: any) => selectItem === item.id)
      let newSelectedItems = items.filter((selectItem: any) => selectItem !== item.id)

      if (newSelectedItem.length > 0) {
        items = newSelectedItems
        return
      }
      items.push(item.id)
    })
    setSelectedItems(items)
  }

  async function changeStatus(status: boolean) {
    try {
      const res = await axiosInstance.patch(
        '/terminals/status/',
        {
          is_active: status,
          terminal_ids: [...selectedItems],
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      setSelectedItems([])
      getData()
      swal.fire(
        swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_UPDATE'}), 'success')
      )
    } catch (err: any) {
      if (err.response.status === 400) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
      return
    }
  }

  const clearSearchParams = () => {
    searchParams.delete('not_active')
    searchParams.delete('with_delete')
    setSearchParams(searchParams)
  }

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }

  if (isLoading) return <Loader />

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>
        {intl.formatMessage({id: 'MENU.BRANCHES'})}
      </PageTitle>
      <div className='h-100 d-flex flex-column flex-column-fluid'>
        <div className='card card-stretch mb-2'>
          <div className='card-header'>
            <div className='d-flex align-items-center justify-content-center'>
              <div className='d-flex align-items-center position-relative input-group-sm my-1 me-3'>
                <KTSVG
                  path='/media/icons/duotune/general/gen021.svg'
                  className='svg-icon-1 position-absolute ms-4'
                />
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control-solid w-250px ps-14'
                  placeholder={intl.formatMessage({id: 'COMMON.SEARCH'})}
                  value={multi_search}
                  onChange={(e) =>
                    setSearchParams({...getQueryObject(), multi_search: e.target.value})
                  }
                />

                {multi_search.length > 0 && (
                  <button
                    className='btn link btn-sm p-0 ms-3'
                    data-bs-toggle='modal'
                    onClick={(e) => setSearchParams({...getQueryObject(), multi_search: ''})}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen040.svg'
                      className='svg-icon-2hx svg-icon-danger'
                    />
                  </button>
                )}
              </div>
            </div>
            <div className='card-toolbar'>
              <div className='dropdown'>
                <button
                  className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary me-3'
                  type='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  <i className='fa-solid fa-bars'></i>
                </button>
                <ul className='dropdown-menu'>
                  <li>
                    <button className='btn dropdown-item' onClick={() => changeStatus(true)}>
                      {intl.formatMessage({id: 'COMMON.ACTIVE'})}
                    </button>
                  </li>
                  <li>
                    <button className='btn dropdown-item' onClick={() => changeStatus(false)}>
                      {intl.formatMessage({id: 'COMMON.INACTIVE'})}
                    </button>
                  </li>
                </ul>
              </div>
              <div className='ms-3'>
                <button
                  className='btn btn-sm btn-icon btn-active-color-primary'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                >
                  <i className='bi bi-funnel fs-5'></i>
                </button>
                <div
                  className='menu menu-sub menu-sub-dropdown w-250px w-md-300px'
                  data-kt-menu='true'
                >
                  <div className='px-7 py-5'>
                    <div className='fs-5 text-dark er'>
                      {intl.formatMessage({id: 'COMMON.FILTER'})}
                    </div>
                  </div>

                  <div className='separator border-gray-200'></div>

                  <div className='px-7 py-5'>
                    <div className='mb-10'>
                      <label className='form-check form-check-sm form-check-custom form-check-solid mb-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value='1'
                          checked={not_active}
                          onClick={(e: any) => {
                            setSearchParams({...getQueryObject(), not_active: e.target.checked})
                          }}
                        />
                        <span className='form-check-label'>
                          {intl.formatMessage({id: 'COMMON.NOT_ACTIVE'})}
                        </span>
                      </label>
                      <label className='form-check form-check-sm form-check-custom form-check-solid mb-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value='1'
                          checked={with_delete}
                          onClick={(e: any) => {
                            setSearchParams({...getQueryObject(), with_delete: e.target.checked})
                          }}
                        />
                        <span className='form-check-label'>
                          {intl.formatMessage({id: 'COMMON.REMOTE'})}
                        </span>
                      </label>
                    </div>

                    <div className='d-flex justify-content-end'>
                      <button
                        type='reset'
                        className='btn btn-sm btn-light btn-active-light-primary me-2'
                        data-kt-menu-dismiss='true'
                        onClick={clearSearchParams}
                      >
                        {intl.formatMessage({id: 'COMMON.CLEAR'})}
                      </button>

                      <button
                        type='submit'
                        className='btn btn-sm btn-primary'
                        data-kt-menu-dismiss='true'
                      >
                        {intl.formatMessage({id: 'COMMON.CLOSE'})}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='card card-stretch mb-2 flex-column-fluid'>
          <div className='card-body main-overflow-x' style={{height: windowSize.current[1] - 320}}>
            <div className='table-responsive'>
              <table className='table table-hover border table-row-dashed table-row-gray-300 gy-2 gs-1'>
                <thead>
                  <tr className=' bg-light'>
                    <th className='w-25px'>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value='1'
                          checked={data.length <= selectedItems.length}
                          onChange={(e) => {
                            changeSelectedItems(data, true)
                          }}
                        />
                      </div>
                    </th>
                    <th className='w-25px'>#</th>
                    <th>{intl.formatMessage({id: 'ITEM.NAME_RU'})}</th>
                    <th>Адрес</th>
                    <th>Номер телефона</th>
                    <th>{intl.formatMessage({id: 'ITEM.OPEN_TIME'})}</th>
                    <th>{intl.formatMessage({id: 'ITEM.CLOSE_TIME'})}</th>
                    <th>Телеграм группа</th>
                    {/*<th>{intl.formatMessage({id: 'COMMON.LATITUDE'})}</th>*/}
                    {/*<th>{intl.formatMessage({id: 'COMMON.LONGITUDE'})}</th>*/}
                    <th className='text-center'>{intl.formatMessage({id: 'COMMON.STATUS'})}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((value, idx) => (
                    <tr
                      key={'list-' + idx}
                      className='c-pointer'
                      onClick={() => {
                        if (hasAccess('branches-edit')) {
                          itemClick(value)
                          // @ts-ignore
                          itemModalRef.current.showModal()
                        }
                      }}
                    >
                      <td>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input widget-9-check'
                            type='checkbox'
                            value='1'
                            checked={selectedItems.includes(value.id)}
                            onClick={(e) => {
                              e.stopPropagation()
                              changeSelectedItems([value])
                            }}
                          />
                        </div>
                      </td>
                      <td>{idx + 1}</td>
                      <td>{value.name_ru}</td>
                      <td>{value.address_ru}</td>
                      <td>{value.phone}</td>
                      <td>{moment(value.open_time, 'HH:mm:ss').format('HH:mm:ss')}</td>
                      <td>{moment(value.close_time, 'HH:mm:ss').format('HH:mm:ss')}</td>
                      {/*<td>{value.latitude}</td>*/}
                      {/*<td>{value.longitude}</td>*/}
                      <td>{value.tg_group_id}</td>
                      <td className='text-center'>
                        {value.is_active ? (
                          <i className='fa-regular fa-square-check text-success'></i>
                        ) : (
                          <i className='far fa-window-close text-danger'></i>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* FOOTER */}
        <div className='card card-stretch py-4 d-flex flex-lg-column' id='kt_footer'>
          <div
            className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
          >
            <div className='order-1 d-flex align-items-center mb-2 mb-md-0'></div>

            {<FooterCopyright />}
          </div>
        </div>
      </div>

      <ItemModal
        item={choosenItem}
        cities={cities}
        refreshData={refreshData}
        ref={itemModalRef}
        COLORS_DICT={COLORS_DICT}
      />
      <AddModal
        refreshData={refreshData}
        cities={cities}
        ref={addModalRef}
        COLORS_DICT={COLORS_DICT}
      />
    </>
  )
}
