export const USER_KEY = '_zu'
export const ROLES_KEY = '_zm'
export const ACCESS_TOKEN_KEY = '_za'
export const REFRESH_TOKEN_KEY = '_zr'

export const SYSTEMS = {
  Web: 'Колл центр',
  Telegram: 'Телеграм бот',
  Site: 'Веб сайт',
  iOS: 'iOS',
  Android: 'Android',
}
