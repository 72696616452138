import React, {FC} from 'react'
import AndroidIcon from '@mui/icons-material/Android'
import AppleIcon from '@mui/icons-material/Apple'
import TelegramIcon from '@mui/icons-material/Telegram'
import CallIcon from '@mui/icons-material/Call'
import WebIcon from '@mui/icons-material/Web'

type PropsType = {
  system: string
}

const SystemIconComponent: FC<PropsType> = (props) => {
  if (props.system === 'WEB') {
    return <WebIcon className='text-danger' />
  } else if (props.system === 'TELEGRAM') {
    return <TelegramIcon className='text-primary' />
  } else if (props.system === 'IOS') {
    return <AppleIcon className='color-black' />
  } else if (props.system === 'ANDROID') {
    return <AndroidIcon className='text-success' />
  }
  return <CallIcon className='text-success' />
}

export default SystemIconComponent
