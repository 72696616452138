import clsx from 'clsx'
import {forwardRef, useImperativeHandle, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import * as Yup from 'yup'

// @ts-ignore
import 'react-datepicker/dist/react-datepicker.css'

import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'

import {swalDefaultConfig} from '../../../../_metronic/helpers/constants'
import {swal} from '../../../../_metronic/helpers/swal'
import axiosInstance from '../../../utils/axiosInstance'

export const AddModal = forwardRef((props: any, ref: any) => {
  const intl = useIntl()

  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)

  const handleClose = () => {
    formik.resetForm()
    setShow(false)
  }
  const handleShow = () => {
    formik.resetForm()
    setShow(true)
  }

  useImperativeHandle(ref, () => ({
    showModal() {
      handleShow()
    },
  }))

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1}))
      .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
    key: Yup.string()
      .min(2, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1}))
      .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
    latitude: Yup.string(),
    longitude: Yup.string(),
  })

  let initialValues = {
    latitude: '',
    longitude: '',
    name: '',
    key: '',
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)
      setLoading(true)

      try {
        const res = await axiosInstance.post(
          '/organization/',
          {
            latitude: values.latitude || '',
            longitude: values.longitude || '',
            name: values.name,
            key: values.key,
            is_main: 0,
            id: '9ffff8f3-7708-4e5b-b4ed-2f24748cd339',
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )

        swal.fire({
          title: intl.formatMessage({id: 'NOTIFICATION.SUCCESS_ADD'}),
          icon: 'success',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        })
        setLoading(false)
      } catch (err: any) {
        if (err.response.status === 400) {
          swal.fire(
            swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}), 'error')
          )
        } else {
          swal.fire(
            swalDefaultConfig(
              intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}),
              'error'
            )
          )
        }
        setLoading(false)
        // console.log('error', err)
        // let msg = (((err || {}).response || {}).data || {}).message

        // setStatus(intl.formatMessage({id: 'VALIDATION.API_ERROR'}, {text: msg || err.message}))
        return
      }
      setShow(false)
      setLoading(false)
      setSubmitting(false)
      props.refreshData()
    },
  })

  return (
    <Modal className='es-modal' size='lg' show={show} onHide={handleClose} centered>
      <Modal.Header>
        <h2 className='modal-title'>{intl.formatMessage({id: 'COMMON.ADD'})}</h2>
        <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={handleClose}>
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
        </div>
      </Modal.Header>
      <Modal.Body>
        <form className='row' onSubmit={formik.handleSubmit}>
          {formik.status ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          ) : (
            <div></div>
          )}

          <div className='col-md-6 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'COMMON.NAME'})}</label>
            <input
              type='text'
              {...formik.getFieldProps('name')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.name && formik.errors.name},
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
              value={formik.values.name}
            />
          </div>
          <div className='col-md-6 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'COMMON.KEY'})}</label>
            <input
              type='text'
              {...formik.getFieldProps('key')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.key && formik.errors.key},
                {
                  'is-valid': formik.touched.key && !formik.errors.key,
                }
              )}
              value={formik.values.key}
            />
          </div>
          <div className='col-md-6 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'COMMON.LATITUDE'})}</label>
            <input
              type='text'
              {...formik.getFieldProps('latitude')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.latitude && formik.errors.latitude},
                {
                  'is-valid': formik.touched.latitude && !formik.errors.latitude,
                }
              )}
              value={formik.values.latitude}
            />
          </div>
          <div className='col-md-6 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'COMMON.LONGITUDE'})}</label>
            <input
              type='text'
              {...formik.getFieldProps('longitude')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.longitude && formik.errors.longitude},
                {
                  'is-valid': formik.touched.longitude && !formik.errors.longitude,
                }
              )}
              value={formik.values.longitude}
            />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button type='button' className='btn btn-light' onClick={handleClose}>
          {intl.formatMessage({id: 'COMMON.CANCEL'})}
        </button>
        <button
          type='button'
          className='btn btn-success'
          onClick={() => {
            formik.handleSubmit()
          }}
          disabled={loading}
          data-kt-indicator={loading === true ? 'on' : 'off'}
        >
          <span className='indicator-label'>{intl.formatMessage({id: 'COMMON.SAVE'})}</span>

          <span className='indicator-progress'>
            {intl.formatMessage({id: 'COMMON.PLS_WAIT'})}{' '}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        </button>
      </Modal.Footer>
    </Modal>
  )
})
