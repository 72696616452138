import {FC} from 'react'
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {App} from '../App'

import {OtherPublicOfferIndex} from '../pages/other/public-offer'
import {OtherPublicOfferAxmadIndex} from '../pages/other/public-offer-axmad'
import {useAuth} from '../modules/auth/useAuth'
import {Logout} from '../modules/auth/Logout'
import {AuthPage} from '../modules/auth/AuthPage'
import Loader from '../components/Loader'

const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {isAuthenticated, loading} = useAuth()
  if (loading) return <Loader />

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          <Route path='public-offer' element={<OtherPublicOfferIndex />} />
          <Route path='axmad/public-offer' element={<OtherPublicOfferAxmadIndex />} />
          {isAuthenticated ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
