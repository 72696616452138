import clsx from 'clsx'
import moment from 'moment'
import React from 'react'
import {useIntl} from 'react-intl'
import {useNavigate, useParams} from 'react-router-dom'

import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {swalDefaultConfig} from '../../../../_metronic/helpers/constants'
import {convertToPrice, getMediaUrl} from '../../../../_metronic/helpers/general'
import {swal} from '../../../../_metronic/helpers/swal'
import {PageTitle, useLayout} from '../../../../_metronic/layout/core'
import Loader from '../../../components/Loader'
import FooterCopyright from '../../../modules/FooterCopyright'
import axiosInstance from '../../../utils/axiosInstance'

export function AccountViewIndex() {
  let navigate = useNavigate()
  const intl = useIntl()
  const windowSize = React.useRef([window.innerWidth, window.innerHeight])

  let {id} = useParams()
  const {classes} = useLayout()

  const [data, setData] = React.useState<any>({})
  const [statuses, setStatuses] = React.useState([])
  const [paymentTypes, setPaymentTypes] = React.useState([])
  const [error, setError] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)

  async function getData() {
    try {
      const res = await axiosInstance.get(`accounts/${id}/`)
      setData(res.data || {})
      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      if (err.response.status === 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }

  React.useEffect(() => {
    if (!id) {
      navigate('/error/404')
      return
    }
    setIsLoading(true)

    getData()
    getStatuses()
    getPaymentTypes()
  }, [])

  const refreshData = async () => {
    await getData()
  }

  async function getStatuses() {
    try {
      const res = await axiosInstance.get('/statuses/')

      setStatuses(res.data.data)
    } catch (err: any) {
      console.log('error', err)
    }
  }

  async function getPaymentTypes() {
    try {
      const res = await axiosInstance.get('/payment-types/')

      setPaymentTypes(res.data.data)
    } catch (err: any) {
      console.log('error', err)
    }
  }

  async function setBlocked() {
    // try {
    //   const res = await axiosInstance.put(`accounts/block/${id}?value=1`)
    // } catch (err: any) {
    //   console.log('error', err)
    //   setError(err)
    // }
    try {
      const res = await axiosInstance.patch(`accounts/block/`, {
        account_ids: [parseInt(id || '0', 10)],
        is_blocked: true,
      })
    } catch (err: any) {
      console.log('error', err)
      setError(err)
    }
  }

  async function setUnBlocked() {
    try {
      const res = await axiosInstance.patch(`accounts/block/`, {
        account_ids: [parseInt(id || '0', 10)],
        is_blocked: false,
      })
    } catch (err: any) {
      console.log('error', err)
      setError(err)
    }
  }

  const getOrdersTableContent = (arr: any) => {
    if (!arr || !arr.length) return []

    let content = []

    for (const [i, value] of arr.entries()) {
      content.push(
        <tr
          key={'list-' + value.id}
          className='c-pointer text-nowrap'
          onClick={() => {
            // navigate(`/orders/view/${value.id}`)
            navigate(`/orders/edit-order?orderId=${value.id}`)
            return
          }}
        >
          <td>{value.id}</td>
          <td>{value.number}</td>
          <td>{value.system}</td>
          <td>{value.name}</td>
          <td>{value.phone}</td>
          <td>{moment(value.created_at).format('DD/MM/YYYY HH:mm:ss')}</td>
          <td>{(value.terminal || {}).name_ru}</td>
          <td>
            {/* @ts-ignore: */}
            {(
              paymentTypes.find((x: any) => x.code === (value.payment_type || {}).code) || {
                name_ru: '',
              }
            ).name_ru || 'Не задано'}
          </td>
          <td>
            {convertToPrice(value.full_sum)} {intl.formatMessage({id: 'COMMON.SUM'})}
          </td>
          <td>
            <span className='badge badge-light-success'>
              {/* @ts-ignore: */}
              {(statuses.find((x: any) => x.id === value.status) || {}).description_ru ||
                intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}
            </span>
          </td>
        </tr>
      )
    }

    return content
  }

  const getLanguagesName = (name: string) => {
    if (name === 'ru') return intl.formatMessage({id: 'COMMON.LANG.RU'})
    if (name === 'en') return intl.formatMessage({id: 'COMMON.LANG.EN'})
    return intl.formatMessage({id: 'COMMON.LANG.UZ'})
  }

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
      {
        title: `${intl.formatMessage({id: 'MENU.CLIENT'})}`,
        path: '/accounts',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }

  if (isLoading) return <Loader />

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>{data?.name}</PageTitle>
      <div className='h-100 d-flex flex-column flex-column-fluid'>
        <div
          className='h-30 card-body main-overflow-x'
          // style={{height: windowSize.current[1] - 140}}
        >
          <div className='d-flex flex-column flex-xl-row'>
            <div className='flex-column flex-lg-row-auto w-100 w-xl-350px'>
              <div className='card mb-5 mb-xl-8'>
                <div className='card-body pt-10'>
                  <div className='d-flex flex-center flex-column'>
                    <div className='symbol symbol-150px symbol-circle mb-7'>
                      <img
                        src={
                          data?.image
                            ? getMediaUrl('users', data?.image)
                            : toAbsoluteUrl('/avatar.png')
                        }
                        alt={data?.name}
                      />
                    </div>
                    <div className='fs-3 text-gray-800  mb-1'>{data?.name}</div>
                    <div className='fs-5 fw-semibold text-muted mb-1'>+{data?.phone}</div>
                  </div>
                  <div className='d-flex flex-stack fs-4 py-3'>
                    <div className=''>{intl.formatMessage({id: 'COMMON.BLOCKED'})}</div>
                    <div
                      className={clsx(
                        'badge d-inline',
                        {'badge-success': data?.is_blocked},
                        {
                          'badge-danger': !data?.is_blocked,
                        }
                      )}
                    >
                      {data.is_blocked
                        ? intl.formatMessage({id: 'COMMON.YES'})
                        : intl.formatMessage({id: 'COMMON.NO'})}
                    </div>
                  </div>
                  <div className='separator separator-dashed my-1' />
                  <div className='pb-3 fs-6'>
                    <div className=' mt-5'>{intl.formatMessage({id: 'COMMON.ACCOUNT_ID'})}</div>
                    <div className='text-gray-600'>{data?.id}</div>
                    <div className=' mt-5'>{intl.formatMessage({id: 'COMMON.REGION'})}</div>
                    <div className='text-gray-600'>{data?.region?.name || '-'}</div>
                    <div className=' mt-5'>{intl.formatMessage({id: 'COMMON.BIRTH_DATE'})}</div>
                    <div className='text-gray-600'>
                      {data?.birthday ? moment(data?.birthday).format('DD/MM/YYYY') : '-'}
                    </div>
                    <div className=' mt-5'>{intl.formatMessage({id: 'COMMON.GENDER'})}</div>
                    <div className='text-gray-600'>
                      {data?.gender === 0
                        ? intl.formatMessage({id: 'COMMON.FEMALE'})
                        : intl.formatMessage({id: 'COMMON.MALE'})}
                    </div>
                    {/* <div className=' mt-5'>Delivery Address</div>
                    <div className='text-gray-600'>
                      101 Collin Street, <br />
                      Melbourne 3000 VIC
                      <br />
                      Australia
                    </div> */}
                    <div className=' mt-5'>{intl.formatMessage({id: 'COMMON.LANGUAGE'})}</div>
                    <div className='text-gray-600'>{getLanguagesName(data.language)}</div>
                    <div className=' mt-5'>{intl.formatMessage({id: 'COMMON.CREATED_AT'})}</div>
                    <div className='text-gray-600'>{moment(data.date).format('DD/MM/YYYY')}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex-lg-row-fluid ms-lg-5'>
              <ul
                className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-4'
                role='tablist'
              >
                <li className='nav-item' role='presentation'>
                  <a
                    className='nav-link text-active-primary pb-2 active'
                    data-bs-toggle='tab'
                    href='#kt_ecommerce_customer_overview'
                    aria-selected='true'
                    role='tab'
                  >
                    {intl.formatMessage({id: 'COMMON.OVERVIEW'})}
                  </a>
                </li>
                <li className='nav-item' role='presentation'>
                  <a
                    className='nav-link text-active-primary pb-2'
                    data-bs-toggle='tab'
                    href='#kt_ecommerce_customer_general'
                    aria-selected='false'
                    tabIndex={-1}
                    role='tab'
                  >
                    {intl.formatMessage({id: 'COMMON.HISTORY_OF_ORDERS'})}
                  </a>
                </li>
              </ul>
              <div className='tab-content' id='myTabContent'>
                <div
                  className='tab-pane fade show active'
                  id='kt_ecommerce_customer_overview'
                  role='tabpanel'
                >
                  <div className='row row-cols-1 row-cols-md-2 mb-6 mb-xl-9'>
                    <div className='col-md-4 mb-3'>
                      <div className='card mb-6'>
                        <div className='px-4 py-5'>
                          <h2 className=''>
                            {(data?.order_count || 0) +
                              ' ' +
                              intl.formatMessage({id: 'COMMON.PCS'})}
                          </h2>
                          <div className='  text-muted'>
                            {intl.formatMessage({id: 'COMMON.ORDERS_COUNT'})}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4 mb-3'>
                      <div className='card mb-6'>
                        <div className='px-4 py-5'>
                          <h2 className=''>
                            {convertToPrice(Math.round(data?.order_total || 0)) +
                              ' ' +
                              intl.formatMessage({id: 'COMMON.SUM'})}
                          </h2>
                          <div className='  text-muted'>
                            {intl.formatMessage({id: 'COMMON.TOTAL_BUY_SUM'})}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4 mb-3'>
                      <div className='card mb-6'>
                        <div className='px-4 py-5'>
                          <h2 className=''>
                            {(data?.order_avg ? convertToPrice(Math.round(data?.order_avg)) : 0) +
                              ' ' +
                              intl.formatMessage({id: 'COMMON.SUM'})}
                          </h2>
                          <div className=' fs-2'>
                            <div className='fs-7 fw-normal text-muted'>
                              {intl.formatMessage({id: 'COMMON.AVG_CHECK'})}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4 mb-3'>
                      <div className='card mb-6'>
                        <div className='px-4 py-5'>
                          <h2 className=''>
                            {(data?.order_processed_count ? data?.order_processed_count : 0) +
                              ' ' +
                              intl.formatMessage({id: 'COMMON.PCS'})}
                          </h2>

                          <div className='text-muted'>
                            {intl.formatMessage({id: 'COMMON.PROCESSED_ORDERS'})}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4 mb-3'>
                      <div className='card mb-6'>
                        <div className='px-4 py-5'>
                          <h2 className=''>
                            {(data?.order_max ? convertToPrice(Math.round(data?.order_max)) : 0) +
                              ' ' +
                              intl.formatMessage({id: 'COMMON.SUM'})}
                          </h2>
                          <div className='text-muted'>
                            {intl.formatMessage({id: 'COMMON.EXPENSIVE_ORDER'})}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4 mb-3'>
                      <div className='card mb-6'>
                        <div className='px-4 py-5'>
                          <h2 className=''>
                            {(data?.order_min ? convertToPrice(Math.round(data?.order_min)) : 0) +
                              ' ' +
                              intl.formatMessage({id: 'COMMON.SUM'})}
                          </h2>
                          <div className='text-muted'>
                            {intl.formatMessage({id: 'COMMON.CHEAP_ORDER'})}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className='col-md-4 mb-3'>
                      <div className='card mb-6'>
                        <div className='px-4 py-5'>
                          <h2 className=''>
                            {(data?.order_canceled_count ? data?.order_canceled_count : 0) +
                              ' ' +
                              intl.formatMessage({id: 'COMMON.PCS'})}
                          </h2>
                          <div className='fs-7 fw-normal text-muted'>
                            {intl.formatMessage({id: 'COMMON.CANCELED_ORDERS'})}
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className='tab-pane fade' id='kt_ecommerce_customer_general' role='tabpanel'>
                  <div className='card mb-xl-9'>
                    <div className='card-header border-0'>
                      <div className='card-title'>
                        <h2>{intl.formatMessage({id: 'COMMON.HISTORY_OF_ORDERS'})}</h2>
                      </div>
                    </div>
                    <div className='card-body py-0 pb-5'>
                      <div className='table-responsive'>
                        <table className='table table-hover border table-row-dashed table-row-gray-300 gy-2 gs-1'>
                          <thead>
                            <tr className=' bg-light'>
                              <th>ID</th>
                              <th className='min-w-10px'>
                                {intl.formatMessage({id: 'COMMON.CHEQUE'})}
                              </th>
                              <th className='min-w-50px'>
                                {intl.formatMessage({id: 'COMMON.SYSTEM'})}
                              </th>
                              <th className='min-w-50px'>
                                {intl.formatMessage({id: 'COMMON.CLIENT'})}
                              </th>
                              <th className='min-w-50px'>
                                {intl.formatMessage({id: 'COMMON.PHONE'})}
                              </th>
                              <th className='min-w-50px'>
                                {intl.formatMessage({id: 'COMMON.DATE'})}
                              </th>
                              <th className='min-w-50px'>
                                {intl.formatMessage({id: 'COMMON.BRANCH'})}
                              </th>
                              <th className='min-w-50px'>
                                {intl.formatMessage({id: 'COMMON.PAYMENT_TYPE'})}
                              </th>
                              <th className='min-w-50px'>
                                {intl.formatMessage({id: 'COMMON.TOTAL'})}
                              </th>
                              <th className='min-w-50px'>
                                {intl.formatMessage({id: 'COMMON.STATUS'})}
                              </th>
                            </tr>
                          </thead>
                          {/* @ts-ignore: */}
                          <tbody>{getOrdersTableContent(data.orders || [])}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='card card-stretch py-4 d-flex flex-lg-column' id='kt_footer'>
          <div
            className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
          >
            <div className='order-1 d-flex align-items-center mb-2 mb-md-0'></div>

            {<FooterCopyright />}
          </div>
        </div>
      </div>
    </>
  )
}
