import {Navigate, Route, Routes} from 'react-router-dom'
import {CustomLayout} from '../../_metronic/layout/CustomLayout'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'

import {SettingsIndex} from '../pages/settings/Index'
import {UsersIndex} from '../pages/users/Index'
import {BranchesIndex} from '../pages/branches/Index'
import {RestaurantsIndex} from '../pages/restaurants/Index'
import {PagesIndex} from '../pages/pages/Index'
import {NewsIndex} from '../pages/news/Index'
import {GalleryIndex} from '../pages/gallery/Index'
import {SliderIndex} from '../pages/slider/Index'
import {NotificationsIndex} from '../pages/notifications/Index'
import {AccountsIndex} from '../pages/accounts/Index'
import {AccountViewIndex} from '../pages/accounts/view/Index'
import {FeedbacksIndex} from '../pages/feedbacks/Index'
import {OrdersIndex} from '../pages/orders/Index'
import {CreateOrder} from '../pages/orders/CreateOrder'
import {GroupsIndex} from '../pages/groups/Index'
import {GroupsMenuIndex} from '../pages/groups/Menu'
import {GroupsProductsIndex} from '../pages/groups/products/index'
import {GroupsProductsItemIndex} from '../pages/groups/products/item/Index'
import {HeatMapIndex} from '../pages/heat-map/Index'
import {RolesIndex} from '../pages/roles/Index'
import {RolesItemsIndex} from '../pages/roles/items/Index'
import {OrganizationsIndex} from '../pages/organizations/Index'
import {StatisticsAccountsIndex} from '../pages/statistics/Accounts'
import {StatisticsIndex} from '../pages/statistics/Index'
import {StatisticsLocationIndex} from '../pages/statistics/Location'
import {StatisticsUsersIndex} from '../pages/statistics/Users'
import {StatisticsOrderTypeIndex} from '../pages/statistics/OrderType'
import {StatisticsPaymentTypeIndex} from '../pages/statistics/PaymentType'
import {StatisticsProductIndex} from '../pages/statistics/Product'
import {StatisticsSystemIndex} from '../pages/statistics/System'
import {StatisticsTerminalsIndex} from '../pages/statistics/Terminals'
import {StatisticsCouriersIndex} from '../pages/statistics/Couriers'
import {StatisticsAbcIndex} from '../pages/statistics/Abc'

import ProfileIndex from '../pages/profile'
import {EditOrder} from '../pages/orders/EditOrder'
import {CouriersMapIndex} from '../pages/couriers-map/Index'
import {PaymentTypes} from '../pages/payment-types'
import {CouriersIndex} from '../pages/couriers'
import {CouriersItemIndex} from '../pages/couriers/view'
import {StatisticsXyzIndex} from '../pages/statistics/Xyz'
import {StatisticsAbcXyzIndex} from '../pages/statistics/Abcxyz'
import {StatisticsRfmIndex} from '../pages/statistics/Rfm'

import {GroupsProductsTestItemIndex} from '../pages/groups/products/item/Index copy'
import {UserItemIndex} from '../pages/users/view'
import {RedirectCalls} from '../pages/redirect/Calls'
import {StatisticsAbcClienntsIndex} from '../pages/statistics/Abc_clients'
import {ViewOrder} from '../pages/orders/ViewOrder'
import {NewOrder} from '../pages/orders/NewOrder'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<CustomLayout />}>
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />

        <Route path='dashboard' element={<DashboardWrapper />} />

        <Route path='statistics' element={<StatisticsIndex />} />
        <Route path='statistics/accounts' element={<StatisticsAccountsIndex />} />
        <Route path='statistics/location' element={<StatisticsLocationIndex />} />
        <Route path='statistics/users' element={<StatisticsUsersIndex />} />
        <Route path='statistics/order-type' element={<StatisticsOrderTypeIndex />} />
        <Route path='statistics/payment-type' element={<StatisticsPaymentTypeIndex />} />
        <Route path='statistics/products' element={<StatisticsProductIndex />} />
        <Route path='statistics/system' element={<StatisticsSystemIndex />} />
        <Route path='statistics/terminals' element={<StatisticsTerminalsIndex />} />
        <Route path='statistics/couriers' element={<StatisticsCouriersIndex />} />
        <Route path='statistics/abc' element={<StatisticsAbcIndex />} />
        <Route path='statistics/abc-clients' element={<StatisticsAbcClienntsIndex />} />
        <Route path='statistics/xyz' element={<StatisticsXyzIndex />} />
        <Route path='statistics/abcxyz' element={<StatisticsAbcXyzIndex />} />
        <Route path='statistics/rfm' element={<StatisticsRfmIndex />} />

        <Route path='profile' element={<ProfileIndex />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='/settings' element={<SettingsIndex />} />
        <Route path='/users' element={<UsersIndex />} />
        <Route path='/users/:id' element={<UserItemIndex />} />
        <Route path='/branches' element={<BranchesIndex />} />
        <Route path='/restaurants' element={<RestaurantsIndex />} />
        <Route path='/pages' element={<PagesIndex />} />
        <Route path='/news' element={<NewsIndex />} />
        <Route path='/gallery' element={<GalleryIndex />} />
        <Route path='/slider' element={<SliderIndex />} />
        <Route path='/payment-types' element={<PaymentTypes />} />
        <Route path='/notifications' element={<NotificationsIndex />} />
        <Route path='/accounts' element={<AccountsIndex />} />
        <Route path='/account/:id' element={<AccountViewIndex />} />
        <Route path='/feedbacks' element={<FeedbacksIndex />} />
        <Route path='/orders' element={<OrdersIndex />} />
        <Route path='/orders/view/:id' element={<ViewOrder />} />
        <Route path='/orders/new-order' element={<NewOrder />} />
        <Route path='/orders/create-order' element={<CreateOrder />} />
        <Route path='/orders/edit-order' element={<EditOrder />} />
        <Route path='/couriers-map' element={<CouriersMapIndex />} />
        <Route path='/couriers' element={<CouriersIndex />} />
        <Route path='/couriers/:id' element={<CouriersItemIndex />} />
        <Route path='/organizations' element={<OrganizationsIndex />} />
        <Route path='/groups' element={<GroupsIndex />} />
        <Route path='/groups/menu/:org_id' element={<GroupsMenuIndex />} />
        <Route
          path='/groups/menu/:org_id/products/:parent_group'
          element={<GroupsProductsIndex />}
        />
        <Route
          path='/groups/menu/:org_id/products/:parent_group/:product_id'
          element={<GroupsProductsItemIndex />}
        />
        <Route
          path='/groups/menu/:org_id/products/test/:parent_group/:product_id'
          element={<GroupsProductsTestItemIndex />}
        />

        <Route path='/heat-map' element={<HeatMapIndex />} />

        <Route path='roles' element={<RolesIndex />} />
        <Route path='roles/:role_id/items' element={<RolesItemsIndex />} />
        <Route path='/calls/new' element={<RedirectCalls />} />

        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export {PrivateRoutes}
