import React, {useCallback, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {PageTitle, useLayout} from '../../../_metronic/layout/core'
import moment from 'moment'

import ReactPaginate from 'react-paginate'
import ImageViewer from 'react-simple-image-viewer'

// COMPONENTS
import {KTSVG} from '../../../_metronic/helpers'
import Loader from '../../components/Loader'
import FooterCopyright from '../../modules/FooterCopyright'
import {AddModal} from './components/AddModal'
import {ItemModal} from './components/ItemModal'

// HELPERS
import {swalDefaultConfig} from '../../../_metronic/helpers/constants'
import {getMediaUrl} from '../../../_metronic/helpers/general'
import {swal} from '../../../_metronic/helpers/swal'
import {useAuth} from '../../modules/auth/useAuth'
import axiosInstance from '../../utils/axiosInstance'
import _ from 'lodash'

export function OrganizationsIndex() {
  const intl = useIntl()

  const {classes} = useLayout()
  const {hasAccess} = useAuth()

  let navigate = useNavigate()
  let [searchParams, setSearchParams] = useSearchParams()
  const windowSize = React.useRef([window.innerWidth, window.innerHeight])

  // FILTERS
  let page = parseInt(searchParams.get('page') as string, 10) || 1
  let page_size = parseInt(searchParams.get('page_size') as string, 10) || 50
  let multi_search = searchParams.get('multi_search') || ''

  // LOCAL STATE
  const [data, setData] = React.useState([])
  const [cities, setCities] = React.useState([])
  const [choosenItem, setChoosenItem] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [error, setError] = React.useState(null)
  const [paginationData, setPaginationData] = React.useState({
    count: 1,
    page: 1,
    page_size: 50,
    totalPageCount: 1,
  })

  const [selectedItems, setSelectedItems] = React.useState<any>([])

  const addModalRef = React.useRef()
  const itemModalRef = React.useRef()
  const imagesRef = React.useRef()

  // ImageViewer
  const [currentImage, setCurrentImage] = useState(0)
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  // @ts-ignore
  let viewerImages = data
    // @ts-ignore
    // .filter((x) => x.type === 'photo')
    // @ts-ignore
    .map((x) => getMediaUrl('notifications', x.media))
  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }, [])
  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }
  // End of ImageViewer

  const getQueryObject = (): any => {
    let obj: {
      page?: number
      page_size?: number
      multi_search?: string
    } = {}

    if (!!page) obj.page = page
    if (!!page_size) obj.page_size = page_size
    if (!!multi_search) obj.multi_search = multi_search

    return obj
  }
  const buildQueryParams = () => {
    let query = `?page=${page}&page_size=${page_size}`

    if (!!multi_search) query += `&multi_search=${multi_search}`

    return query
  }

  async function getData() {
    try {
      const res = await axiosInstance.get(`organization/${buildQueryParams()}`)
      setData(res.data.organizations || [])
      setPaginationData({
        count: res.data.count,
        page: res.data.page,
        page_size: res.data.page_size,
        totalPageCount: Math.ceil(res.data.count / res.data.page_size),
      })
      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      if (err.response.status === 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
      setError(err)
    }
  }

  async function getCities() {
    try {
      const result = await axiosInstance.get('/regions/?is_page_all=true')
      setCities(result.data.data)
    } catch (err: any) {
      if (err.response.status === 404) {
        setIsLoading(false)
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
      setError(err)
    }
  }

  useEffect(() => {
    getData()
  }, [searchParams])

  useEffect(() => {
    setIsLoading(true)
    getData()
    getCities()
  }, [])

  const refreshData = async () => {
    await getData()
  }

  React.useEffect(() => {
    // @ts-ignore
    if (!!choosenItem && !!choosenItem.id) {
      // @ts-ignore
      setChoosenItem(data.find((x) => x.id === choosenItem.id) || null)
    }
  }, [data])

  const itemClick = (item: any) => {
    setChoosenItem(item)
  }

  const handlePaginationClick = (event: any) => {
    setSearchParams({...getQueryObject(), page: event.selected + 1})
  }

  const changeSelectedItems = (array: any, allSelect: boolean = false) => {
    let items: any = []
    items = selectedItems

    setData([...data])

    if (allSelect) {
      if (items.length >= array.length) {
        setSelectedItems([])
        return
      }
      let getAllId: any = []
      array.map((item: any) => {
        getAllId.push(item.id)
      })
      setSelectedItems(getAllId)
      return
    }
    array.map((item: any) => {
      let newSelectedItem = items.filter((selectItem: any) => selectItem === item.id)
      let newSelectedItems = items.filter((selectItem: any) => selectItem !== item.id)

      if (newSelectedItem.length > 0) {
        items = newSelectedItems
        return
      }
      items.push(item.id)
    })
    setSelectedItems(items)
  }

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }

  if (isLoading) return <Loader />

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>
        {intl.formatMessage({id: 'MENU.ORGANIZATION'})}
      </PageTitle>
      <div className='h-100 d-flex flex-column flex-column-fluid'>
        <div className='card card-stretch mb-2'>
          <div className='card-header'>
            <div className='d-flex align-items-center justify-content-center'>
              {/* {hasAccess('organizations-add') && (
                <button
                  className='btn btn-success me-3'
                  data-bs-toggle='modal' // @ts-ignore
                  onClick={() => addModalRef.current.showModal()}
                >
                  {intl.formatMessage({id: 'COMMON.ADD'})}
                </button>
              )} */}

              <div className='d-flex align-items-center position-relative input-group-sm my-1 me-3'>
                <KTSVG
                  path='/media/icons/duotune/general/gen021.svg'
                  className='svg-icon-1 position-absolute ms-4'
                />
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control-solid w-250px ps-14'
                  placeholder={intl.formatMessage({id: 'COMMON.SEARCH'})}
                  value={multi_search}
                  onChange={(e) =>
                    setSearchParams({...getQueryObject(), multi_search: e.target.value})
                  }
                />

                {multi_search.length > 0 && (
                  <button
                    className='btn link btn-sm p-0 ms-3'
                    data-bs-toggle='modal'
                    onClick={(e) => setSearchParams({...getQueryObject(), multi_search: ''})}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen040.svg'
                      className='svg-icon-2hx svg-icon-danger'
                    />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='card card-stretch mb-2 flex-column-fluid'>
          <div className='card-body main-overflow-x' style={{height: windowSize.current[1] - 320}}>
            <div className='table-responsive'>
              <table className='table table-hover border table-row-dashed table-row-gray-300 gy-2 gs-1'>
                <thead>
                  <tr className=' bg-light'>
                    <th className='w-25px'>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value='1'
                          checked={data.length <= selectedItems.length}
                          onChange={(e) => {
                            changeSelectedItems(data, true)
                          }}
                        />
                      </div>
                    </th>
                    <th className='w-25px'>#</th>
                    <th>{intl.formatMessage({id: 'COMMON.NAME'})}</th>
                    <th>{intl.formatMessage({id: 'COMMON.KEY'})}</th>
                    <th className='text-center'>{intl.formatMessage({id: 'COMMON.MAIN'})}</th>
                    <th>{intl.formatMessage({id: 'COMMON.LATITUDE'})}</th>
                    <th>{intl.formatMessage({id: 'COMMON.LONGITUDE'})}</th>
                    <th>{intl.formatMessage({id: 'COMMON.LAST_REVISION'})}</th>
                  </tr>
                </thead>
                <tbody>
                  {_.sortBy(data, function (o) {
                    return !o.is_main
                  }).map((value, idx) => (
                    <tr
                      key={'list-' + value.id}
                      className='c-pointer'
                      onClick={() => {
                        if (!hasAccess('organizations-edit')) return
                        itemClick(value)
                        // @ts-ignore
                        itemModalRef.current.showModal()
                      }}
                    >
                      <td>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input widget-9-check'
                            type='checkbox'
                            value='1'
                            checked={selectedItems.includes(value.id)}
                            onClick={(e) => {
                              e.stopPropagation()
                              changeSelectedItems([value])
                            }}
                          />
                        </div>
                      </td>
                      <td>{idx + 1}</td>
                      <td>{value.name || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}</td>
                      <td>{value.key || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}</td>
                      <td className='text-center'>
                        {value.is_main === 1
                          ? intl.formatMessage({id: 'COMMON.YES'})
                          : intl.formatMessage({id: 'COMMON.NO'})}
                      </td>
                      <td>{value.latitude || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}</td>
                      <td>{value.longitude || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}</td>
                      <td>{moment(value.synced_at || null).format('DD/MM/YYYY HH:mm')}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* FOOTER */}
        <div className='card card-stretch py-4 d-flex flex-lg-column' id='kt_footer'>
          <div
            className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
          >
            <div className='order-1 d-flex align-items-center mb-2 mb-md-0'>
              <div className='dataTables_length' id='kt_ecommerce_report_sales_table_length'>
                <label>
                  <select
                    name='kt_ecommerce_report_sales_table_length'
                    aria-controls='kt_ecommerce_report_sales_table'
                    className='form-select form-select-sm form-select-solid'
                    value={page_size}
                    onChange={(e) =>
                      setSearchParams({...getQueryObject(), page_size: e.target.value})
                    }
                  >
                    <option value='10'>10</option>
                    <option value='25'>25</option>
                    <option value='50'>50</option>
                    <option value='100'>100</option>
                  </select>
                </label>
              </div>

              {paginationData.totalPageCount > 1 ? (
                <ReactPaginate
                  breakLabel='...'
                  nextLabel='>'
                  previousLabel='<'
                  onPageChange={handlePaginationClick}
                  initialPage={page - 1}
                  pageRangeDisplayed={2}
                  pageCount={paginationData.totalPageCount}
                  renderOnZeroPageCount={null}
                  containerClassName='pagination'
                  pageClassName='page-item'
                  pageLinkClassName='page-link'
                  breakClassName='page-item'
                  breakLinkClassName='page-link'
                  activeClassName='active'
                  previousClassName='page-item previous'
                  previousLinkClassName='page-link'
                  nextClassName='page-item next'
                  nextLinkClassName='page-link'
                  disabledClassName='disabled'
                />
              ) : (
                ''
              )}
            </div>

            {<FooterCopyright />}
          </div>
        </div>
      </div>

      <ItemModal item={choosenItem} refreshData={refreshData} cities={cities} ref={itemModalRef} />
      <AddModal refreshData={refreshData} cities={cities} ref={addModalRef} />
      {isViewerOpen && (
        <ImageViewer
          // @ts-ignore
          src={viewerImages}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
          closeOnClickOutside={true}
        />
      )}
    </>
  )
}
