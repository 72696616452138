import React, {FC} from 'react'

type PropsType = {
  show: boolean
}

const LoadingDataComponent: FC<PropsType> = (props) => {
  if (!props.show) return <></>
  return (
    <div className='d-flex align-items-center justify-content-start'>
      <div className='spinner-grow text-success me-2'></div>
      <div>Пожалуйста, подождите идет загрузка...</div>
    </div>
  )
}

export default LoadingDataComponent
