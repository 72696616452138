export const APPLICATIONS_STATUSES = [
  {
    id: 0,
    nameRu: 'Новый',
    nameUz: 'Yangi',
    nameEn: 'New',
    badge: 'warning',
  },
  {
    id: 1,
    nameRu: 'Оплачено',
    nameUz: "To'langan",
    nameEn: 'Paid',
    badge: 'success',
  },
  {
    id: 2,
    nameRu: 'Завершен',
    nameUz: 'Tugallangan',
    nameEn: 'Finished',
    badge: 'success',
  },
  {
    id: 3,
    nameRu: 'Отменён',
    nameUz: 'Bekor qilingan',
    nameEn: 'Canceled',
    badge: 'danger',
  },
]

export const APPLICATION_ITEMS_STATUSES = [
  {
    id: 0,
    nameRu: 'Новый',
    nameUz: 'Yangi',
    nameEn: 'New',
  },
  {
    id: 1,
    nameRu: 'Оплачено',
    nameUz: "To'langan",
    nameEn: 'Paid',
  },
  {
    id: 2,
    nameRu: 'В процессе',
    nameUz: 'Jarayonda',
    nameEn: 'In progress',
  },
  {
    id: 3,
    nameRu: 'Завершен',
    nameUz: 'Tugallangan',
    nameEn: 'Finished',
  },
  {
    id: 4,
    nameRu: 'Отменён',
    nameUz: 'Bekor qilingan',
    nameEn: 'Canceled',
  },
]

export const PATIENT_STATUSES = [
  {
    id: 1,
    nameRu: 'Активный',
    nameUz: 'Активный',
    nameEn: 'Активный',
  },
  {
    id: 2,
    nameRu: 'Заблокированный',
    nameUz: 'Заблокированный',
    nameEn: 'Заблокированный',
  },
]

export const MAIN_STATUSES = [
  {
    id: 1,
    boolean: true,
    nameRu: 'Активный',
    nameUz: 'Активный',
    nameEn: 'Активный',
    class: 'btn-active-light-success',
  },
  {
    id: 0,
    boolean: false,
    nameRu: 'Не активный',
    nameUz: 'Не активный',
    nameEn: 'Не активный',
    class: 'btn-active-light-danger',
  },
]

export const swalDefaultConfig: any = (title: string = '', icon = 'success') => {
  return {
    title: title,
    icon: icon,
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
  }
}

// DATE RANGE PICKER
export const daysOfWeek = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']

export const monthNames = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
]
