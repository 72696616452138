import React, {useCallback, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useSearchParams} from 'react-router-dom'
import {PageTitle, useLayout} from '../../../_metronic/layout/core'

import ReactPaginate from 'react-paginate'
import ImageViewer from 'react-simple-image-viewer'

// COMPONENTS
import {KTSVG} from '../../../_metronic/helpers'
import FooterCopyright from '../../modules/FooterCopyright'
import {AddModal} from './components/AddModal'
import {ItemModal} from './components/ItemModal'

// HELPERS
import {swalDefaultConfig} from '../../../_metronic/helpers/constants'
import {getMediaUrl} from '../../../_metronic/helpers/general'
import {swal} from '../../../_metronic/helpers/swal'
import Loader from '../../components/Loader'
import axiosInstance from '../../utils/axiosInstance'
import {useAuth} from '../../modules/auth/useAuth'
import _ from 'lodash'

export function SliderIndex() {
  const intl = useIntl()

  const {classes} = useLayout()
  const {hasAccess} = useAuth()

  let [searchParams, setSearchParams] = useSearchParams()
  const windowSize = React.useRef([window.innerWidth, window.innerHeight])

  // FILTERS
  let page = parseInt(searchParams.get('page') as string, 10) || 1
  let page_size = parseInt(searchParams.get('page_size') as string, 10) || 50
  let multi_search = searchParams.get('multi_search') || ''
  let with_delete = searchParams.get('with_delete') === 'true' ? true : false
  let not_active = searchParams.get('not_active') === 'true' ? true : false

  // LOCAL STATE
  const [data, setData] = React.useState([])
  const [choosenItem, setChoosenItem] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [paginationData, setPaginationData] = React.useState({
    count: 1,
    page: 1,
    page_size: 50,
    totalPageCount: 1,
  })

  const [selectedItems, setSelectedItems] = React.useState<any>([])

  const addModalRef = React.useRef()
  const itemModalRef = React.useRef()

  // ImageViewer
  const [currentImage, setCurrentImage] = useState(0)
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  // @ts-ignore
  let viewerImages = data.map((x) => getMediaUrl('sliders', x.image_ru))

  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }, [])

  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }

  const [currentImageUz, setCurrentImageUz] = useState(0)
  const [isViewerOpenUz, setIsViewerOpenUz] = useState(false)

  // @ts-ignore
  let viewerImagesUz = data.map((x) => getMediaUrl('sliders', x.image_uz))
  const openImageViewerUz = useCallback((index: number) => {
    setCurrentImageUz(index)
    setIsViewerOpenUz(true)
  }, [])
  const closeImageViewerUz = () => {
    setCurrentImageUz(0)
    setIsViewerOpenUz(false)
  }
  const [currentImageEn, setCurrentImageEn] = useState(0)
  const [isViewerOpenEn, setIsViewerOpenEn] = useState(false)
  // @ts-ignore
  let viewerImagesEn = data.map((x) => getMediaUrl('sliders', x.image_en))
  const openImageViewerEn = useCallback((index: number) => {
    setCurrentImageEn(index)
    setIsViewerOpenEn(true)
  }, [])
  const closeImageViewerEn = () => {
    setCurrentImageEn(0)
    setIsViewerOpenEn(false)
  }
  // End of ImageViewer

  const getQueryObject = (): any => {
    let obj: {
      page?: number
      page_size?: number
      multi_search?: string
      not_active?: boolean
      with_delete?: boolean
    } = {}

    if (!!page) obj.page = page
    if (!!page_size) obj.page_size = page_size
    if (!!multi_search) obj.multi_search = multi_search
    if (!!with_delete) obj.with_delete = with_delete
    if (!!not_active) obj.not_active = not_active

    return obj
  }

  const buildQueryParams = () => {
    let query = `?&page=${page}&page_size=${page_size}`

    if (!!multi_search) query += `&multi_search=${multi_search}`
    if (!!with_delete) query += `&with_delete=${with_delete}`
    if (!!not_active) query += `&all=${not_active}`

    return query
  }

  async function getData() {
    try {
      const res = await axiosInstance.get(`sliders/${buildQueryParams()}`)

      setData(res.data.data || [])

      setPaginationData({
        count: res.data.count,
        page: res.data.page,
        page_size: res.data.page_size,
        totalPageCount: Math.ceil(res.data.count / res.data.page_size),
      })
      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      if (err.response.status === 404) {
        setIsLoading(false)
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }

  useEffect(() => {
    getData()
  }, [searchParams])

  useEffect(() => {
    setIsLoading(true)
    getData()
  }, [])

  const refreshData = async () => {
    await getData()
  }

  React.useEffect(() => {
    // @ts-ignore
    if (!!choosenItem && !!choosenItem.id) {
      // @ts-ignore
      setChoosenItem(data.find((x) => x.id === choosenItem.id) || null)
    }
  }, [data])

  const itemClick = (item: any) => {
    setChoosenItem(item)
  }

  const handlePaginationClick = (event: any) => {
    setSearchParams({...getQueryObject(), page: event.selected + 1})
  }

  const changeSelectedItems = (array: any, allSelect: boolean = false) => {
    let items: any = []
    items = selectedItems

    setData([...data])

    if (allSelect) {
      if (items.length >= array.length) {
        setSelectedItems([])
        return
      }
      let getAllId: any = []
      array.map((item: any) => {
        getAllId.push(item.id)
      })
      setSelectedItems(getAllId)
      return
    }
    array.map((item: any) => {
      let newSelectedItem = items.filter((selectItem: any) => selectItem === item.id)
      let newSelectedItems = items.filter((selectItem: any) => selectItem !== item.id)

      if (newSelectedItem.length > 0) {
        items = newSelectedItems
        return
      }
      items.push(item.id)
    })
    setSelectedItems(items)
  }

  async function changeStatus(status: boolean) {
    try {
      const res = await axiosInstance.patch(
        '/sliders/status/',
        {
          is_active: status,
          slider_ids: [...selectedItems],
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      setSelectedItems([])
      getData()
      swal.fire(
        swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_UPDATE'}), 'success')
      )
    } catch (err: any) {
      if (err.response.status === 400) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
      return
    }
  }

  const clearSearchParams = () => {
    searchParams.delete('with_delete')
    searchParams.delete('not_active')
    setSearchParams(searchParams)
  }

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }

  if (isLoading) return <Loader />

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>
        {intl.formatMessage({id: 'MENU.SLIDER'})}
      </PageTitle>
      <div className='h-100 d-flex flex-column flex-column-fluid'>
        <div className='card card-stretch mb-2'>
          <div className='card-header'>
            <div className='d-flex align-items-center justify-content-center'>
              {hasAccess('slider-add') && (
                <button
                  className='btn btn-success me-3'
                  data-bs-toggle='modal' // @ts-ignore
                  onClick={() => addModalRef.current.showModal()}
                >
                  {intl.formatMessage({id: 'COMMON.ADD'})}
                </button>
              )}

              <div className='d-flex align-items-center position-relative input-group-sm my-1 me-3'>
                <KTSVG
                  path='/media/icons/duotune/general/gen021.svg'
                  className='svg-icon-1 position-absolute ms-4'
                />
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control-solid w-250px ps-14'
                  placeholder={intl.formatMessage({id: 'COMMON.SEARCH'})}
                  value={multi_search}
                  onChange={(e) =>
                    setSearchParams({...getQueryObject(), multi_search: e.target.value})
                  }
                />

                {multi_search.length > 0 && (
                  <button
                    className='btn link btn-sm p-0 ms-3'
                    data-bs-toggle='modal'
                    onClick={(e) => setSearchParams({...getQueryObject(), multi_search: ''})}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen040.svg'
                      className='svg-icon-2hx svg-icon-danger'
                    />
                  </button>
                )}
              </div>
            </div>
            <div className='card-toolbar'></div>
          </div>
        </div>

        <div className='card card-stretch mb-2 flex-column-fluid'>
          <div className='card-body main-overflow-x' style={{height: windowSize.current[1] - 320}}>
            <div className='table-responsive'>
              <table className='table table-hover border table-row-dashed table-row-gray-300 gy-2 gs-1'>
                <thead>
                  <tr className=' bg-light'>
                    <th className='w-25px'>#</th>
                    <th className='w-150px'>{intl.formatMessage({id: 'ITEM.IMAGE_RU'})}</th>
                    <th className='w-150px'>{intl.formatMessage({id: 'ITEM.MOBILE_IMAGE_RU'})}</th>
                    <th>{intl.formatMessage({id: 'ITEM.NAME_RU'})}</th>
                    <th className='text-center'>{intl.formatMessage({id: 'COMMON.POSITION'})}</th>
                    <th className='text-center'>{intl.formatMessage({id: 'COMMON.STATUS'})}</th>
                  </tr>
                </thead>
                <tbody>
                  {_.sortBy(data, function (o) {
                    return o.position
                  }).map((value, idx) => (
                    <tr
                      key={'list-' + value.id}
                      className={`c-pointer ${!value.is_deleted || 'bg-danger bg-opacity-20'}`}
                      onClick={() => {
                        if (!hasAccess('slider-edit')) return
                        itemClick(value)
                        // @ts-ignore
                        itemModalRef.current.showModal()
                      }}
                    >
                      <td className='text-center'>{idx + 1}</td>
                      <td>
                        <img
                          className='row-img rounded'
                          src={getMediaUrl('sliders', value.image_ru)}
                          alt=''
                          onClick={(e) => {
                            e.stopPropagation()
                            openImageViewer(idx)
                          }}
                        />
                      </td>
                      <td>
                        <img
                          className='row-img rounded'
                          src={getMediaUrl('sliders', value.mobile_image_ru)}
                          alt=''
                          onClick={(e) => {
                            e.stopPropagation()
                            openImageViewer(idx)
                          }}
                        />
                      </td>
                      <td>{value.name_ru}</td>
                      <td className='text-center'>
                        {!!value.position
                          ? value.position
                          : intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}
                      </td>
                      <td className='text-center'>
                        {value.is_active ? (
                          <i className='fa-regular fa-square-check text-success'></i>
                        ) : (
                          <i className='far fa-window-close text-danger'></i>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* FOOTER */}
        <div className='card card-stretch py-4 d-flex flex-lg-column' id='kt_footer'>
          <div
            className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
          >
            <div className='order-1 d-flex align-items-center mb-2 mb-md-0'>
              <div className='dataTables_length' id='kt_ecommerce_report_sales_table_length'>
                <label>
                  <select
                    name='kt_ecommerce_report_sales_table_length'
                    aria-controls='kt_ecommerce_report_sales_table'
                    className='form-select form-select-sm form-select-solid'
                    value={page_size}
                    onChange={(e) =>
                      setSearchParams({...getQueryObject(), page_size: e.target.value})
                    }
                  >
                    <option value='10'>10</option>
                    <option value='25'>25</option>
                    <option value='50'>50</option>
                    <option value='100'>100</option>
                  </select>
                </label>
              </div>

              {paginationData.totalPageCount > 1 ? (
                <ReactPaginate
                  breakLabel='...'
                  nextLabel='>'
                  previousLabel='<'
                  onPageChange={handlePaginationClick}
                  initialPage={page - 1}
                  pageRangeDisplayed={2}
                  pageCount={paginationData.totalPageCount}
                  renderOnZeroPageCount={null}
                  containerClassName='pagination'
                  pageClassName='page-item'
                  pageLinkClassName='page-link'
                  breakClassName='page-item'
                  breakLinkClassName='page-link'
                  activeClassName='active'
                  previousClassName='page-item previous'
                  previousLinkClassName='page-link'
                  nextClassName='page-item next'
                  nextLinkClassName='page-link'
                  disabledClassName='disabled'
                />
              ) : (
                ''
              )}
            </div>

            {<FooterCopyright />}
          </div>
        </div>
      </div>

      <ItemModal item={choosenItem} refreshData={refreshData} ref={itemModalRef} />
      <AddModal refreshData={refreshData} ref={addModalRef} />
      {isViewerOpen && (
        <ImageViewer
          // @ts-ignore
          src={viewerImages}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
          closeOnClickOutside={true}
        />
      )}
      {isViewerOpenUz && (
        <ImageViewer
          // @ts-ignore
          src={viewerImagesUz}
          currentIndex={currentImageUz}
          onClose={closeImageViewerUz}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
          closeOnClickOutside={true}
        />
      )}
      {isViewerOpenEn && (
        <ImageViewer
          // @ts-ignore
          src={viewerImagesEn}
          currentIndex={currentImageEn}
          onClose={closeImageViewerEn}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
          closeOnClickOutside={true}
        />
      )}
    </>
  )
}
