import clsx from 'clsx'
import {useFormik} from 'formik'
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import {useAuth} from './useAuth'
import axiosInstance from '../../utils/axiosInstance'

const loginSchema = Yup.object().shape({
  login: Yup.string()
    .min(3, 'Минимум 3 символа')
    .max(50, 'Максимум 50 символов')
    .required('Логин обязателен'),
  password: Yup.string()
    .min(3, 'Минимум 3 символа')
    .max(50, 'Максимум 50 символов')
    .required('Пароль обязателен'),
})

const initialValues = {
  login: '',
  password: '',
}

export function Login() {
  const {logIn, isAuthenticated} = useAuth()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const intl = useIntl()

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard')
    }
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const response = await axiosInstance.post('/auth/login/', {
          username: values.login,
          password: values.password,
        })

        logIn(response.data)
        navigate('/dashboard', {replace: true})
      } catch (error) {
        // console.error('Login failed:', error)
        setStatus(error.message || error.response.data.error.message)
      } finally {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {formik.status ? (
        <div className='mb-7 alert alert-danger'>
          <div className='d-flex align-items-center'>
            <div className='me-3'>
              <i className='fas fa-exclamation-triangle text-danger'></i>
            </div>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className='fv-row mb-7'>
        <input
          {...formik.getFieldProps('login')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.login && formik.errors.login},
            {
              'is-valid': formik.touched.login && !formik.errors.login,
            }
          )}
          type='login'
          placeholder={intl.formatMessage({id: 'COMMON.LOGIN'})}
          name='login'
          autoComplete='off'
        />
        {formik.touched.login && formik.errors.login && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.login}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-10'>
        <input
          type='password'
          autoComplete='off'
          placeholder={intl.formatMessage({id: 'COMMON.PASSWORD'})}
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary btn-lg w-100 mb-6'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && (
            <span className='indicator-label'> {intl.formatMessage({id: 'COMMON.SIGNIN'})}</span>
          )}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'COMMON.PLS_WAIT'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        <div className='text-muted'>
          &copy; {new Date().getFullYear()} <a href='https://exord.uz/'>Exord</a>{' '}
          <span>Гарантия успеха</span>
        </div>
      </div>
    </form>
  )
}
