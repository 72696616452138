import {forwardRef, useImperativeHandle, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import * as Yup from 'yup'

import {useFormik} from 'formik'

import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'
import {swalDefaultConfig} from '../../../../_metronic/helpers/constants'
import {swal} from '../../../../_metronic/helpers/swal'
import axiosInstance from '../../../utils/axiosInstance'

export const DeleteModal = forwardRef((props: any, ref: any) => {
  const intl = useIntl()

  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  useImperativeHandle(ref, () => ({
    showModal() {
      handleShow()
    },
  }))

  const validationSchema = Yup.object().shape({})

  const initialValues = {}

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)

      try {
        const res = await axiosInstance.delete(`/users/${props.item.id}/`)

        swal.fire({
          title: intl.formatMessage({id: 'NOTIFICATION.SUCCESS_DELETE'}),
          icon: 'success',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        })
      } catch (err: any) {
        if (err.response.status == 404) {
          swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
        } else {
          swal.fire(
            swalDefaultConfig(
              intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}),
              'error'
            )
          )
        }
        setLoading(false)

        // console.log('error', err)
        // setStatus(intl.formatMessage({id: 'VALIDATION.API_ERROR'}, {text: err.message}))
      }
      setShow(false)
      setLoading(false)
      setSubmitting(false)
      props.refreshData()
    },
  })

  return (
    <Modal className='es-modal' size='lg' show={show} onHide={handleClose} centered>
      <Modal.Header>
        <h5 className='modal-title'>{intl.formatMessage({id: 'COMMON.DELETE'})}</h5>
        <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={handleClose}>
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
        </div>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          {formik.status ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          ) : (
            <div></div>
          )}
        </form>
        <h3>{intl.formatMessage({id: 'COMMON.WANT_DELETE'}, {name: props.item?.name})}</h3>
      </Modal.Body>
      <Modal.Footer>
        <button type='button' className='btn btn-light' onClick={handleClose}>
          {intl.formatMessage({id: 'COMMON.CANCEL'})}
        </button>
        <button
          type='button'
          className='btn btn-success'
          onClick={() => {
            formik.handleSubmit()
          }}
          disabled={loading}
          data-kt-indicator={loading == true ? 'on' : 'off'}
        >
          <span className='indicator-label'>{intl.formatMessage({id: 'COMMON.SUBMIT'})}</span>

          <span className='indicator-progress'>
            {intl.formatMessage({id: 'COMMON.PLS_WAIT'})}{' '}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        </button>
      </Modal.Footer>
    </Modal>
  )
})
