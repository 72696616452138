import {Outlet, Route, Routes} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Login} from './Login'

const AuthLayout = () => {
  const dataTheme = document.documentElement.getAttribute('data-theme')
  const logoFile = dataTheme == 'dark' ? '/logo-white.svg' : '/logo.svg'
  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
      }}
    >
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        <div className='w-lg-500px bg-body rounded-4 shadow-sm p-10 p-lg-15 mx-auto'>
          <div className='mb-10 text-center'>
            <img alt='Logo' src={logoFile} className='h-35px' />
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
