// @ts-nocheck
import axios from 'axios'
import clsx from 'clsx'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
// @ts-ignore:
import Select from 'react-select'

import {
  FullscreenControl,
  Map,
  Placemark,
  Polygon,
  SearchControl,
  TypeSelector,
  YMaps,
  ZoomControl,
} from '@pbe/react-yandex-maps'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {useNavigate, useSearchParams} from 'react-router-dom'

// COMPONENTS
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import InputMask from 'react-input-mask'
import Swal from 'sweetalert2'

import {KTSVG, selectStyles} from '../../../_metronic/helpers'
import {swalDefaultConfig} from '../../../_metronic/helpers/constants'
import {clearPhoneNumber, convertToPrice, getMediaUrl} from '../../../_metronic/helpers/general'
import {swal} from '../../../_metronic/helpers/swal'
import {PageTitle} from '../../../_metronic/layout/core'
import Loader from '../../components/Loader'
import Timer from '../../components/Timer/Timer'
import {useAuth} from '../../modules/auth/useAuth'
import {ClientAddresses} from './components/Modals/ClientAddresses'
import {ProductModifierModal} from './components/Modals/ProductModifier'
import {StatusOrder} from './components/Modals/StatusOrder'

const phoneNumberRegEx = /^(\+)?998(\s)?\d{2}(\s)?\d{3}(\s)?\d{4}$/

interface Cart {
  productId: string
  amount: number
  modifiers: []
}

export function EditOrder() {
  const intl = useIntl()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const {hasAccess} = useAuth()

  let orderId = searchParams.get('orderId')

  const productModifierModalRef = React.useRef()
  const statusRef = React.useRef()
  const [clientAddressShow, setClientAddressShow] = useState(false)

  // LOCAL STATES
  const [menu, setMenu] = useState([])
  const [cart, setCart] = useState<Cart | any>([])
  const [branches, setBranches] = useState([])
  const [paymentTypes, setPaymentTypes] = useState([])
  const [statuses, setStatuses] = React.useState<any>([])
  const [order, setOrder] = React.useState<any>({})
  const [settings, setSettings] = useState({
    check_by_polygon: '0',
    yandex_key: '',
    suggest_key: '',
  })

  const [total, setTotal] = useState(0)
  const [isPaid, setIsPaid] = useState(false)
  const [isActiveTotal, setIsActiveTotal] = useState(false)
  const [selectedModifiers, setSelectedModifiers] = useState([])
  const [createdOrder, setCreatedOrder] = useState({})

  const [userSearchResult, setUserSearchResult] = useState([])
  const [isShowPhoneDrpdwn, setIsShowPhoneDrpdwn] = useState(false)
  const [isShowNameDrpdwn, setIsShowNameDrpdwn] = useState(false)

  const [productWithModifiers, setProductWithModifiers] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [cartOverView, setCartOverView] = useState([])

  const [selectedGroup, setSelectedGroup] = useState(null)
  let [productWithModifier, setProductWithModifier] = useState(null)

  const [show, setShow] = useState(false)

  const [showInvoice, setShowInvoice] = useState(false)
  const [invoicePhone, setInvoicePhone] = useState('')

  const [searchInput, setSearchInput] = useState('')
  const [searchProductList, setSearchProductList] = useState([])

  const [loyaltyBalance, setLoyaltyBalance] = useState(0)
  const [loyaltyUsed, setLoyaltyUsed] = useState(0)
  const [isLoyaltyUsed, setIsLoyaltyUsed] = useState(false)

  // TIMER
  // const [acceptedTimer, setAcceptedTimer] = useState<any>({})
  let acceptedTimer = ''
  useEffect(() => {
    getPaymentTypes()
    getSettings()
    getMenu()
    getBranches()
    getStatuses()
  }, [])
  useEffect(() => {
    if (!(searchParams.get('phone') || []).length > 0) return

    searchUser('phone', searchParams.get('phone'))
    // formik.setFieldValue("client_phone", searchParams.get("phone"))
  }, [searchParams])

  useEffect(() => {
    getOrderById(orderId)
  }, [orderId])

  // useEffect(() => {
  //   if (!orderId) return
  //   const interval = setInterval(() => {
  //     getOrderById(orderId)
  //   }, 10 * 1000)

  //   //Clearing the interval
  //   return () => clearInterval(interval)
  // }, [orderId])

  const getOrderById = async (orderId = null) => {
    if (!orderId) return
    setCart([])

    try {
      const res = await axiosInstance.get(`/orders/${orderId}`)

      let orderInfo = res.data || {}
      setOrder(orderInfo)
      formik.setFieldValue('id', orderInfo.id ? orderInfo.id : 0)
      formik.setFieldValue('is_self_service', orderInfo.is_self_service ? 1 : 0)
      formik.setFieldValue('payment_type_id', (orderInfo.payment_type || {}).id || '')
      formik.setFieldValue('comment', orderInfo.comment || '')
      formik.setFieldValue('address', orderInfo.commentary || '')
      formik.setFieldValue('entrance', orderInfo.entrance || '')
      formik.setFieldValue('home', orderInfo.home || '')
      formik.setFieldValue('point', orderInfo.point || '')
      formik.setFieldValue('floor', orderInfo.floor || '')
      formik.setFieldValue('couriers', orderInfo.couriers)
      formik.setFieldValue('status', orderInfo.status)
      formik.setFieldValue('organizationId', orderInfo.organizationId)
      formik.setFieldValue('orders_id', orderInfo.orders_id)
      formik.setFieldValue('courier_status', orderInfo.courier_status)

      formik.setFieldValue('claim_id', orderInfo.claim_id || null)
      formik.setFieldValue('yandex_delivery_status', orderInfo.yandex_delivery_status || null)
      formik.setFieldValue('client_id', orderInfo.account_id || null)
      formik.setFieldValue('client_name', orderInfo.name || '')
      formik.setFieldValue('client_phone', orderInfo.phone || '+998')
      formik.setFieldValue('second_phone', orderInfo.second_phone || '+998')
      formik.setFieldValue('is_time', orderInfo.is_time || 1)
      formik.setFieldValue('time', orderInfo.time || null)
      formik.setFieldValue('latitude', orderInfo.latitude || null)
      formik.setFieldValue('longitude', orderInfo.longitude || null)
      formik.setFieldValue('deliveryPrice', orderInfo.delivery_price || 0)
      formik.setFieldValue('terminalId', orderInfo?.terminal?.id || null)
      formik.setFieldValue('organization_id', orderInfo?.terminal?.organization_id || null)
      formik.setFieldValue('district', orderInfo.district || '')
      formik.setFieldValue('apartment', orderInfo.apartment || '')
      formik.setFieldValue('city', orderInfo.city || '')
      formik.setFieldValue('cook_time', orderInfo.cook_time || 0)
      formik.setFieldValue(
        'deliveryDistance',
        orderInfo.distance || orderInfo.distance == 0 ? orderInfo.distance : null
      )
      formik.setFieldValue('total', orderInfo.full_sum)

      setInvoicePhone(orderInfo.phone || '+998')

      setTotal(orderInfo.full_sum)
      setIsActiveTotal(true)
      setIsPaid(orderInfo.is_paid)

      setLoyaltyUsed(orderInfo.loyalty_total || 0)

      if (orderInfo.id) setCreatedOrder(orderInfo)

      for (const item of orderInfo.items || []) {
        let modifiers = []
        modifiers = (item.modifiers || []).map((x) => ({
          // id: item.id,
          id: item.id,
          modifier_id: x.modifier_id,
          product_id: item.item_id,
          price: x.price,
          amount: x.amount,
          name_ru: x.name,
          name_uz: x.name,
          name_en: x.name,
        }))
        addToCart(item.product_id, item.amount, modifiers, item.comment)
      }

      // setIsActiveSend(true)
      setIsLoading(false)
    } catch (err: any) {
      if (err.response.status == 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
      setIsLoading(false)
    }
  }

  const updateOrder = async (e) => {
    if (!formik.values?.id) {
      return
    }

    if (formik.values.is_self_service == 1) {
      if (
        !formik.values.client_phone ||
        !formik.values.payment_type_id ||
        !formik.values.terminalId ||
        !cart.length
      ) {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Пожалуйста заполните все поля!',
          timer: 4000,
          showConfirmButton: false,
        })
        return
      }
    }

    if (formik.values.is_self_service == 0) {
      if (
        !formik.values.client_phone ||
        !formik.values.latitude ||
        !formik.values.longitude ||
        !formik.values.address ||
        !formik.values.terminalId ||
        !formik.values.payment_type_id
        //  || !cart.length
      ) {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Пожалуйста заполните все поля!',
          timer: 4000,
          showConfirmButton: false,
        })

        return
      }
    }

    let items = []
    for (let i = 0; i < cart.length; i++) {
      const item = cart[i]

      const modifiers = []

      for (let j = 0; j < item.modifiers.length; j++) {
        const modifier = item.modifiers[j]

        modifiers.push({
          id: modifier.id,
          amount: modifier.amount,
          modifier_id: modifier.modifier_id,
        })
      }
      items.push({
        item_id: item.id,
        price: item.price,
        amount: item.amount,
        modifiers: modifiers,
        comment: item.comment,
      })
    }

    let normalizedPhone = clearPhoneNumber(formik.values.client_phone)
    let normalizedSecondPhone = clearPhoneNumber(formik.values.second_phone)

    let data = {
      apartment: formik.values.apartment,
      city: formik.values.city,
      comment: formik.values.comment,
      commentary: formik.values.address,
      cook_time: formik.values.cook_time,
      name: formik.values.client_name,
      phone: normalizedPhone,
      second_phone: normalizedSecondPhone,
      district: formik.values.district,
      home: formik.values.home,
      point: formik.values.point,
      is_self_service: parseInt(formik.values.is_self_service) == 1,
      is_time: formik.values.is_time == 1 ? true : false,
      latitude: `${formik.values.latitude}`,
      longitude: `${formik.values.longitude}`,
      payment_type_id: formik.values.payment_type_id,
      status: 0,
      system: 'Web',
      terminal_id: formik.values.terminalId,
      time: formik.values.time,
      items: items,
      region_id: 1,
      language: formik.values.client_language,
      delivery_price: formik.values.deliveryPrice,
      loyalty_total: loyaltyUsed || 0,
      total: total,
    }

    if (!!formik.values.client_id) data.account_id = formik.values.client_id

    let result = await axiosInstance.put(`/orders/${orderId}`, data).catch((error) => {
      setIsLoading(false)
      swal.fire(
        swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
      )
      return
    })

    setIsLoading(false)

    swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_UPDATE'}), 'success'))
    setCart([])
    // navigate(`/orders`)
    window.location.reload(false)
  }

  const createIIko = async () => {
    if (formik.values.is_self_service == 0) {
      if (
        !formik.values.client_phone ||
        !formik.values.latitude ||
        !formik.values.longitude ||
        !formik.values.address ||
        !formik.values.terminalId ||
        !formik.values.payment_type_id
        //  || !cart.length
      ) {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Пожалуйста заполните все поля!',
          timer: 4000,
          showConfirmButton: false,
        })

        return
      }
    }

    try {
      const res = await axiosInstance.post(
        `/iiko/delivery/create`,
        {order_id: Number(orderId)},
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      // console.log(res)
      swal.fire(
        swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_UPDATE'}), 'success')
      )
    } catch (err: any) {
      if (err.response.status == 400) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
      return
    }

    // swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_UPDATE'}), 'success'))
    // navigate('/orders')

    window.location.reload(false)
  }

  const cancelOrder = async () => {
    try {
      const res = await axiosInstance.patch(
        `/orders/cancel/${orderId}`,
        {id: Number(orderId), organizationId: formik.values.organizationId, cancel_reason: ''},
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )

      // swal.fire(
      //   swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_CANCEL'}), 'success')
      // )
    } catch (err: any) {
      // if (err.response.status == 400) {
      //   swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}), 'error'))
      // } else {
      //   swal.fire(
      //     swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
      //   )
      // }
    }

    swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_CANCEL'}), 'success'))
    swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_UPDATE'}), 'success'))
    navigate('/orders')
  }

  const deleveredOrder = async () => {
    try {
      const res = await axiosInstance.post(
        `/orders/delivered/`,
        {id: Number(orderId), courierId: formik.values?.couriers?.id},
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      getOrderById(orderId)
      // swal.fire(
      //   swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_CANCEL'}), 'success')
      // )
    } catch (err: any) {
      // if (err.response.status == 400) {
      //   swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}), 'error'))
      // } else {
      //   swal.fire(
      //     swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
      //   )
      // }
    }

    swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_CANCEL'}), 'success'))
    swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_UPDATE'}), 'success'))
    // navigate('/orders')
  }

  const acceptOrder = async () => {
    try {
      const res = await axiosInstance.post(
        `/orders/accept/`,
        {id: Number(orderId), courierId: formik.values?.couriers?.id},
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      getOrderById(orderId)
      // swal.fire(
      //   swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_CANCEL'}), 'success')
      // )
    } catch (err: any) {
      // if (err.response.status == 400) {
      //   swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}), 'error'))
      // } else {
      //   swal.fire(
      //     swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
      //   )
      // }
    }

    swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_CANCEL'}), 'success'))
    swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_UPDATE'}), 'success'))
    // navigate('/orders')
  }

  const onWAYOrder = async () => {
    try {
      const res = await axiosInstance.post(
        `/orders/take-off/`,
        {id: Number(orderId), courierId: formik.values?.couriers?.id},
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      getOrderById(orderId)
      // swal.fire(
      //   swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_CANCEL'}), 'success')
      // )
    } catch (err: any) {
      // if (err.response.status == 400) {
      //   swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}), 'error'))
      // } else {
      //   swal.fire(
      //     swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
      //   )
      // }
    }

    swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_CANCEL'}), 'success'))
    swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_UPDATE'}), 'success'))
    // navigate('/orders')
  }

  const inPlaceOrder = async () => {
    try {
      const res = await axiosInstance.post(
        `/orders/in-place/`,
        {id: Number(orderId), courierId: formik.values?.couriers?.id},
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      getOrderById(orderId)
      // swal.fire(
      //   swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_CANCEL'}), 'success')
      // )
    } catch (err: any) {
      // if (err.response.status == 400) {
      //   swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}), 'error'))
      // } else {
      //   swal.fire(
      //     swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
      //   )
      // }
    }

    swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_CANCEL'}), 'success'))
    swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_UPDATE'}), 'success'))
    // navigate('/orders')
  }

  const onCallYDCourier = async () => {
    try {
      const res = await axiosInstance.post(`/yandex-delivery/create/${orderId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      swal.fire(swalDefaultConfig('Курьер успешно вызван!', 'success'))
    } catch (err: any) {
      swal.fire(swalDefaultConfig('При вызове курьера произошла ошибка!', 'error'))
    }

    swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_CANCEL'}), 'success'))
    swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_UPDATE'}), 'success'))
    // navigate('/orders')
  }

  async function getStatuses() {
    try {
      const res = await axiosInstance.get('/statuses/')

      setStatuses(res.data.data)
    } catch (err: any) {
      if (err.response.status == 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }

  // HELPER FUNCTIONS
  const sortModifiers = (modifiers = []) => {
    return sortBy(modifiers, [
      function (o) {
        return o.id
      },
    ])
  }

  const getMinutes = (e) => {
    let branch = branches.filter((bran) => bran.id == e.terminalId)
    let t = e.is_self_service == 1 ? branch[0].self_time : branch[0].time
    let time = moment(t, 'HH:mm:ss')
    let hours = time.hours()
    let minutes = time.minutes()
    let newMinutes = hours * 60 + minutes
    let newTime = moment().add(newMinutes, 'minutes').format()
    return newTime
  }

  const filterPassedTime = (time) => {
    const currentDate = !!formik.values.terminalId ? getMinutes(formik.values) : moment().format()
    const selectedDate = moment(time).format()
    return currentDate < selectedDate
  }

  const addToCart = (
    productId: string,
    amount: number,
    modifiers = [],
    comment?: string = '',
    success?: boolean = false
  ) => {
    let cartState = cart.map((x) => x)

    if (success) {
      setIsActiveTotal(true)
    }

    if (!cartState.length) {
      setCart((oldArray) => [
        ...oldArray,
        {
          id: productId,
          amount,
          comment,
          modifiers,
        },
      ])
      return
    }

    modifiers = sortModifiers(modifiers)

    let prIdx = cartState.findIndex((x) => {
      x.modifiers = sortModifiers(x.modifiers)
      return x.id == productId && isEqual(x.modifiers, modifiers)
    })

    if (prIdx >= 0) {
      cartState[prIdx].comment = comment
      cartState[prIdx].amount += amount
      setCart(cartState)

      return
    }

    cartState.push({
      id: productId,
      amount,
      comment,
      modifiers,
    })

    setCart(cartState)
    return
  }
  const reduceCartItem = (idx: number, success?: boolean = false) => {
    let cartState = cart.map((x) => x)

    cartState[idx].amount = cartState[idx].amount - 1

    if (cartState[idx].amount <= 0) cartState.splice(idx, 1)

    if (success) {
      setIsActiveTotal(true)
    }

    setCart(cartState)
    return
  }
  const deleteCartItem = (idx: number, success?: boolean = false) => {
    let cartState = cart.map((x) => x)

    cartState.splice(idx, 1)

    if (success) {
      setIsActiveTotal(true)
    }

    setCart(cartState)
    return
  }
  const setExectAmountOnCart = (
    productId: string,
    amount: number,
    modifiers = [],
    comment?: string = '',
    success?: boolean = false
  ) => {
    let cartState = cart.map((x) => x)

    if (success) {
      setIsActiveTotal(true)
    }

    if (!cartState.length) {
      setCart((oldArray) => [
        ...oldArray,
        {
          id: productId,
          amount,
          comment,
          modifiers,
        },
      ])
      return
    }

    modifiers = sortModifiers(modifiers)

    let prIdx = cartState.findIndex((x) => {
      x.modifiers = sortModifiers(x.modifiers)
      return x.id == productId && isEqual(x.modifiers, modifiers)
    })

    if (prIdx >= 0) {
      cartState[prIdx].comment = comment
      cartState[prIdx].amount = amount
      setCart(cartState)

      return
    }

    cartState.push({
      id: productId,
      amount,
      comment,
      modifiers,
    })

    setCart(cartState)
    return
  }
  const buildCartOverview = (cart = []) => {
    let productsData = flatten(menu.map((x) => x.products))
    let products = cart.map((x, idx) => {
      let productInfo = productsData.find((y) => (y || {}).id == x.id)

      if (!productInfo) return
      let modPrice = x.modifiers.reduce((p, c) => {
        // return p + c.amount * c.price
        return p + c.price
      }, 0)
      let productPrice = parseFloat(productInfo.price) + modPrice
      return {
        id: productInfo.id,
        name_ru: productInfo.name_ru,
        name_uz: productInfo.name_uz,
        name_en: productInfo.name_en,
        comment: x.comment,
        price: productInfo.price,
        // sum: productInfo.price * x.amount,
        // price: productPrice,
        sum: productPrice * x.amount,
        amount: x.amount,
        cartIdx: idx,
        modifiers: x.modifiers,
      }
    })

    products = compact(products)
    let newTotal = getCartPrice(products)
    return {
      products,
      total: newTotal,
    }
  }
  const getCartPrice = (cart = []) => {
    let prodTotal = cart.reduce((total, product) => {
      // let modSum = product.modifiers.reduce((p, c) => {
      //   return p + product.amount * c.amount * c.price
      // }, 0)
      let modSum = 0
      return total + product.sum + modSum
    }, 0)
    return prodTotal
  }
  const getItemTotalPrice = (item, isHumanReadable = true) => {
    let price = getItemPrice(item, false)

    if (!isHumanReadable) return price * item.q

    return convertToPrice(parseInt(price * item.q, 10))
  }
  const getItemsTotalPrice = (items, delivery_price = 0, isHumanReadable = true) => {
    if (!items) return
    let totalPrice = items.reduce((acc, curr) => {
      let price = getItemTotalPrice(curr, false)

      return acc + price
    }, 0)

    totalPrice = totalPrice + delivery_price

    if (!isHumanReadable) return totalPrice

    return convertToPrice(parseInt(totalPrice, 10))
  }
  async function getPaymentTypes() {
    try {
      const res = await axiosInstance.get('/payment-types/')
      setPaymentTypes(res.data.data.filter((x) => x.is_active == true))
    } catch (err: any) {
      if (err.response.status == 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }
  async function getSettings() {
    try {
      const res = await axiosInstance.get('/orders/settings/')
      setSettings({
        check_by_polygon: res.data.check_by_polygon || '0',
        yandex_key: res.data.yandex_key || '',
        suggest_key: res.data.suggest_key || '',
      })
    } catch (err: any) {
      if ((err.response || {}).status == 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }
  async function getMenu() {
    try {
      const res = await axiosInstance.get(
        `/groups/products/?organization_id=${formik.values.organization_id}&terminal_id=${formik.values.terminalId}`
      )
      setMenu(res.data.data || [])
      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      if (err.response.status == 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }

  // TEMPLATE BASED FUNCTIONS

  const getSelectContent = (
    arr: any,
    keyName = '',
    valueKey = '',
    valueName: string | null = null,
    isProper = true
  ) => {
    if (!arr || !arr.length) return []

    let content = []

    for (const [i, value] of arr.entries()) {
      content.push(
        <label
          key={keyName + i}
          className={clsx('btn btn-outline btn-color-muted p-2', {
            active: formik.values.is_self_service == value.id,
            [value.class]: true,
          })}
          data-kt-button='true'
        >
          <input
            className='btn-check'
            type='radio'
            name='RadioStatus'
            value={value.id}
            checked={formik.values.is_self_service == value.id}
            onChange={(e) =>
              formik.setFieldValue('is_self_service', JSON.parse(e.currentTarget.value))
            }
          />
          {valueName && value[valueName]}
          {!valueName && isProper && value.nameRu}
          {!valueName && !isProper && value.name}
        </label>
      )
    }

    return content
  }
  const getProductsContent = (products = []) => {
    let content = []
    for (let product of products) {
      content.push(
        <div
          // @ts-ignore:
          key={'product-' + product.id}
          className='border-bottom'
        >
          <button
            className='btn py-2 px-0 text-start w-100 d-flex justify-content-between'
            onClick={async () => {
              let stopListResult = await axiosInstance.get(
                `/products/stop-list/?productId=${product.id}&terminalId=${formik.values.terminalId}`
              )

              if (stopListResult.data == true) {
                swal.fire(
                  swalDefaultConfig(
                    intl.formatMessage({id: 'NOTIFICATION.PRODUCT_IN_STOPLIST'}),
                    'error'
                  )
                )
                return
              }

              if (product.group_modifiers.length > 0) {
                setShow(true)
                setProductWithModifier(product)
                productModifierModalRef.current.showModal()
              } else {
                addToCart(product.id, 1, [], product.comment, true)
              }
            }}
          >
            {/* @ts-ignore: */}
            {product.name_ru}
            <span className='me-1'>{product.amount}</span>
          </button>
        </div>
      )
    }

    return content
  }
  const getMenuContent = (parent_id = '0') => {
    let content = []
    // for (let group of menu) {
    for (let group of menu.filter((x) => x.parent_id == parent_id)) {
      content.push(
        // @ts-ignore:
        <div key={'group-' + group.id} className='border-bottom'>
          <button
            className='btn py-2 px-0 text-start w-100'
            onClick={() => setSelectedGroup(group)}
          >
            {/* @ts-ignore: */}
            {group.name_ru}
          </button>
        </div>
      )
    }
    return content
  }
  const getCartContent = (cartV) => {
    let content = []
    let overview = buildCartOverview(cartV)

    let i = 1
    for (let item of overview.products) {
      content.push(
        <tr key={'cartitem-' + i}>
          <td>{i}</td>
          <td>{item.name_ru}</td>
          <td>
            {item?.modifiers.map(
              (x) =>
                `${x.name_ru} - ${convertToPrice(x.price)} ${intl.formatMessage({
                  id: 'COMMON.SUM',
                })}`
            )}
          </td>
          <td>
            <div className='btn btn-outline btn-outline-danger px-3 py-1'>
              <button
                className='btn btn-outline btn-outline-primary btn-outline-dashed btn-sm px-3'
                onClick={() => reduceCartItem(item.cartIdx, true)}
              >
                -
              </button>
              <input
                type='number'
                className={clsx('form-control text-center item-quantity')}
                onChange={(e) =>
                  setExectAmountOnCart(
                    item.id,
                    parseInt(e.target.value, 10),
                    item?.modifiers,
                    item?.comment,
                    true
                  )
                }
                value={item.amount}
              />
              <button
                className='btn btn-outline btn-outline-success px-3 py-1'
                onClick={() => addToCart(item.id, 1, item?.modifiers, item?.comment, true)}
              >
                +
              </button>
            </div>
          </td>
          <td>
            <input
              type='text'
              className={clsx('form-control')}
              onChange={(e) => addToCart(item.id, 0, item?.modifiers, e.target.value, true)}
              value={item.comment}
            />
          </td>
          <td>{`${convertToPrice(item.price)} ${intl.formatMessage({id: 'COMMON.SUM'})}`}</td>
          <td>{`${convertToPrice(item.sum)} ${intl.formatMessage({id: 'COMMON.SUM'})}`}</td>
          <td>
            <div>
              <button
                className='btn btn-outline btn-outline-danger btn-outline-dashed px-3'
                onClick={() => deleteCartItem(item.cartIdx, true)}
              >
                x
              </button>
            </div>
          </td>
        </tr>
      )
      i++
    }
    return content
  }
  const searchUser = async (key: string, value: string) => {
    if (value.length < 3) {
      return setUserSearchResult([])
    }

    if (key == 'phone') value = clearPhoneNumber(value)
    try {
      // const res = await axiosInstance.get(`accounts/phone?${key}=${value}`)
      const res = await axiosInstance.get(`accounts/phone/${value}`)

      // TODO: backend peredelat' na massiv
      setUserSearchResult(res.data)
      if (res.data.length == 1 && phoneNumberRegEx.test(value || '')) {
        formik.setFieldValue('client_id', res.data[0].id)
        formik.setFieldValue('client_name', res.data[0].name)
        formik.setFieldValue('client_language', res.data[0].language)
        formik.setFieldValue('client_phone', res.data[0].phone)
        setInvoicePhone(res.data[0].phone)
      }
    } catch (err: any) {
      if (err.response.status == 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }
  const userSearchDroprown = (isShow: boolean = false) => {
    const getContent = () => {
      if (!userSearchResult)
        return (
          <div className='d-flex justify-content-center'>
            <span>{intl.formatMessage({setIsShowDrpdwnid: 'VALIDATION.ENTER_SEARCH_VALUE'})}</span>
          </div>
        )
      if (!userSearchResult.length)
        return (
          <div className='d-flex justify-content-center'>
            <span>{intl.formatMessage({id: 'VALIDATION.NO_DATA_FOUNDED'})}</span>
          </div>
        )

      return userSearchResult.map((user) => {
        return (
          <button
            // @ts-ignore:
            key={'userSearchList-' + user.id}
            className='dropdown-item'
            onClick={(e) => {
              e.preventDefault()
              onUserSearchSelect(user)
              setIsShowPhoneDrpdwn(false)
            }}
          >
            {/* @ts-ignore: */}
            {user.name || ''} / {user.phone || ''}
          </button>
        )
      })
    }

    return (
      <div className='dropdown'>
        <div
          aria-labelledby=''
          className={clsx('w-100 dropdown-menu es-client-autocomplete', {show: isShow == true})}
          style={{
            position: 'absolute',
            inset: '0px auto auto 0px',
            transform: 'translate3d(0px, 10px, 0px)',
          }}
        >
          {getContent()}
        </div>
      </div>
    )
  }

  const searchProductInput = (query) => {
    setSearchInput(query)
    setSearchProductList([])

    let products = flatten(menu.map((x) => x.products))
    const results = products.filter(
      (prod) =>
        ((prod || {}).name_ru || '').toLowerCase().includes(query.toLowerCase()) ||
        ((prod || {}).name_uz || '').toLowerCase().includes(query.toLowerCase()) ||
        ((prod || {}).name_en || '').toLowerCase().includes(query.toLowerCase())
    )
    setSearchProductList((prev) => [...prev, ...results])
  }

  const onUserSearchSelect = (user: any) => {
    let phone = user.phone
    if (!phone.startsWith('+')) phone = '+' + phone

    formik.values.client_id = user.id || null
    formik.values.client_phone = phone || '+998'
    formik.setFieldValue('second_phone', user.second_phone || '+998')
    formik.values.client_name = user.name || ''
    formik.values.client_birthday = user.birthday || ''
    formik.values.client_language = user.language || 'ru'
  }
  const setIsShowDrpdwn = (key: string = '') => {
    setIsShowPhoneDrpdwn(false)
    setIsShowNameDrpdwn(false)

    if (key == 'phone') setIsShowPhoneDrpdwn(true)
    if (key == 'name') setIsShowNameDrpdwn(true)
  }

  const validationSchema = Yup.object().shape({
    client_phone: Yup.string()
      .matches(phoneNumberRegEx, intl.formatMessage({id: 'VALIDATION.PHONE'}))
      .min(12, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 12}))
      .max(12, intl.formatMessage({id: 'VALIDATION.MAX_SYMBOL'}, {number: 12}))
      .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
    client_name: Yup.string().min(
      1,
      intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})
    ),
    payment_type_id: Yup.string()
      .min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1}))
      .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
    comment: Yup.string().min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})),
    commentary: Yup.string().min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})),
    entrance: Yup.string(),
    home: Yup.string(),
    point: Yup.string(),
    floor: Yup.string(),
    is_self_service: Yup.number().integer(),
    is_time: Yup.number().integer(),
    time: Yup.date(),
  })

  const initialValues = {
    is_self_service: 0,
    payment_type_id: '',
    comment: '',
    address: '',
    entrance: '',
    home: '',
    point: '',
    floor: '',
    claim_id: null,
    yandex_delivery_status: null,
    client_id: null,
    client_name: '',
    client_phone: '+998',
    client_birthday: '',
    client_language: 'ru',
    client_addresses: [],
    is_time: 1,
    time: null,
    coords: [],
    latitude: null,
    longitude: null,
    deliveryPrice: 0,
    terminalId: null,
    district: '',
    cook_time: 0,
    apartment: '',
    city: '',
    deliveryDistance: null,
    status: 0,
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {},
  })

  function ProductView({setShowProduct, showProduct, product}) {
    const onModSelect = (modifier) => {
      let currentModifiersGroups = selectedModifiers.filter((x) => x.group_id == modifier.group_id)
      let otherModifiers = selectedModifiers.filter((x) => x.group_id != modifier.group_id)
      let isModAdded = currentModifiersGroups.find((x) => x.modifier_id == modifier.modifier_id)
      if (!!isModAdded) {
        currentModifiersGroups = currentModifiersGroups.filter(
          (x) => x.modifier_id != modifier.modifier_id
        )
        setSelectedModifiers([...otherModifiers, ...currentModifiersGroups])

        return
      }

      currentModifiersGroups.push(modifier)
      let currentModifiers = currentModifiersGroups.filter((x) => x.group_id == modifier.group_id)
      currentModifiers = currentModifiers.slice(-modifier.max_amount || 1)

      currentModifiersGroups = currentModifiersGroups.filter(
        (x) => x.modifier_id != modifier.modifier_id
      )
      currentModifiersGroups.push(...currentModifiers)

      // TODO: group max amount
      currentModifiersGroups = currentModifiersGroups.slice(-modifier.max_amount)

      setSelectedModifiers([...otherModifiers, ...currentModifiersGroups])
      return
    }
    return (
      <>
        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{product.name_ru}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='row'>
              <div className='col-6'>
                <div className='order-pr-view-img'>
                  <img className='img-fluid' src={getMediaUrl('products', product.image)} alt='' />
                </div>
              </div>
              <div className='col-6'>
                {product.group_modifiers.map((y) => {
                  return (
                    <div key={'modifier-group' + y.group_id}>
                      <h2>{y.name_ru}</h2>
                      <div>
                        {(y.child || []).map((x) => {
                          return (
                            <div className='form-group' key={'mod-child' + x.modifier_id}>
                              <input
                                id={`pr-modifier-id-${x.modifier_id}`}
                                name={`mName-${y.group_id}`}
                                type='checkbox'
                                value={`mValue-${x.modifier_id}`}
                                checked={
                                  !!selectedModifiers.find(
                                    (sMod) => sMod.modifier_id == x.modifier_id
                                  )
                                }
                                onChange={() => onModSelect(x)}
                              />
                              <label htmlFor={`pr-modifier-id-${x.modifier_id}`}>{x.name_ru}</label>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={() => setShow(false)}>
              Close
            </Button>
            <Button variant='primary' onClick={() => setShow(false)}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }

  const getAdresses = async () => {
    if (!formik.values.client_id) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: intl.formatMessage({id: 'COMMON.CLIENT_NOT_FOUND'}),
        timer: 1000,
        showConfirmButton: false,
      })
      return
    }
    const addresses = await axios
      .get(`/orders/addresses/${formik.values.client_id || ''}`)
      .catch((error) => {
        if (err.response.status == 404) {
          swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
        } else {
          swal.fire(
            swalDefaultConfig(
              intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}),
              'error'
            )
          )
        }
      })
    if (addresses.status == 200) {
      formik.setFieldValue('client_addresses', (addresses || {}).data || [])
      setClientAddressShow(true)
      return
    }
  }

  const mapOnClick = async (e) => {
    if (isLoading) return
    if (!e && !formik.values.coords.length) return
    let coords
    if (!e) coords = formik.values.coords
    else coords = await e.get('coords')
    formik.setFieldValue('coords', coords)
    formik.setFieldValue('latitude', coords[0])
    formik.setFieldValue('longitude', coords[1])
    formik.setFieldValue('address', '')
    formik.setFieldValue('terminalId', '')

    getAddressInfo(coords[0], coords[1], '')
  }

  if (isShowPhoneDrpdwn) {
    document.addEventListener('click', (event) => {
      setIsShowPhoneDrpdwn(false)
    })
  }

  const getBranches = async () => {
    const result = await axiosInstance.get('/terminals/all/').catch((error) => console.log(error))
    setBranches(result.data.data)
  }

  useEffect(() => {
    // if (isActiveTotal) {
    let overview = buildCartOverview(cart)
    setCartOverView(overview.products)
    setTotal(overview.total)
    formik.setFieldValue('total', overview.total)
    // }
    // }, [cart.length])
  }, [cart, menu])

  useEffect(() => {
    getMenu()
  }, [formik.values.organization_id])

  // useEffect(() => {
  //   if (!formik.values.latitude || !formik.values.longitude) return

  //   const data = {
  //     total: total || 0,
  //     latitude: formik.values.latitude,
  //     longitude: formik.values.longitude,
  //     terminal_id: formik.values.terminalId || null,
  //     // terminal_id: null,
  //   }

  //   ;(async () => {
  //     const info = await axios
  //       .get(
  //         '/orders/address/',
  //         {params: data}
  //         // total: getItemsTotalPrice(buildCartOverview(cartOverView), 0, false),
  //       )
  //       .catch((error) => {
  //         let m = (((error || {}).response || {}).data || {}).message
  //         if (!m) m = (error || {}).message || ''
  //         swal.fire(swalDefaultConfig(m, 'error'))
  //         return
  //       })

  //     formik.setFieldValue('deliveryDistance', info.data.deliveryDistance)
  //     formik.setFieldValue('address', info.data.address)
  //     formik.setFieldValue('deliveryPrice', info.data.deliveryPrice)
  //     formik.setFieldValue('terminalId', info.data.terminalId)
  //     formik.setFieldValue('organization_id', info.data.organizationId)
  //   })()
  // }, [formik.values.latitude, formik.values.longitude, formik.values.terminalId])

  useEffect(() => {
    let paymentType = paymentTypes.find((x) => x.id == formik.values.payment_type_id)
    if (!!paymentType && paymentType.is_online == true) {
      setShowInvoice(true)
    } else {
      setShowInvoice(false)
    }
  }, [formik.values.payment_type_id, paymentTypes])

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
      {
        title: intl.formatMessage({id: 'MENU.ORDERS'}),
        path: '/orders',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }
  const getAddressInfo = async (latitude = null, longitude = null, terminalId) => {
    if ((!latitude && !formik.values.latitude) || (!latitude && !formik.values.longitude)) return

    const data = {
      total: total || 0,
      latitude: latitude || formik.values.latitude,
      longitude: longitude || formik.values.longitude,
      terminal_id: terminalId || null,
      // terminal_id: null,
    }

    const info = await axios
      .get(
        '/orders/address/',
        {params: data}
        // total: getItemsTotalPrice(buildCartOverview(cartOverView), 0, false),
      )
      .catch((error) => {
        let m = (((error || {}).response || {}).data || {}).message
        if (!m) m = (error || {}).message || ''
        swal.fire(swalDefaultConfig(m, 'error'))
        return
      })

    formik.setFieldValue('deliveryDistance', info.data.deliveryDistance)
    formik.setFieldValue('address', info.data.address)
    formik.setFieldValue('deliveryPrice', info.data.deliveryPrice)
    formik.setFieldValue('terminalId', info.data.terminalId)
    formik.setFieldValue('organization_id', info.data.organizationId)
  }

  const getAccountBalance = async () => {
    if (!formik.values.client_id) {
      return
    }

    const balance = await axios
      .get(`/accounts/balance/${formik.values.client_id || ''}`)
      .catch((error) => {
        // if (err.response.status == 404) {
        //   swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
        // } else {
        //   swal.fire(
        //     swalDefaultConfig(
        //       intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}),
        //       'error'
        //     )
        //   )
        // }
      })
    if (balance.status == 200) {
      // formik.setFieldValue('client_addresses', (addresses || {}).data || [])
      // setClientAddressShow(true)
      setLoyaltyBalance(balance?.data?.balance || 0)
      // setLoyaltyUsed(0)
      setIsLoyaltyUsed(true)
      return
    }
  }

  useEffect(() => {
    getAccountBalance()
  }, [formik.values.client_id])

  if (isLoading) return <Loader />

  const getOrderStatusLog = (status: string) => {
    let ordersLog = (order.order_logs || []).map((x) => x)
    let filteredOrdersLog = ordersLog.filter((orderLog) => orderLog.event == status)
    return filteredOrdersLog[filteredOrdersLog.length - 1]
  }

  function calculateTimeDifference(start: string, end: string) {
    let filterStatusLogStart = (order.order_logs || []).filter(
      (orderLog) => orderLog.event == start
    )
    let filterStatusLogEnd = (order.order_logs || []).filter((orderLog) => orderLog.event == end)

    const startTime = new Date(
      filterStatusLogStart[filterStatusLogStart.length - 1]?.date
    ).getTime()
    const endTime = new Date(filterStatusLogEnd[filterStatusLogEnd.length - 1]?.date).getTime()
    const difference = endTime - startTime

    if (difference < 0) {
      return <>00:00:00</>
    }
    // Convert difference to minutes and seconds
    const seconds =
      Math.floor((difference / 1000) % 60) > 9
        ? Math.floor((difference / 1000) % 60)
        : '0' + Math.floor((difference / 1000) % 60)
    const minutes =
      Math.floor((difference / (1000 * 60)) % 60) > 9
        ? Math.floor((difference / (1000 * 60)) % 60)
        : '0' + Math.floor((difference / (1000 * 60)) % 60)
    const hours =
      Math.floor((difference / (1000 * 60 * 60)) % 24) > 9
        ? Math.floor((difference / (1000 * 60 * 60)) % 24)
        : '0' + Math.floor((difference / (1000 * 60 * 60)) % 24)
    const days = Math.floor(difference / (1000 * 60 * 60 * 24))

    return (
      <>
        {hours}:{minutes}:{seconds}
      </>
    )
  }

  function getColorStatusLog(start: string, end: string) {
    let filterStatusLogStart = order.order_logs.filter((orderLog) => orderLog.event == start)
    let filterStatusLogEnd = order.order_logs.filter((orderLog) => orderLog.event == end)

    const startTime = new Date(
      filterStatusLogStart[filterStatusLogStart.length - 1]?.date
    ).getTime()
    const endTime = new Date(filterStatusLogEnd[filterStatusLogEnd.length - 1]?.date).getTime()
    const difference = endTime - startTime

    // Convert difference to minutes and seconds

    const hours =
      Math.floor((difference / (1000 * 60 * 60)) % 24) > 9
        ? Math.floor((difference / (1000 * 60 * 60)) % 24)
        : '0' + Math.floor((difference / (1000 * 60 * 60)) % 24)

    if (hours > 0) return 'btn-active-light-danger'
    if ((hours * 60) / 2 > 1) return 'btn-active-light-warning'
    return 'btn-active-light-success'
  }

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>
        {`${intl.formatMessage({id: 'COMMON.ORDER'})} #${orderId}`}
      </PageTitle>
      <div className='row mb-2'>
        <div className='col-md-6'>
          <div className='row'>
            <div className='col-md-6 pe-md-1 mb-2'>
              <div className='card card-stretch'>
                <div className='card-header'>
                  <h2 className='card-title'>{intl.formatMessage({id: 'COMMON.CLIENT'})}</h2>
                </div>
                <div className='card-body'>
                  <div className='mb-3'>
                    <label className='form-label'>{intl.formatMessage({id: 'COMMON.NAME2'})}</label>
                    <input
                      type='text'
                      {...formik.getFieldProps('client_name')}
                      className={clsx(
                        'form-control',
                        {'is-invalid': formik.touched.client_name && formik.errors.client_name},
                        {
                          'is-valid': formik.touched.client_name && !formik.errors.client_name,
                        }
                      )}
                      value={formik.values.client_name}
                    />
                  </div>
                  <div className='mb-3'>
                    <label className='form-label'>{intl.formatMessage({id: 'COMMON.PHONE'})}</label>
                    <InputMask
                      className={clsx(
                        'form-control',
                        {'is-invalid': formik.touched.client_phone && formik.errors.client_phone},
                        {
                          'is-valid': formik.touched.client_phone && !formik.errors.client_phone,
                        }
                      )}
                      mask='+999 (99) 999-99-99'
                      // @ts-ignore:
                      onChange={(e) => (
                        formik.setFieldValue('client_phone', e.currentTarget.value),
                        searchUser('phone', e.currentTarget.value),
                        setIsShowDrpdwn('phone')
                      )}
                      value={formik.values.client_phone}
                    />
                    {userSearchDroprown(isShowPhoneDrpdwn)}
                  </div>
                  <div className='mb-3'>
                    <label className='form-label'>
                      {intl.formatMessage({id: 'COMMON.SECOND_PHONE'})}
                    </label>
                    <InputMask
                      className={clsx(
                        'form-control',
                        {'is-invalid': formik.touched.second_phone && formik.errors.second_phone},
                        {
                          'is-valid': formik.touched.second_phone && !formik.errors.second_phone,
                        }
                      )}
                      mask='+999 (99) 999-99-99'
                      // @ts-ignore:
                      onChange={(e) => {
                        formik.setFieldValue('second_phone', e.currentTarget.value)
                        // searchUser('phone', e.currentTarget.value)
                      }}
                      value={formik.values.second_phone}
                    />
                  </div>
                  {/* <div className='mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'COMMON.LANG'})}
                      </label>

                      <div className='form-check form-check-custom form-check-solid form-check-sm'>
                        <input
                          id='ruLangRadio'
                          className={clsx(
                            'form-check-input',
                            {
                              'is-invalid':
                                formik.touched.client_language && formik.errors.client_language,
                            },
                            {
                              'is-valid':
                                formik.touched.client_language && !formik.errors.client_language,
                            }
                          )}
                          type='radio'
                          value={'ru'}
                          name='userLangRadio'
                          onChange={(e) =>
                            formik.setFieldValue('client_language', e.currentTarget.value)
                          }
                          checked={formik.values.client_language == 'ru'}
                        />
                        <label className='form-check-label me-3' htmlFor='ruLangRadio'>
                          {intl.formatMessage({id: 'COMMON.LANG.RU'})}
                        </label>
                        <input
                          id='uzLangRadio'
                          className={clsx(
                            'form-check-input',
                            {
                              'is-invalid':
                                formik.touched.client_language && formik.errors.client_language,
                            },
                            {
                              'is-valid':
                                formik.touched.client_language && !formik.errors.client_language,
                            }
                          )}
                          type='radio'
                          value={'uz'}
                          name='userLangRadio'
                          onChange={(e) =>
                            formik.setFieldValue('client_language', e.currentTarget.value)
                          }
                          checked={formik.values.client_language == 'uz'}
                        />
                        <label className='form-check-label me-3' htmlFor='uzLangRadio'>
                          {intl.formatMessage({id: 'COMMON.LANG.UZ'})}
                        </label>
                        <input
                          id='enLangRadio'
                          className={clsx(
                            'form-check-input',
                            {
                              'is-invalid':
                                formik.touched.client_language && formik.errors.client_language,
                            },
                            {
                              'is-valid':
                                formik.touched.client_language && !formik.errors.client_language,
                            }
                          )}
                          type='radio'
                          value={'en'}
                          name='userLangRadio'
                          onChange={(e) =>
                            formik.setFieldValue('client_language', e.currentTarget.value)
                          }
                          checked={formik.values.client_language == 'en'}
                        />
                        <label className='form-check-label' htmlFor='enLangRadio'>
                          {intl.formatMessage({id: 'COMMON.LANG.EN'})}
                        </label>
                      </div>
                    </div> */}
                </div>
              </div>
            </div>
            <div className='col-md-6 px-md-1 mb-2'>
              <div className='card card-stretch'>
                <div className='card-header'>
                  <h2 className='card-title'>{intl.formatMessage({id: 'MENU.ORDER_TYPE'})}</h2>
                </div>
                <div className='card-body'>
                  <div className='mb-3'>
                    <label className='form-label'>
                      {intl.formatMessage({id: 'COMMON.ORDER_TYPE'})}
                    </label>
                    <div className='form-check form-check-custom form-check-solid'>
                      <div
                        className='btn-group w-100'
                        data-kt-buttons='true'
                        data-kt-buttons-target='[data-kt-button]'
                      >
                        {getSelectContent(
                          [
                            {
                              id: 0,
                              boolean: true,
                              nameRu: 'Доставка',
                              nameUz: 'Доставка',
                              nameEn: 'Доставка',
                              class: 'btn-active-light-success',
                            },
                            {
                              id: 1,
                              boolean: false,
                              nameRu: 'Самовывоз',
                              nameUz: 'Самовывоз',
                              nameEn: 'Самовывоз',
                              class: 'btn-active-light-danger',
                            },
                          ],
                          'patientStatus-'
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='mb-3'>
                    <label className='form-label'>
                      {intl.formatMessage({id: 'COMMON.BRANCH'})}
                    </label>
                    <Select
                      classNamePrefix='react-select'
                      placeholder=''
                      components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                      className='react-select-styled react-select-solid'
                      options={branches?.map((x: any) => ({
                        value: x.id,
                        label: x.name_ru,
                        organization_id: x.organization_id,
                      }))}
                      onChange={(e: any) => {
                        formik.setFieldValue('terminalId', e.value)
                        formik.setFieldValue('organization_id', e.organization_id)

                        getAddressInfo(null, null, e.value)
                      }}
                      value={{
                        value:
                          (branches.find((x: any) => x.id == formik.values.terminalId) || {}).id ||
                          null,
                        label:
                          (branches.find((x: any) => x.id == formik.values.terminalId) || {})
                            .name_ru || '',
                      }}
                      styles={selectStyles}
                    />
                  </div>

                  <div
                    className={clsx('mb-3', {
                      hidden: formik.values.is_time == 0,
                    })}
                  >
                    <label className='form-label'>
                      {intl.formatMessage({id: 'COMMON.RECEIVE_DATE'})}
                    </label>

                    <DatePicker
                      selected={(formik.values.time && new Date(formik.values.time)) || null}
                      onChange={(val: any) => {
                        formik.setFieldValue('time', val)
                      }}
                      dateFormat='dd.MM.yyyy HH:mm'
                      timeFormat='HH:mm'
                      timeIntervals={15}
                      minDate={new Date()}
                      filterTime={filterPassedTime}
                      showTimeSelect
                      className={clsx(
                        'form-control',
                        {'is-invalid': formik.touched.time && formik.errors.time},
                        {
                          'is-valid': formik.touched.time && !formik.errors.time,
                        }
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6 pe-md-1'>
              <div className='card card-stretch'>
                <div className='card-header'>
                  <h2 className='card-title'>{intl.formatMessage({id: 'COMMON.DETAILS'})}</h2>
                  <div className='card-toolbar'>
                    <button type='button' className='btn btn-sm btn-warning'>
                      {intl.formatMessage({id: 'COMMON.DISCOUNT'})}
                    </button>
                  </div>
                </div>

                <div className='card-body'>
                  <div className='mb-3'>
                    <label className='form-label'>
                      {intl.formatMessage({id: 'COMMON.PAYMENT_TYPE'})}
                    </label>

                    <div className='form-check form-check-custom form-check-solid form-check-sm flex-wrap'>
                      {paymentTypes.map((pType, idx) => {
                        return (
                          <div key={'payment-idx-' + idx} className='mb-3'>
                            <input
                              id={'pTypeRadio-' + pType.code}
                              className={clsx(
                                'form-check-input',
                                {
                                  'is-invalid':
                                    formik.touched.payment_type_id && formik.errors.payment_type_id,
                                },
                                {
                                  'is-valid':
                                    formik.touched.payment_type_id &&
                                    !formik.errors.payment_type_id,
                                }
                              )}
                              type='radio'
                              value={pType.id}
                              name='pTypeRadio'
                              onChange={(e) =>
                                formik.setFieldValue('payment_type_id', e.currentTarget.value)
                              }
                              checked={formik.values.payment_type_id == pType.id}
                            />
                            <label
                              className='form-check-label me-5'
                              htmlFor={'pTypeRadio-' + pType.code}
                            >
                              {pType.name_ru}
                            </label>
                          </div>
                        )
                      })}
                      {/* <input
                      type='text'
                      {...formik.getFieldProps('client_name')}
                      className={clsx(
                        'form-control mt-3',
                        {'is-invalid': formik.touched.client_name && formik.errors.client_name},
                        {
                          'is-valid': formik.touched.client_name && !formik.errors.client_name,
                        }
                      )}
                      disabled
                      value={'21313123213'}
                    /> */}
                    </div>
                  </div>
                  <table className='table table-row-dashed gy-2 gs-1 mb-3'>
                    <tbody>
                      {isLoyaltyUsed && (
                        <>
                          <tr>
                            <th>Сумма накопительного</th>
                            <td className='text-end'>
                              {convertToPrice(loyaltyBalance)}{' '}
                              {intl.formatMessage({id: 'COMMON.SUM'})}
                            </td>
                          </tr>

                          <tr>
                            <th>Использовать из накопительного</th>
                            <td className='text-end'>
                              <input
                                type='number'
                                className='form-control text-end'
                                value={loyaltyUsed}
                                onChange={(e) => {
                                  let n = parseInt(e.target.value, 10)
                                  if (n >= loyaltyBalance) {
                                    setLoyaltyUsed(loyaltyBalance)
                                  } else if (n > total) {
                                    setLoyaltyUsed(total)
                                  } else {
                                    setLoyaltyUsed(n)
                                  }
                                }}
                              />
                            </td>
                          </tr>
                        </>
                      )}

                      <tr>
                        <th>{intl.formatMessage({id: 'COMMON.ORDER_SUM'})}</th>
                        <td className='text-end'>
                          {convertToPrice(total)} {intl.formatMessage({id: 'COMMON.SUM'})}
                        </td>
                      </tr>
                      {formik.values.is_self_service == 0 && (
                        <tr>
                          <th>{intl.formatMessage({id: 'COMMON.DELIVERY_PRICE_SUM'})}</th>
                          <td className='text-end'>
                            {convertToPrice(formik.values.deliveryPrice)}{' '}
                            {intl.formatMessage({id: 'COMMON.SUM'})}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <th>{intl.formatMessage({id: 'COMMON.DISCOUNT'})}</th>
                        <td className='text-end'>-</td>
                      </tr>
                      <tr>
                        <th>{intl.formatMessage({id: 'COMMON.TOTAL_FOR_PAYMENT'})}</th>
                        <td className='text-end'>
                          {convertToPrice(formik.values.total + (formik.values.deliveryPrice || 0))}{' '}
                          {intl.formatMessage({id: 'COMMON.SUM'})}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div className='mb-3'>
                    <label className='form-label'>
                      {intl.formatMessage({id: 'COMMON.COMMENT'})}
                    </label>
                    <textarea
                      {...formik.getFieldProps('comment')}
                      className={clsx(
                        'form-control',
                        {'is-invalid': formik.touched.comment && formik.errors.comment},
                        {
                          'is-valid': formik.touched.comment && !formik.errors.comment,
                        }
                      )}
                      value={formik.values.comment}
                      rows={2}
                    ></textarea>
                  </div>

                  {/* {!createdOrder.id && cart.length > 0 && (
                    <button
                      className='btn btn-success btn-lg w-100 mt-3 h-auto'
                      onClick={createOrder}
                    >
                      {intl.formatMessage({id: 'COMMON.CHECKOUT'})}
                    </button>
                  )} */}

                  {!order.claim_id && (
                    <button className='btn btn-warning w-100 mt-5' onClick={onCallYDCourier}>
                      Вызвать курьера YD
                    </button>
                  )}

                  {formik.values.status < 3 && (
                    <button className='btn btn-primary w-100 mt-5' onClick={updateOrder}>
                      {intl.formatMessage({id: 'COMMON.CHANGE'})}
                    </button>
                  )}
                  {formik.values.status > 0
                    ? ''
                    : hasAccess('order-confirm-iiko') && (
                        <button className='btn btn-success w-100 mt-5' onClick={createIIko}>
                          {intl.formatMessage({id: 'COMMON.SEND'})} IIKO
                        </button>
                      )}
                  {hasAccess('order-cancel') && formik.values.status < 9 && (
                    <button className='btn btn-danger w-100 mt-5' onClick={cancelOrder}>
                      {intl.formatMessage({id: 'COMMON.CANCEL'})}
                    </button>
                  )}
                  {!!formik.values.couriers?.id && (
                    <div>
                      {hasAccess('order-cancel') && formik.values.courier_status == 3 && (
                        <button className='btn btn-secondary w-100 mt-5' onClick={deleveredOrder}>
                          {intl.formatMessage({id: 'COMMON.DELIVERED'})}
                        </button>
                      )}

                      {hasAccess('order-cancel') && formik.values.courier_status == 0 && (
                        <button className='btn btn-secondary w-100 mt-5' onClick={acceptOrder}>
                          {intl.formatMessage({id: 'COMMON.ACCEPT'})}
                        </button>
                      )}

                      {hasAccess('order-cancel') && formik.values.courier_status == 1 && (
                        <button className='btn btn-secondary w-100 mt-5' onClick={onWAYOrder}>
                          {intl.formatMessage({id: 'COMMON.ON_WAY'})}
                        </button>
                      )}

                      {hasAccess('order-cancel') && formik.values.courier_status == 2 && (
                        <button className='btn btn-secondary w-100 mt-5' onClick={inPlaceOrder}>
                          {intl.formatMessage({id: 'COMMON.IN_PLACE'})}
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='col-md-6 px-md-1'>
              <div className='card card-stretch'>
                <div className='card-header'>
                  <h2 className='card-title'>
                    {intl.formatMessage({id: 'COMMON.DETAILS'})}{' '}
                    {intl.formatMessage({id: 'COMMON.STATUS'})}
                  </h2>
                  <div className='card-toolbar'>
                    <button
                      className='btn btn-warning btn-sm' // @ts-ignore
                      onClick={() => statusRef.current.showModal()}
                    >
                      {intl.formatMessage({id: 'COMMON.LOGS'})}
                    </button>
                  </div>
                </div>
                <div className='card-body'>
                  <table className='table table-row-dashed gy-2 gs-1 mb-2'>
                    <tbody>
                      <tr>
                        <th>{intl.formatMessage({id: 'COMMON.IS_PAID'})}</th>
                        <td className='text-end'>
                          <span
                            className={`badge ${isPaid == true ? 'badge-success' : 'badge-danger'}`}
                          >
                            {intl.formatMessage({id: isPaid == true ? 'COMMON.YES' : 'COMMON.NO'})}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>{intl.formatMessage({id: 'COMMON.STATUS'})}</th>
                        <td className='text-end'>
                          {(statuses.find((x: any) => x.id == formik.values.status) || {})
                            .description_ru || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}
                        </td>
                      </tr>
                      <tr>
                        <th>{intl.formatMessage({id: 'COMMON.SYSTEM'})}</th>
                        <td className='text-end'>
                          {order.system || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}
                        </td>
                      </tr>
                      <tr>
                        <th>{intl.formatMessage({id: 'COMMON.CREATED_AT'})}</th>
                        <td className='text-end'>
                          {moment(order.created_at).format('DD/MM/YYYY HH:mm')}
                        </td>
                      </tr>
                      <tr>
                        <th>Приготовление</th>
                        <td className='text-end'>
                          {getOrderStatusLog('WaitCooking')?.date
                            ? moment(getOrderStatusLog('WaitCooking')?.date).format(
                                'DD/MM/YYYY HH:mm'
                              )
                            : '-'}{' '}
                        </td>
                      </tr>
                      <tr>
                        <th>Курьер принял</th>
                        <td className='text-end'>
                          {getOrderStatusLog('OnWay')?.date
                            ? moment(getOrderStatusLog('OnWay')?.date).format('DD/MM/YYYY HH:mm')
                            : '-'}{' '}
                        </td>
                      </tr>
                      {order.claim_id && (
                        <tr style={{lineBreak: 'anywhere'}}>
                          <th>Claim id</th>
                          <td className='text-end'>{order.claim_id}</td>
                        </tr>
                      )}
                      {order.yandex_delivery_status && (
                        <tr>
                          <th>YD статус</th>
                          <td className='text-end'>{order.yandex_delivery_status}</td>
                        </tr>
                      )}

                      {order.status > 0 && order.status < 9 && (
                        <tr>
                          <td></td>
                          <td className='text-end'>
                            <span className='btn btn-outline btn-color-muted px-2 py-1 active btn-active-light-success'>
                              {!!getOrderStatusLog('WaitCooking') && (
                                <>
                                  {order.status > 0 && order.status < 7 && (
                                    <Timer targetTime={getOrderStatusLog('WaitCooking')?.date} />
                                  )}
                                  {(order.status == 7 || order.status == 8) &&
                                    (!!getOrderStatusLog('Delivered')
                                      ? calculateTimeDifference('WaitCooking', 'Delivered')
                                      : calculateTimeDifference('WaitCooking', 'Closed'))}
                                </>
                              )}
                            </span>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  {showInvoice && (
                    <div className='w-100'>
                      <InputMask
                        className={clsx('form-control mb-3')}
                        mask='+999 (99) 999-99-99'
                        // @ts-ignore:
                        onChange={(e) => setInvoicePhone(e.currentTarget.value)}
                        value={invoicePhone}
                      />

                      <button
                        className='btn btn-primary text-nowrap w-100'
                        onClick={async (e) => {
                          e.preventDefault()

                          let normalizedPhone = clearPhoneNumber(invoicePhone)
                          let paymentType = paymentTypes.find(
                            (x) => x.id == formik.values.payment_type_id
                          )
                          if (!paymentType) {
                            swal.fire(
                              swalDefaultConfig(
                                intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}),
                                'error'
                              )
                            )
                            return
                          }

                          try {
                            await axiosInstance.put(
                              `/orders/invoice/?order_id=${orderId}&payment_type=${paymentType.code}&phone=${normalizedPhone}`
                            )

                            swal.fire(
                              swalDefaultConfig(
                                intl.formatMessage({id: 'NOTIFICATION.INVOICE_CREATED'}),
                                'success'
                              )
                            )
                          } catch (error) {
                            swal.fire(
                              swalDefaultConfig(
                                intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}),
                                'error'
                              )
                            )
                          }
                        }}
                      >
                        {intl.formatMessage({id: 'COMMON.SEND_INVOICE'})}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-6 ps-md-1'>
          <div
            className={clsx('card card-stretch', {
              hidden: formik.values.is_self_service == 1,
            })}
          >
            <div className='card-header'>
              <h2 className='card-title'>{intl.formatMessage({id: 'COMMON.DELIVERY_ADDRESS'})}</h2>
              <div className='card-toolbar'>
                {formik.values.client_id && (
                  <button
                    className='btn btn-danger btn-sm' // @ts-ignore
                    onClick={() => getAdresses()}
                  >
                    <i className='far fa-map' style={{width: '1.2rem'}}></i>
                  </button>
                )}
              </div>
            </div>
            <div className='card-body row'>
              <div className='p-0 mb-2' style={{height: '480px'}}>
                {!!settings.yandex_key && (
                  <YMaps
                    query={{
                      apikey: process.env.REACT_APP_YMAPS_KEY,
                      lang: 'ru_RU',
                      load: 'package.full',
                    }}
                  >
                    <Map
                      onClick={mapOnClick}
                      defaultState={{
                        center: [41.311151, 69.279737],
                        // center: [41.013570, 70.083146],
                        zoom: 11,
                        controls: [],
                      }}
                      options={{
                        suppressMapOpenBlock: true,
                      }}
                      style={{width: '100%', height: '100%'}}
                    >
                      <FullscreenControl />
                      <TypeSelector />
                      <SearchControl
                        options={{
                          float: 'left',
                          provider: 'yandex#search',
                        }}
                      />
                      <ZoomControl />
                      <Placemark
                        geometry={[formik.values.latitude, formik.values.longitude]}
                        options={{
                          preset: 'islands#icon',
                        }}
                        properties={{
                          iconCaption: intl.formatMessage({id: 'COMMON.DELIVERY_ADDRESS'}),
                        }}
                      />
                      {formik.values.couriers?.latitude && formik.values.couriers?.longitude && (
                        <Placemark
                          geometry={[
                            formik.values.couriers?.latitude,
                            formik.values.couriers?.longitude,
                          ]}
                          options={{
                            preset: formik.values.couriers?.is_online
                              ? 'islands#greenIcon'
                              : 'islands#redIcon',
                          }}
                          properties={{
                            iconCaption: formik.values.couriers?.name,
                            hintContent: formik.values.couriers?.name,
                            balloonContent: `<h2>${formik.values.couriers?.name}</h2>
                          <span><b>Дата посл. локации:</b> ${moment(
                            formik.values.couriers?.last_location
                          ).format('DD/MM/YYYY HH:mm')}</span><br/>`,
                          }}
                        />
                      )}

                      {(branches || []).map((x: any) => {
                        return (
                          <Polygon
                            key={'restriction-zone-' + x.id}
                            onClick={mapOnClick}
                            // geometry={[polygonJson.map((x) => [x[1], x[0]])]}
                            geometry={[JSON.parse(x.coordinates || '[]').map((y) => [y[1], y[0]])]}
                            options={{
                              fillColor: x.polygon_color || '#0000FF',
                              strokeColor: x.polygon_color || '#0000FF',
                              opacity: 0.3,
                              strokeWidth: 5,
                            }}
                          />
                        )
                      })}
                      {(branches || []).map((x: any) => (
                        <Placemark
                          key={'branchPin-' + x.id}
                          geometry={[x.latitude, x.longitude]}
                          options={{
                            preset: 'islands#blueStretchyIcon',
                          }}
                          properties={{
                            iconContent: `Филиал: ${x.name_ru}`,
                            hintContent: `Филиал: ${x.name_ru}`,
                          }}
                        />
                      ))}
                    </Map>
                  </YMaps>
                )}
              </div>
              <div className='separator my-2'></div>
              <div className='col-md-12 mb-2'>
                <label className='form-label'>{intl.formatMessage({id: 'ITEM.ADDRESS_RU'})}</label>
                <input
                  type='text'
                  {...formik.getFieldProps('address')}
                  className={clsx(
                    'form-control',
                    {
                      'is-invalid': formik.touched.address && formik.errors.address,
                    },
                    {
                      'is-valid': formik.touched.address && !formik.errors.address,
                    }
                  )}
                  value={formik.values.address}
                />
              </div>
              <div className='col-md-3 mb-2'>
                <label className='form-label'>{intl.formatMessage({id: 'COMMON.HOUSE'})}</label>
                <input
                  type='text'
                  {...formik.getFieldProps('home')}
                  className={clsx(
                    'form-control',
                    {'is-invalid': formik.touched.home && formik.errors.home},
                    {
                      'is-valid': formik.touched.home && !formik.errors.home,
                    }
                  )}
                  value={formik.values.home}
                />
              </div>
              <div className='col-md-3 mb-2'>
                <label className='form-label'>{intl.formatMessage({id: 'COMMON.ENTRANCE'})}</label>
                <input
                  type='text'
                  {...formik.getFieldProps('entrance')}
                  className={clsx(
                    'form-control',
                    {'is-invalid': formik.touched.entrance && formik.errors.entrance},
                    {
                      'is-valid': formik.touched.entrance && !formik.errors.entrance,
                    }
                  )}
                  value={formik.values.entrance}
                />
              </div>
              <div className='col-md-3 mb-2'>
                <label className='form-label'>{intl.formatMessage({id: 'COMMON.FLOOR'})}</label>
                <input
                  type='text'
                  {...formik.getFieldProps('floor')}
                  className={clsx(
                    'form-control',
                    {'is-invalid': formik.touched.floor && formik.errors.floor},
                    {
                      'is-valid': formik.touched.floor && !formik.errors.floor,
                    }
                  )}
                  value={formik.values.floor}
                />
              </div>
              <div className='col-md-3 mb-2'>
                <label className='form-label'>
                  {intl.formatMessage({id: 'COMMON.APT_OFFICE'})}
                </label>
                <input
                  type='text'
                  {...formik.getFieldProps('apartment')}
                  className={clsx(
                    'form-control',
                    {'is-invalid': formik.touched.apartment && formik.errors.apartment},
                    {
                      'is-valid': formik.touched.apartment && !formik.errors.apartment,
                    }
                  )}
                  value={formik.values.apartment}
                />
              </div>
              <div className='col-md-12 mb-2'>
                <label className='form-label'>{intl.formatMessage({id: 'COMMON.POINT'})}</label>
                <input
                  type='text'
                  {...formik.getFieldProps('point')}
                  className={clsx(
                    'form-control',
                    {
                      'is-invalid': formik.touched.point && formik.errors.point,
                    },
                    {
                      'is-valid': formik.touched.point && !formik.errors.point,
                    }
                  )}
                  value={formik.values.point}
                />
              </div>

              {(formik.values.deliveryDistance || formik.values.deliveryDistance == 0) && (
                <div>
                  {intl.formatMessage({id: 'DISTANCE'})}:{' '}
                  {Math.round((formik.values.deliveryDistance / 1000 + Number.EPSILON) * 100) / 100}{' '}
                  {intl.formatMessage({id: 'KILOMETR_SHORT'})}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-3 pe-md-1'>
          <div className='card card-stretch mb-2 h-500px'>
            <div className='card-header d-flex align-items-center justify-content-between'>
              <h2 className='card-title'>{intl.formatMessage({id: 'MENU.MENU'})}</h2>
              {!!selectedGroup && (
                <button
                  className='btn btn-sm py-2 btn-primary'
                  onClick={() => setSelectedGroup(null)}
                >
                  {intl.formatMessage({id: 'COMMON.BACK'})}
                </button>
              )}
            </div>

            {!searchInput && (
              <div className='card-body main-overflow-x'>
                {!!selectedGroup &&
                !menu.filter((x) => x.parent_id == (selectedGroup || {}).id).length
                  ? getProductsContent((selectedGroup || {}).products || [])
                  : getMenuContent((selectedGroup || {}).id)}
              </div>
            )}
            {searchInput ? (
              <div className='card-body main-overflow-x'>
                {getProductsContent(searchProductList || [])}
              </div>
            ) : null}
          </div>
        </div>
        <div className='col-md-9 ps-md-1'>
          <div className='card card-stretch mb-2 h-500px'>
            <div className='card-header'>
              <div className='d-flex align-items-center'>
                <h2 className='card-title'>{intl.formatMessage({id: 'COMMON.PRODUCTS'})}</h2>
                <div className='d-flex align-items-center position-relative input-group-sm my-1'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen021.svg'
                    className='svg-icon-1 position-absolute ms-4'
                  />
                  <input
                    type='text'
                    data-kt-user-table-filter='search'
                    className='form-control form-control-solid w-300px ps-14'
                    placeholder={intl.formatMessage({id: 'COMMON.SEARCH'})}
                    value={searchInput}
                    onChange={(e) => searchProductInput(e.target.value)}
                  />

                  {searchInput && (
                    <button
                      className='btn link btn-sm p-0 ms-3'
                      data-bs-toggle='modal'
                      onClick={(e) => searchProductInput('')}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen040.svg'
                        className='svg-icon-2hx svg-icon-danger'
                      />
                    </button>
                  )}
                </div>
              </div>

              <div className='card-toolbar'>
                {formik.values.client_id && (
                  <button className='btn btn-sm btn-danger'>
                    {intl.formatMessage({id: 'COMMON.LOVED_PRODUCTS'})}
                  </button>
                )}
              </div>
            </div>
            <div className='card-body main-overflow-x'>
              {cart.length > 0 && (
                <div className='table-responsive'>
                  <table className='table table-hover border table-rounded table-row-dashed table-row-gray-300 gy-2 gs-1'>
                    <thead>
                      <tr className='bg-light fw-bold'>
                        {/* <th>#</th> */}
                        <th>{intl.formatMessage({id: 'COMMON.NAME'})}</th>
                        <th>{intl.formatMessage({id: 'COMMON.MODIFIER'})}</th>
                        <th className='text-center'>
                          {intl.formatMessage({id: 'COMMON.AMOUNT_SHORT'})}
                        </th>
                        <th>{intl.formatMessage({id: 'COMMON.COMMENT'})}</th>
                        <th>{intl.formatMessage({id: 'COMMON.PRICE'})}</th>
                        <th>{intl.formatMessage({id: 'COMMON.TOTAL'})}</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {getCartContent(cart)}

                      <tr className='py-2 bg-gray-100 fw-bold'>
                        <td colSpan={4} className='text-end'>
                          {intl.formatMessage({id: 'COMMON.ORDER_SUM'})}
                        </td>
                        <td></td>
                        <td className=''>
                          {convertToPrice(total)} {intl.formatMessage({id: 'COMMON.SUM'})}
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
              {!cart.length && (
                <div className='d-flex align-items-center justify-content-center h-100'>
                  <p className='p-0'>{intl.formatMessage({id: 'COMMON.CART_IS_EMPTY'})}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ProductModifierModal
        addToCart={addToCart}
        productWithModifier={productWithModifier}
        ref={productModifierModalRef}
        selectedModifiers={selectedModifiers}
        setSelectedModifiers={setSelectedModifiers}
        reduceCartItem={reduceCartItem}
        cart={cart}
      />
      <ClientAddresses
        addresses={formik.values.client_addresses}
        show={clientAddressShow}
        setShow={setClientAddressShow}
        setFormik={formik.setFieldValue}
      />
      <StatusOrder ref={statusRef} data={order} />
    </>
  )
}
