import {useEffect} from 'react'
import {Navigate, Routes, useNavigate} from 'react-router-dom'
import {useAuth} from './useAuth'

export function Logout() {
  const navigate = useNavigate()

  const redirect = () => {
    navigate('/login')
  }

  const {logOut} = useAuth()
  useEffect(() => {
    logOut()
    redirect()
    window.location.reload()
  }, [logOut])

  return (
    <Routes>
      <Navigate to='/login' replace={true} />
    </Routes>
  )
}
