import React, {FC, useRef} from 'react'
import {useIntl} from 'react-intl'
import {swalDefaultConfig} from '../../../_metronic/helpers/constants'
import {convertToPrice} from '../../../_metronic/helpers/general'
import {swal} from '../../../_metronic/helpers/swal'
import {PageTitle, useLayout} from '../../../_metronic/layout/core'
// @ts-ignore
import {KTSVG} from '../../../_metronic/helpers'
import Loader from '../../components/Loader'
import FooterCopyright from '../../modules/FooterCopyright'
import axiosInstance from '../../utils/axiosInstance'

const StatisticsRfmIndex: FC = () => {
  const intl = useIntl()
  const {classes} = useLayout()
  const windowSize = useRef([window.innerWidth, window.innerHeight])

  const [data, setData] = React.useState<any>([])

  // RFM DIAPOZONES
  const [fl, setFL] = React.useState<any>(3)
  const [fh, setFH] = React.useState<any>(10)
  const [ml, setML] = React.useState<any>(30000)
  const [mh, setMH] = React.useState<any>(60000)
  const [rl, setRL] = React.useState<any>(90)
  const [rh, setRH] = React.useState<any>(30)

  const [isLoading, setIsLoading] = React.useState(true)
  const [error, setError] = React.useState(null)

  const buildQueryParams = () => {
    let query = `?frequency_high=${fh}&frequency_low=${fl}&monetary_high=${mh}&monetary_low=${ml}&recent_high=${rh}&recent_low=${rl}`

    return query
  }

  async function getData() {
    try {
      const res = await axiosInstance.get(`/stats/accounts/rfm${buildQueryParams()}`)
      setData(res.data || [])

      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      if (err.response.status === 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      }
      if (err.response.status === 500) {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
      setError(err)
    }
  }

  React.useEffect(() => {
    setData([])
    getData()
  }, [])

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
      {
        title: intl.formatMessage({id: 'MENU.STATS'}),
        path: '',
        isSeparator: false,
        isActive: false,
      },
      {
        title: intl.formatMessage({id: 'COMMON.CURRENT'}),
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }

  // const exportToExcel = () => {
  //   let newData: any = []
  //   data.map((d: any) => {
  //     newData.push({
  //       Тип:
  //         d.type === 'completed'
  //           ? intl.formatMessage({id: 'COMMON.COMPLETED'})
  //           : intl.formatMessage({id: 'COMMON.UNCOMPLETED'}),
  //       Количество: d.count,
  //       Сред: d.avg,
  //       Итого: d.total,
  //     })
  //   })
  //   newData.push({
  //     Итого: convertToPrice(total),
  //   })

  //   const fileType =
  //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  //   const fileExtension = '.xlsx'
  //   const fileName = 'Выручка'

  //   const ws = XLSX.utils.json_to_sheet(newData)
  //   const wb = {Sheets: {data: ws}, SheetNames: ['data']}
  //   const excelBuffer = XLSX.write(wb, {
  //     bookType: 'xlsx',
  //     type: 'array',
  //   })
  //   const dataBlob = new Blob([excelBuffer], {type: fileType})
  //   const url = URL.createObjectURL(dataBlob)
  //   const link = document.createElement('a')
  //   link.href = url
  //   link.download = fileName + fileExtension
  //   document.body.appendChild(link)
  //   link.click()
  //   document.body.removeChild(link)
  // }

  const getDataByCategory = (arr: any, selector: any, category: any) => {
    if (!category || !selector) {
      return {
        count: 0,
        total: 0,
      }
    }

    let curr = (arr[selector] || []).find((x: any) => x.category === category)
    if (!curr) {
      return {
        count: 0,
        total: 0,
      }
    }

    return curr
  }

  if (isLoading) return <Loader />

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>{intl.formatMessage({id: 'MENU.RFM'})}</PageTitle>
      <div className='h-100 d-flex flex-column flex-column-fluid'>
        <div
          className='card card-stretch mb-2 flex-column-fluid main-overflow-x'
          style={{height: windowSize.current[1]}}
        >
          <div className='card-body'>
            <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6'>
              <li className='nav-item'>
                <a
                  className='nav-link text-dark active'
                  data-bs-toggle='tab'
                  href='#kt_tab_pane_mf'
                >
                  M-F
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link text-dark' data-bs-toggle='tab' href='#kt_tab_pane_rm'>
                  R-M
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link text-dark' data-bs-toggle='tab' href='#kt_tab_pane_rf'>
                  R-F
                </a>
              </li>
              <button
                className='btn ms-auto'
                data-bs-toggle='modal'
                data-bs-target='#rfmSettingsModal'
              >
                <i className='fa-solid fa-sliders text-dark'></i>
              </button>
            </ul>

            <div className='tab-content' id='rfmTabContent'>
              <div className='tab-pane fade active show' id='kt_tab_pane_mf' role='tabpanel'>
                <div className='row'>
                  <div className='col-3'>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center h-100px'></div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span>Частые заказы</span>
                      </div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span>Средние заказы</span>
                      </div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span>Редкие заказы</span>
                      </div>
                    </div>
                  </div>
                  <div className='col-3'>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span>Большая сумма</span>
                      </div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span className=''>
                          Количество:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_mf', 'F0M0').count)}
                        </span>
                        <span>
                          Сумма:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_mf', 'F0M0').total)}{' '}
                          {intl.formatMessage({id: 'COMMON.SUM'})}
                        </span>
                      </div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span className=''>
                          Количество:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_mf', 'F1M0').count)}
                        </span>
                        <span>
                          Сумма:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_mf', 'F1M0').total)}{' '}
                          {intl.formatMessage({id: 'COMMON.SUM'})}
                        </span>
                      </div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span className=''>
                          Количество:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_mf', 'F2M0').count)}
                        </span>
                        <span>
                          Сумма:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_mf', 'F2M0').total)}{' '}
                          {intl.formatMessage({id: 'COMMON.SUM'})}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='col-3'>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span>Средняя сумма</span>
                      </div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span className=''>
                          Количество:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_mf', 'F0M1').count)}
                        </span>
                        <span>
                          Сумма:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_mf', 'F0M1').total)}{' '}
                          {intl.formatMessage({id: 'COMMON.SUM'})}
                        </span>
                      </div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span className=''>
                          Количество:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_mf', 'F1M1').count)}
                        </span>
                        <span>
                          Сумма:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_mf', 'F1M1').total)}{' '}
                          {intl.formatMessage({id: 'COMMON.SUM'})}
                        </span>
                      </div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span className=''>
                          Количество:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_mf', 'F2M1').count)}
                        </span>
                        <span>
                          Сумма:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_mf', 'F2M1').total)}{' '}
                          {intl.formatMessage({id: 'COMMON.SUM'})}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='col-3'>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span>Маленькая сумма</span>
                      </div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span className=''>
                          Количество:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_mf', 'F0M2').count)}
                        </span>
                        <span>
                          Сумма:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_mf', 'F0M2').total)}{' '}
                          {intl.formatMessage({id: 'COMMON.SUM'})}
                        </span>
                      </div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span className=''>
                          Количество:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_mf', 'F1M2').count)}
                        </span>
                        <span>
                          Сумма:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_mf', 'F1M2').total)}{' '}
                          {intl.formatMessage({id: 'COMMON.SUM'})}
                        </span>
                      </div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span className=''>
                          Количество:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_mf', 'F2M2').count)}
                        </span>
                        <span>
                          Сумма:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_mf', 'F2M2').total)}{' '}
                          {intl.formatMessage({id: 'COMMON.SUM'})}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='tab-pane fade' id='kt_tab_pane_rm' role='tabpanel'>
                <div className='row'>
                  <div className='col-3'>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center h-100px'></div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span>Большая сумма</span>
                      </div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span>Средняя сумма</span>
                      </div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span>Маленькая сумма</span>
                      </div>
                    </div>
                  </div>
                  <div className='col-3'>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span>Недавние</span>
                      </div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span className=''>
                          Количество:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rm', 'R0M0').count)}
                        </span>
                        <span>
                          Сумма:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rm', 'R0M0').total)}{' '}
                          {intl.formatMessage({id: 'COMMON.SUM'})}
                        </span>
                      </div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span className=''>
                          Количество:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rm', 'R0M1').count)}
                        </span>
                        <span>
                          Сумма:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rm', 'R0M1').total)}{' '}
                          {intl.formatMessage({id: 'COMMON.SUM'})}
                        </span>
                      </div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span className=''>
                          Количество:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rm', 'R0M2').count)}
                        </span>
                        <span>
                          Сумма:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rm', 'R0M2').total)}{' '}
                          {intl.formatMessage({id: 'COMMON.SUM'})}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='col-3'>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span>Средней давности</span>
                      </div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span className=''>
                          Количество:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rm', 'R1M0').count)}
                        </span>
                        <span>
                          Сумма:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rm', 'R1M0').total)}{' '}
                          {intl.formatMessage({id: 'COMMON.SUM'})}
                        </span>
                      </div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span className=''>
                          Количество:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rm', 'R1M1').count)}
                        </span>
                        <span>
                          Сумма:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rm', 'R1M1').total)}{' '}
                          {intl.formatMessage({id: 'COMMON.SUM'})}
                        </span>
                      </div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span className=''>
                          Количество:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rm', 'R1M2').count)}
                        </span>
                        <span>
                          Сумма:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rm', 'R1M2').total)}{' '}
                          {intl.formatMessage({id: 'COMMON.SUM'})}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='col-3'>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span>Давние</span>
                      </div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span className=''>
                          Количество:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rm', 'R2M0').count)}
                        </span>
                        <span>
                          Сумма:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rm', 'R2M0').total)}{' '}
                          {intl.formatMessage({id: 'COMMON.SUM'})}
                        </span>
                      </div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span className=''>
                          Количество:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rm', 'R2M1').count)}
                        </span>
                        <span>
                          Сумма:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rm', 'R2M1').total)}{' '}
                          {intl.formatMessage({id: 'COMMON.SUM'})}
                        </span>
                      </div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span className=''>
                          Количество:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rm', 'R2M2').count)}
                        </span>
                        <span>
                          Сумма:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rm', 'R2M2').total)}{' '}
                          {intl.formatMessage({id: 'COMMON.SUM'})}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='tab-pane fade' id='kt_tab_pane_rf' role='tabpanel'>
                <div className='row'>
                  <div className='col-3'>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center h-100px'></div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span>Частые заказы</span>
                      </div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span>Средние заказы</span>
                      </div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span>Редкие заказы</span>
                      </div>
                    </div>
                  </div>
                  <div className='col-3'>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span>Недавние</span>
                      </div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span className=''>
                          Количество:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rf', 'R0F0').count)}
                        </span>
                        <span>
                          Сумма:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rf', 'R0F0').total)}{' '}
                          {intl.formatMessage({id: 'COMMON.SUM'})}
                        </span>
                      </div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span className=''>
                          Количество:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rf', 'R0F1').count)}
                        </span>
                        <span>
                          Сумма:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rf', 'R0F1').total)}{' '}
                          {intl.formatMessage({id: 'COMMON.SUM'})}
                        </span>
                      </div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span className=''>
                          Количество:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rf', 'R0F2').count)}
                        </span>
                        <span>
                          Сумма:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rf', 'R0F2').total)}{' '}
                          {intl.formatMessage({id: 'COMMON.SUM'})}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='col-3'>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span>Средней давности</span>
                      </div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span className=''>
                          Количество:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rf', 'R1F0').count)}
                        </span>
                        <span>
                          Сумма:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rf', 'R1F0').total)}{' '}
                          {intl.formatMessage({id: 'COMMON.SUM'})}
                        </span>
                      </div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span className=''>
                          Количество:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rf', 'R1F1').count)}
                        </span>
                        <span>
                          Сумма:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rf', 'R1F1').total)}{' '}
                          {intl.formatMessage({id: 'COMMON.SUM'})}
                        </span>
                      </div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span className=''>
                          Количество:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rf', 'R1F2').count)}
                        </span>
                        <span>
                          Сумма:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rf', 'R1F2').total)}{' '}
                          {intl.formatMessage({id: 'COMMON.SUM'})}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='col-3'>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span>Давние</span>
                      </div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span className=''>
                          Количество:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rf', 'R2F0').count)}
                        </span>
                        <span>
                          Сумма:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rf', 'R2F0').total)}{' '}
                          {intl.formatMessage({id: 'COMMON.SUM'})}
                        </span>
                      </div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span className=''>
                          Количество:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rf', 'R2F1').count)}
                        </span>
                        <span>
                          Сумма:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rf', 'R2F1').total)}{' '}
                          {intl.formatMessage({id: 'COMMON.SUM'})}
                        </span>
                      </div>
                    </div>
                    <div className='p-2'>
                      <div className='d-flex align-items-center justify-content-center flex-column border rounded h-100px'>
                        <span className=''>
                          Количество:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rf', 'R2F2').count)}
                        </span>
                        <span>
                          Сумма:{' '}
                          {convertToPrice(getDataByCategory(data, 'account_rf', 'R2F2').total)}{' '}
                          {intl.formatMessage({id: 'COMMON.SUM'})}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* FOOTER */}
        <div className='card card-stretch py-4 d-flex flex-lg-column' id='kt_footer'>
          <div
            className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
          >
            <div className='order-1 d-flex align-items-center mb-2 mb-md-0'></div>

            {<FooterCopyright />}
          </div>
        </div>

        <div className='modal fade' tabIndex={-1} id='rfmSettingsModal'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h2 className='modal-title'>{intl.formatMessage({id: 'MENU.SETTINGS'})}</h2>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr061.svg'
                    className='svg-icon svg-icon-2x'
                  />
                </div>
              </div>
              <div className='modal-body'>
                <div className='row'>
                  <div className='col-md-12 mb-3'>
                    <span className=''>Recency (Дни с последней покупки)</span>
                  </div>
                  <div className='col-md-6 mb-3'>
                    <label className='form-label'>Давние</label>
                    <input
                      type='text'
                      className={'form-control'}
                      value={rl}
                      onChange={(e) => setRL(e.target.value)}
                    />
                  </div>
                  <div className='col-md-6 mb-3'>
                    <label className='form-label'>Средней давности</label>
                    <input
                      type='text'
                      className={'form-control'}
                      value={rh}
                      onChange={(e) => setRH(e.target.value)}
                    />
                  </div>
                  <div className='col-12 my-2'>
                    <hr className='f-hr' />
                  </div>
                  <div className='col-md-12 mb-3'>
                    <span className=''>Frequency (Количество заказов)</span>
                  </div>
                  <div className='col-md-6 mb-3'>
                    <label className='form-label'>Редкие заказы</label>
                    <input
                      type='text'
                      className={'form-control'}
                      value={fl}
                      onChange={(e) => setFL(e.target.value)}
                    />
                  </div>
                  <div className='col-md-6 mb-3'>
                    <label className='form-label'>Средние заказы</label>
                    <input
                      type='text'
                      className={'form-control'}
                      value={fh}
                      onChange={(e) => setFH(e.target.value)}
                    />
                  </div>
                  <div className='col-12 my-2'>
                    <hr className='f-hr' />
                  </div>
                  <div className='col-md-12 mb-3'>
                    <span className=''>Monetary (Общая сумма заказов)</span>
                  </div>
                  <div className='col-md-6 mb-3'>
                    <label className='form-label'>Mаленькая сумма</label>
                    <input
                      type='text'
                      className={'form-control'}
                      value={ml}
                      onChange={(e) => setML(e.target.value)}
                    />
                  </div>
                  <div className='col-md-6 mb-3'>
                    <label className='form-label'>Cредняя сумма</label>
                    <input
                      type='text'
                      className={'form-control'}
                      value={mh}
                      onChange={(e) => setMH(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                  {intl.formatMessage({id: 'COMMON.CANCEL'})}
                </button>
                <button
                  type='button'
                  className='btn btn-primary'
                  data-bs-dismiss='modal'
                  onClick={() => getData()}
                >
                  {intl.formatMessage({id: 'COMMON.APPLY'})}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {StatisticsRfmIndex}
