import {useEffect} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'

export function RedirectCalls() {
  const navigate = useNavigate()
  let [searchParams, setSearchParams] = useSearchParams()
  let phone = searchParams.get('caller')

  useEffect(() => {
    if (!phone) {
      navigate(`/orders/create-order`)
      return
    }

    navigate(`/orders/create-order?phone=998${phone}`)
  }, [phone])

  return (
    <>
      <div>calls redirect</div>
    </>
  )
}
