import React, {FC} from 'react'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun'

type PropsType = {
  is_delivery: boolean
}

const OrderTypeIconComponent: FC<PropsType> = (props) => {
  if (props.is_delivery) {
    return <LocalShippingIcon className='text-primary' />
  } else {
    return <DirectionsRunIcon className='text-danger' />
  }
}

export default OrderTypeIconComponent
