import {FC} from 'react'

type ErrorProps = {
  error: string | null
}

const FormErrorComponent: FC<ErrorProps> = (props) => {
  return props.error ? (
    <div className='alert alert-danger d-flex align-items-center px-4 py-3 mb-4'>
      <div className='me-4'>
        <i className='fa fa-exclamation-circle text-danger fs-1'></i>
      </div>
      <div className='d-flex flex-column'>
        <h4 className='mb-1 text-danger fs-6'>Ошибка</h4>
        <span className='fs-7'>{props.error}</span>
      </div>
    </div>
  ) : null
}

export default FormErrorComponent
