import {useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle, useLayout} from '../../../_metronic/layout/core'

// COMPONENTS
import FooterCopyright from '../../modules/FooterCopyright'

// HELPERS
import Loader from '../../components/Loader'
import AddModal from './components/AddModal'
import ItemModal from './components/ItemModal'
import {useSettings} from './hooks'
import {useAuth} from '../../modules/auth/useAuth'

export function SettingsIndex() {
  const intl = useIntl()

  const {classes} = useLayout()
  const {hasAccess} = useAuth()

  const [selectedItem, setSelectedItem] = useState(null)
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false)
  const [isItemModalOpen, setIsItemModalOpen] = useState<boolean>(false)

  const {settingsQuery, createMutation, updateMutation, deleteMutation, rowsCount} = useSettings()

  const getDataContent = (arr: any) => {
    if (!arr || !arr.length) return []

    let content = []

    for (const [i, value] of arr.entries()) {
      content.push(
        <>
          {hasAccess('settings-edit') ? (
            <tr
              key={value.id}
              className='c-pointer'
              data-bs-toggle='modal'
              data-bs-target='#itemInfoModal'
              onClick={() => {
                setSelectedItem(value)
                setIsItemModalOpen(true)
              }}
            >
              <td>{i + 1}</td>
              <td>{value.title}</td>
              <td>{value.key}</td>
              <td>{value.value}</td>
            </tr>
          ) : (
            <tr key={value.id} className='c-pointer'>
              <td style={{width: 50}}>{value.id}</td>
              <td>{value.title}</td>
              <td>{value.key}</td>
              <td>{value.value}</td>
            </tr>
          )}
        </>
      )
    }

    return content
  }

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }

  if (settingsQuery.isLoading) return <Loader />

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>
        {intl.formatMessage({id: 'MENU.SETTINGS'})}
      </PageTitle>
      <div className='h-100 d-flex flex-column flex-column-fluid'>
        <div className='card card-stretch shadow mb-2'>
          <div className='card-header'>
            <div className='d-flex align-items-center justify-content-center'>
              {hasAccess('settings-add') && (
                <button
                  className='btn btn-success'
                  data-bs-toggle='modal' // @ts-ignore
                  onClick={() => setIsAddModalOpen(true)}
                >
                  {intl.formatMessage({id: 'COMMON.ADD'})}
                </button>
              )}
            </div>
            <div className='card-toolbar'></div>
          </div>
        </div>

        <div className='card card-stretch shadow mb-2 flex-column-fluid'>
          <div className='card-body main-overflow-x'>
            <div className='table-responsive'>
              <table className='table table-hover border table-rounded table-row-dashed table-row-gray-300 gy-2 gs-1'>
                <thead>
                  <tr className=' bg-light'>
                    <th className='w-25px'>#</th>
                    <th>{intl.formatMessage({id: 'COMMON.NAME'})}</th>
                    <th>{intl.formatMessage({id: 'COMMON.KEY'})}</th>
                    <th>{intl.formatMessage({id: 'COMMON.VALUE'})}</th>
                  </tr>
                </thead>
                <tbody>{getDataContent(settingsQuery.data)}</tbody>
              </table>
            </div>
          </div>
        </div>

        {/* FOOTER */}
        <div className='card card-stretch shadow py-4 d-flex flex-lg-column' id='kt_footer'>
          <div
            className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
          >
            <div className='order-1 d-flex align-items-center mb-2 mb-md-0'></div>

            {<FooterCopyright />}
          </div>
        </div>
      </div>

      <ItemModal
        item={selectedItem}
        updateMutation={updateMutation}
        deleteMutation={deleteMutation}
        isOpen={isItemModalOpen}
        onClose={() => {
          setSelectedItem(null)
          setIsItemModalOpen(false)
        }}
      />
      <AddModal
        mutation={createMutation}
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
      />
    </>
  )
}
