import axios from 'axios'
import {getStorageItem, setStorageItem} from '../modules/auth/AuthHelper'
import {ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, ROLES_KEY, USER_KEY} from './constants'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

axiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = getStorageItem(ACCESS_TOKEN_KEY)
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const originalRequest = error.config
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true

      try {
        const refreshToken = getStorageItem(REFRESH_TOKEN_KEY)
        const response = await axiosInstance.post('/auth/refresh/', {refreshToken})
        const accessToken = response.data.accessToken
        if (accessToken) {
          setStorageItem(ACCESS_TOKEN_KEY, accessToken)
          originalRequest.headers.Authorization = `Bearer ${accessToken}`
        }

        return axios(originalRequest)
      } catch (refreshError) {
        console.error('Token refresh failed:', refreshError)
        logOut()
      }
    }
    return Promise.reject(error)
  }
)

const logOut = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY)
  localStorage.removeItem(REFRESH_TOKEN_KEY)
  localStorage.removeItem(USER_KEY)
  localStorage.removeItem(ROLES_KEY)
  window.location.href = '/auth'
}

export default axiosInstance
