import moment from 'moment'
import React, {useEffect, useRef, useState} from 'react'
import {
  FullscreenControl,
  Map,
  Placemark,
  SearchControl,
  YMaps,
  ZoomControl,
} from '@pbe/react-yandex-maps'
import {useIntl} from 'react-intl'
import {swalDefaultConfig} from '../../../_metronic/helpers/constants'
import {swal} from '../../../_metronic/helpers/swal'
import {PageTitle} from '../../../_metronic/layout/core'
import {KTSVG} from '../../../_metronic/helpers'
import axiosInstance from '../../utils/axiosInstance'

export function CouriersMapIndex() {
  const intl = useIntl()
  const mapRef = useRef<any>(null)
  const windowSize = useRef([window.innerWidth, window.innerHeight])

  const [data, setData] = useState<any>(null)
  const [orders, setOrders] = useState<any>([])
  const [times, setTimes] = useState([])
  const [search, setSearch] = useState<string>('')
  const [filteredData, setFilteredData] = useState<any>([])
  const [active, setActive] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const getData = async () => {
    setIsLoading(true)
    try {
      const response = await axiosInstance.get(`/courier/maps/`)
      setData(response.data)
    } catch (error: any) {
      swal.fire(swalDefaultConfig(error.message || error.response?.data?.error?.message, 'error'))
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getData()
    const intervalId = setInterval(getData, 30 * 1000)
    return () => clearInterval(intervalId)
  }, [])

  const filterByName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase()
    setFilteredData(value ? data.filter((d: any) => d.name.toLowerCase().includes(value)) : [])
    setSearch(value)
  }

  const calculateTimeLeft = (targetTime: string) => {
    const difference = new Date().getTime() - new Date(targetTime).getTime()
    const hours = String(Math.floor((difference / (1000 * 60 * 60)) % 24)).padStart(2, '0')
    const minutes = String(Math.floor((difference / (1000 * 60)) % 60)).padStart(2, '0')
    const seconds = String(Math.floor((difference / 1000) % 60)).padStart(2, '0')
    return difference <= 0 ? '00:00:00' : `${hours}:${minutes}:${seconds}`
  }

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimes(orders.map((_, i) => calculateTimeLeft(getOrderStatusLog('TAKEOFF', i))))
    }, 1000)
    return () => clearInterval(timerInterval)
  }, [orders])

  const getOrderStatusLog = (status: string, index: number) => {
    const logs = orders[index]?.order_logs?.filter((log: any) => log?.event === status)
    return logs?.length ? logs[logs.length - 1].date : ''
  }

  const getBaloonOrders = (ordersList: any[]) =>
    ordersList.map((order) => `<li>#${order.id} - ${order.commentary}</li>`).join('')

  const getBreadcrumbs = () => [
    {
      title: intl.formatMessage({id: 'MENU.MAIN'}),
      path: '/',
      isSeparator: false,
      isActive: false,
    },
    {title: '', path: '', isSeparator: true, isActive: false},
  ]

  // if (isLoading) return <Loader />

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>
        {intl.formatMessage({id: 'MENU.COURIERS_MAP'})}
      </PageTitle>
      <div className='d-flex flex-column flex-column-fluid'>
        <div
          className='row flex-column-fluid overflow-hidden'
          style={{height: windowSize.current[1] - 90}}
        >
          <div className='col-md-12 d-flex flex-column h-100 position-relative'>
            <div
              className='d-flex align-items-center position-absolute top-0 left-0 h-100'
              style={{zIndex: 5}}
            >
              <div
                className={`card card-stretch shadow mb-5 overflow-auto h-100 ${
                  !active && 'd-none'
                }`}
              >
                <div className='card-body'>
                  <div className='input-group-sm my-1 me-3 position-relative'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen021.svg'
                      className='svg-icon-1 position-absolute ms-4'
                    />
                    <input
                      type='text'
                      className='form-control form-control-solid w-100 ps-14'
                      placeholder={intl.formatMessage({id: 'COMMON.SEARCH'})}
                      onChange={filterByName}
                      value={search}
                    />
                    {search && (
                      <button
                        className='btn link btn-sm p-0 ms-3'
                        onClick={() => {
                          setSearch('')
                          setFilteredData([])
                        }}
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen040.svg'
                          className='svg-icon-2hx svg-icon-danger'
                        />
                      </button>
                    )}
                  </div>
                  {(search ? filteredData : data)?.map((x) => (
                    <div
                      key={`courierL-${x.id}`}
                      className='border-bottom'
                      onClick={() => {
                        if (!x.latitude || !x.longitude) return

                        mapRef.current.setCenter([x.latitude, x.longitude])
                        mapRef.current.setZoom(15)
                      }}
                    >
                      <button className='btn py-2 px-0 text-start w-100'>
                        <i
                          className={`fa-solid fa-circle ${
                            x.is_online
                              ? x.orders.length
                                ? 'text-primary'
                                : 'text-success'
                              : 'text-danger'
                          }`}
                        />
                        {x.name}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <button
                type='button'
                className='btn bg-warning p-2 ms-3'
                onClick={() => setActive(!active)}
              >
                <i className={`bi bi-arrow-${active ? 'left' : 'right'} m-0 text-white fs-2`} />
              </button>
            </div>
            <div className='card card-stretch mb-2 flex-column-fluid'>
              <div className='card-body p-0'>
                <YMaps
                  query={{
                    apikey: process.env.REACT_APP_YMAPS_KEY,
                    lang: 'ru_RU',
                    load: 'package.full',
                  }}
                >
                  <Map
                    instanceRef={mapRef}
                    defaultState={{center: [41.311151, 69.279737], zoom: 12, controls: []}}
                    options={{suppressMapOpenBlock: true}}
                    style={{width: '100%', height: '100%'}}
                  >
                    <FullscreenControl />
                    <SearchControl options={{float: 'right'}} />
                    <ZoomControl
                      options={{
                        position: {
                          top: 100,
                          right: 10,
                        },
                      }}
                    />
                    {data?.map(
                      (item) =>
                        item.latitude &&
                        item.longitude && (
                          <Placemark
                            key={`courierPin-${item.id}`}
                            geometry={[item.latitude, item.longitude]}
                            // onClick={() => getOrdersById(item.orders)}
                            options={{
                              preset: item.is_online
                                ? item.orders.length
                                  ? 'islands#blueStretchyIcon'
                                  : 'islands#greenStretchyIcon'
                                : 'islands#redStretchyIcon',
                            }}
                            properties={{
                              iconContent: item.name,
                              balloonContent: `<h2>${
                                item.name
                              }</h2><b>Дата посл. локации:</b> ${moment(item.last_online).format(
                                'DD.MM.YYYY HH:mm:ss'
                              )}<br /><b>Статус:</b> ${
                                item.is_online
                                  ? item.orders.length
                                    ? 'На заказе'
                                    : 'Свободен'
                                  : 'Офлайн'
                              }<ul>${getBaloonOrders(item.orders)}</ul>`,
                            }}
                          />
                        )
                    )}
                  </Map>
                </YMaps>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
