import clsx from 'clsx'
import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import * as Yup from 'yup'

import {useFormik} from 'formik'

// @ts-ignore:
import Select from 'react-select'
// @ts-ignore:
import {useDropzone} from 'react-dropzone'

import {useIntl} from 'react-intl'
import {KTSVG, selectStyles} from '../../../../../_metronic/helpers'
import {swal} from '../../../../../_metronic/helpers/swal'

import {MAIN_STATUSES, swalDefaultConfig} from '../../../../../_metronic/helpers/constants'
import {DeleteModal} from './DeleteModal'
import axiosInstance from '../../../../utils/axiosInstance'

export const ItemModal = forwardRef((props: any, ref: any) => {
  const intl = useIntl()

  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({multiple: false})

  const handleClose = () => {
    formik.resetForm()
    setShow(false)
  }
  const handleShow = () => {
    formik.resetForm()
    setShow(true)
  }

  useImperativeHandle(ref, () => ({
    showModal() {
      handleShow()
    },
  }))

  let modalRef = useRef(null)
  const deleteModalRef = useRef()

  const validationSchema = Yup.object().shape({
    file_image: Yup.mixed(),
    url: Yup.string()
      .min(3, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 3}))
      .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
    parent_id: Yup.string(),
    smile: Yup.string().min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})),
    name_ru: Yup.string()
      .min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1}))
      .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
    name_uz: Yup.string().min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})),
    name_en: Yup.string().min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})),
    description_ru: Yup.string().min(
      1,
      intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})
    ),
    description_uz: Yup.string().min(
      1,
      intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})
    ),
    description_en: Yup.string().min(
      1,
      intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})
    ),
    position: Yup.number(),
    // seo title
    seo_title_ru: Yup.string().min(
      1,
      intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})
    ),
    seo_title_uz: Yup.string().min(
      1,
      intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})
    ),
    seo_title_en: Yup.string().min(
      1,
      intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})
    ),
    // seo keyword
    seo_keyword_ru: Yup.string().min(
      1,
      intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})
    ),
    seo_keyword_uz: Yup.string().min(
      1,
      intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})
    ),
    seo_keyword_en: Yup.string().min(
      1,
      intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})
    ),
    // seo description
    seo_description_ru: Yup.string().min(
      1,
      intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})
    ),
    seo_description_uz: Yup.string().min(
      1,
      intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})
    ),
    seo_description_en: Yup.string().min(
      1,
      intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})
    ),
    is_active_online: Yup.number().integer(),
    status: Yup.number().integer(),
    tax_code: Yup.string().nullable(),
    tax_package_code: Yup.string().nullable(),
    tax_unit: Yup.number().integer().nullable(),
    measure: Yup.number().nullable(),
    measureUnit: Yup.string().nullable(),
  })
  const initialValues = {
    url: '',
    parent_id: '0',
    smile: '',
    name_ru: '',
    name_uz: '',
    name_en: '',
    description_ru: '',
    description_uz: '',
    description_en: '',
    position: '',
    file_image: '',
    is_active_online: 1,
    image: '',
    price: '',
    status: 1,
    // seo
    seo_title_ru: '',
    seo_title_uz: '',
    seo_title_en: '',
    seo_keyword_ru: '',
    seo_keyword_uz: '',
    seo_keyword_en: '',
    seo_description_ru: '',
    seo_description_uz: '',
    seo_description_en: '',
    tax_code: '',
    tax_package_code: '',
    tax_unit: '',
    measure: '',
    measureUnit: '',
  }

  if (!!props.item && !!props.item.id) {
    initialValues.url = props.item.url
    initialValues.parent_id = props.item.parent_id
    initialValues.smile = props.item.smile
    initialValues.name_ru = props.item.name_ru
    initialValues.name_uz = props.item.name_uz
    initialValues.name_en = props.item.name_en
    initialValues.description_ru = props.item.description_ru
    initialValues.description_uz = props.item.description_uz
    initialValues.description_en = props.item.description_en
    initialValues.position = props.item.position || ''
    initialValues.is_active_online = props.item.is_active_online ? 1 : 0
    initialValues.file_image = props.item.file_image
    initialValues.image = props.item.image
    initialValues.price = props.item.price
    initialValues.status = props.item.is_active ? 1 : 0
    initialValues.seo_title_ru = props.item.seo_title_ru
    initialValues.seo_title_uz = props.item.seo_title_uz
    initialValues.seo_title_en = props.item.seo_title_en
    initialValues.seo_keyword_ru = props.item.seo_keyword_ru
    initialValues.seo_keyword_uz = props.item.seo_keyword_uz
    initialValues.seo_keyword_en = props.item.seo_keyword_en
    initialValues.seo_description_ru = props.item.seo_description_ru
    initialValues.seo_description_uz = props.item.seo_description_uz
    initialValues.seo_description_en = props.item.seo_description_en
    initialValues.tax_code = props.item.tax_code
    initialValues.tax_package_code = props.item.tax_package_code
    initialValues.tax_unit = props.item.tax_unit
    initialValues.measure = props.item.measure
    initialValues.measureUnit = props.item.measureUnit
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)
      setLoading(true)

      let data = {
        id: props.item.id,
        region_id: props.item.region_id,
        url: values.url,
        parent_id: values.parent_id || '0',
        smile: values.smile,
        name_ru: values.name_ru,
        name_uz: values.name_uz,
        name_en: values.name_en,
        description_ru: values.description_ru,
        description_uz: values.description_uz,
        description_en: values.description_en,
        is_active_online: values.is_active_online || 0,
        is_active: values.status,
        // file_image: values.file_image || null,
        image: values.file_image || null,

        seo_title_ru: values.seo_title_ru,
        seo_title_uz: values.seo_title_uz,
        seo_title_en: values.seo_title_en,
        seo_keyword_ru: values.seo_keyword_ru,
        seo_keyword_uz: values.seo_keyword_uz,
        seo_keyword_en: values.seo_keyword_en,
        seo_description_ru: values.seo_description_ru,
        seo_description_uz: values.seo_description_uz,
        seo_description_en: values.seo_description_en,
        tax_code: values.tax_code,
        tax_package_code: values.tax_package_code,
        tax_unit: values.tax_unit,
        measure: values.measure || 0,
        measure_unit: values.measureUnit || '',

        // price: values.price,
      }

      if (!!values.position) {
        // @ts-ignore:
        data.position = values.position || ''
      }

      try {
        const res = await axiosInstance.put(`/products/${props.item.id}/`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        swal.fire({
          title: intl.formatMessage({id: 'NOTIFICATION.SUCCESS_UPDATE'}),
          icon: 'success',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        })
      } catch (err: any) {
        if (err.response.status === 400) {
          swal.fire(
            swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}), 'error')
          )
        } else {
          swal.fire(
            swalDefaultConfig(
              intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}),
              'error'
            )
          )
        }
        setLoading(false)
        return
      }
      setShow(false)
      setLoading(false)
      setSubmitting(false)
      props.refreshData()
    },
  })

  const getSelectContent = (
    arr: any,
    keyName = '',
    valueKey = '',
    valueName: string | null = null,
    isProper = true
  ) => {
    if (!arr || !arr.length) return []

    let content = []

    for (const [i, value] of arr.entries()) {
      content.push(
        <label
          key={keyName + i}
          className={clsx('btn btn-outline btn-color-muted p-2 z-0', {
            active: formik.values.status === value.id,
            [value.class]: true,
          })}
          data-kt-button='true'
        >
          <input
            className='btn-check'
            type='radio'
            name='RadioStatus'
            value={value.id}
            checked={formik.values.status === value.id}
            onChange={(e) => formik.setFieldValue('status', e.currentTarget.value)}
          />
          {valueName && value[valueName]}
          {!valueName && isProper && value.nameRu}
          {!valueName && !isProper && value.name}
        </label>
      )
    }

    return content
  }

  const getSelectContentOnlineStatus = (
    arr: any,
    keyName = '',
    valueKey = '',
    valueName: string | null = null,
    isProper = true
  ) => {
    if (!arr || !arr.length) return []

    let content = []

    for (const [i, value] of arr.entries()) {
      content.push(
        <label
          key={keyName + i}
          className={clsx('btn btn-outline btn-color-muted p-2 z-0', {
            active: formik.values.is_active_online === value.id,
            [value.class]: true,
          })}
          data-kt-button='true'
        >
          <input
            className='btn-check'
            type='radio'
            name='RadioStatus'
            value={value.id}
            checked={formik.values.is_active_online === value.id}
            onChange={(e) => {
              formik.setFieldValue('is_active_online', e.currentTarget.value)
            }}
          />
          {valueName && value[valueName]}
          {!valueName && isProper && value.nameRu}
          {!valueName && !isProper && value.name}
        </label>
      )
    }

    return content
  }

  React.useEffect(() => {
    formik.setFieldValue('file_image', acceptedFiles[0])
  }, [acceptedFiles[0]?.name])

  return (
    <>
      <Modal
        className='es-modal'
        enforceFocus={false}
        size='lg'
        show={show}
        onHide={handleClose}
        id='itemInfoModal'
        ref={modalRef}
        centered
      >
        <Modal.Header>
          <h2 className='modal-title'>{intl.formatMessage({id: 'COMMON.CHANGE'})}</h2>
          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={handleClose}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </Modal.Header>
        <Modal.Body>
          <form className='row' onSubmit={formik.handleSubmit}>
            {formik.status ? (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            ) : (
              <div></div>
            )}

            <div className='col-md-12 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.URL'})}</label>
              <input
                type='text'
                {...formik.getFieldProps('url')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.url && formik.errors.url},
                  {
                    'is-valid': formik.touched.url && !formik.errors.url,
                  }
                )}
                value={formik.values.url}
              />
            </div>
            <div className='col-12 my-2'>
              <hr className='f-hr' />
            </div>
            <div className='col-md-6 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'ITEM.PARENT_GROUP'})}</label>
              <Select
                classNamePrefix='form-control'
                styles={selectStyles}
                placeholder=''
                components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                className='react-select-styled react-select-solid'
                classNames={{
                  control: () =>
                    formik.touched.parent_id && !formik.errors.parent_id
                      ? 'form-select p-0 border-success'
                      : formik.touched.parent_id && formik.errors.parent_id
                      ? 'form-select p-0 border-danger'
                      : 'form-select p-0',
                }}
                options={props.allGroups?.map((x: any) => ({
                  value: x.id,
                  label: x.name_ru,
                }))}
                onChange={(selectedItem: any) => {
                  console.log('Changed:', selectedItem.value)
                  formik.setFieldValue('parent_id', selectedItem.value)
                }}
                value={{
                  value:
                    (props.allGroups?.find((x: any) => x.id === formik.values.parent_id) || {})
                      .id || null,
                  label:
                    (props.allGroups?.find((x: any) => x.id === formik.values.parent_id) || {})
                      .name_ru || '',
                }}
              />
            </div>
            {/* <div className='col-md-6 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'ITEM.PARENT_GROUP'})}</label>

              <select
                className={clsx(
                  'form-select',
                  {'is-invalid': formik.touched.parent_group && formik.errors.parent_group},
                  {
                    'is-valid': formik.touched.parent_group && !formik.errors.parent_group,
                  }
                )}
                {...formik.getFieldProps('parent_group')}
              >
                {getParentGroupDrpdwnBtns(props.allGroups)}
              </select>
            </div> */}
            <div className='col-md-6 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'ITEM.SMILE'})}</label>
              <input
                type='text'
                {...formik.getFieldProps('smile')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.smile && formik.errors.smile},
                  {
                    'is-valid': formik.touched.smile && !formik.errors.smile,
                  }
                )}
                value={formik.values.smile}
              />
            </div>
            <div className='col-12 my-2'>
              <hr className='f-hr' />
            </div>

            <div className='col-md-12'>
              <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6'>
                <li className='nav-item'>
                  <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_ru'>
                    Русский
                  </a>
                </li>
                <li className='nav-item'>
                  <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_uz'>
                    O'zbekcha
                  </a>
                </li>
                <li className='nav-item'>
                  <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_en'>
                    English
                  </a>
                </li>
              </ul>
              <div className='tab-content' id='tabContent'>
                <div className='tab-pane fade show active' id='kt_tab_pane_ru' role='tabpanel'>
                  <div className='row'>
                    <div className='col-md-12 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.NAME_RU'})}
                      </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('name_ru')}
                        className={clsx(
                          'form-control',
                          {'is-invalid': formik.touched.name_ru && formik.errors.name_ru},
                          {
                            'is-valid': formik.touched.name_ru && !formik.errors.name_ru,
                          }
                        )}
                        value={formik.values.name_ru}
                      />
                    </div>
                    <div className='col-md-12 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.DESCRIPTION_RU'})}
                      </label>
                      <textarea
                        {...formik.getFieldProps('description_ru')}
                        className={clsx(
                          'form-control',
                          {
                            'is-invalid':
                              formik.touched.description_ru && formik.errors.description_ru,
                          },
                          {
                            'is-valid':
                              formik.touched.description_ru && !formik.errors.description_ru,
                          }
                        )}
                        value={formik.values.description_ru}
                        rows={4}
                      />
                    </div>
                    <div className='col-md-12 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.SEO_TITLE_RU'})}
                      </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('seo_title_ru')}
                        className={clsx(
                          'form-control',
                          {'is-invalid': formik.touched.seo_title_ru && formik.errors.seo_title_ru},
                          {
                            'is-valid': formik.touched.seo_title_ru && !formik.errors.seo_title_ru,
                          }
                        )}
                        value={formik.values.seo_title_ru}
                      />
                    </div>
                    <div className='col-md-12 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.SEO_KEYWORD_RU'})}
                      </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('seo_keyword_ru')}
                        className={clsx(
                          'form-control',
                          {
                            'is-invalid':
                              formik.touched.seo_keyword_ru && formik.errors.seo_keyword_ru,
                          },
                          {
                            'is-valid':
                              formik.touched.seo_keyword_ru && !formik.errors.seo_keyword_ru,
                          }
                        )}
                        value={formik.values.seo_keyword_ru}
                      />
                    </div>
                    <div className='col-md-12 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.SEO_DESCRIPTION_RU'})}
                      </label>
                      <textarea
                        {...formik.getFieldProps('seo_description_ru')}
                        className={clsx(
                          'form-control',
                          {
                            'is-invalid':
                              formik.touched.seo_description_ru && formik.errors.seo_description_ru,
                          },
                          {
                            'is-valid':
                              formik.touched.seo_description_ru &&
                              !formik.errors.seo_description_ru,
                          }
                        )}
                        value={formik.values.seo_description_ru}
                        rows={4}
                      />
                    </div>
                  </div>
                </div>
                <div className='tab-pane fade' id='kt_tab_pane_uz' role='tabpanel'>
                  <div className='row'>
                    <div className='col-md-12 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.NAME_UZ'})}
                      </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('name_uz')}
                        className={clsx(
                          'form-control',
                          {'is-invalid': formik.touched.name_uz && formik.errors.name_uz},
                          {
                            'is-valid': formik.touched.name_uz && !formik.errors.name_uz,
                          }
                        )}
                        value={formik.values.name_uz}
                      />
                    </div>
                    <div className='col-md-12 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.DESCRIPTION_UZ'})}
                      </label>
                      <textarea
                        {...formik.getFieldProps('description_uz')}
                        className={clsx(
                          'form-control',
                          {
                            'is-invalid':
                              formik.touched.description_uz && formik.errors.description_uz,
                          },
                          {
                            'is-valid':
                              formik.touched.description_uz && !formik.errors.description_uz,
                          }
                        )}
                        value={formik.values.description_uz}
                        rows={4}
                      />
                    </div>
                    <div className='col-md-12 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.SEO_TITLE_UZ'})}
                      </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('seo_title_uz')}
                        className={clsx(
                          'form-control',
                          {'is-invalid': formik.touched.seo_title_uz && formik.errors.seo_title_uz},
                          {
                            'is-valid': formik.touched.seo_title_uz && !formik.errors.seo_title_uz,
                          }
                        )}
                        value={formik.values.seo_title_uz}
                      />
                    </div>
                    <div className='col-md-12 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.SEO_KEYWORD_UZ'})}
                      </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('seo_keyword_uz')}
                        className={clsx(
                          'form-control',
                          {
                            'is-invalid':
                              formik.touched.seo_keyword_uz && formik.errors.seo_keyword_uz,
                          },
                          {
                            'is-valid':
                              formik.touched.seo_keyword_uz && !formik.errors.seo_keyword_uz,
                          }
                        )}
                        value={formik.values.seo_keyword_uz}
                      />
                    </div>
                    <div className='col-md-12 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.SEO_DESCRIPTION_UZ'})}
                      </label>
                      <textarea
                        {...formik.getFieldProps('seo_description_uz')}
                        className={clsx(
                          'form-control',
                          {
                            'is-invalid':
                              formik.touched.seo_description_uz && formik.errors.seo_description_uz,
                          },
                          {
                            'is-valid':
                              formik.touched.seo_description_uz &&
                              !formik.errors.seo_description_uz,
                          }
                        )}
                        value={formik.values.seo_description_uz}
                        rows={4}
                      />
                    </div>
                  </div>
                </div>
                <div className='tab-pane fade' id='kt_tab_pane_en' role='tabpanel'>
                  <div className='row'>
                    <div className='col-md-12 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.NAME_EN'})}
                      </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('name_en')}
                        className={clsx(
                          'form-control',
                          {'is-invalid': formik.touched.name_en && formik.errors.name_en},
                          {
                            'is-valid': formik.touched.name_en && !formik.errors.name_en,
                          }
                        )}
                        value={formik.values.name_en}
                      />
                    </div>
                    <div className='col-md-12 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.DESCRIPTION_EN'})}
                      </label>
                      <textarea
                        {...formik.getFieldProps('description_en')}
                        className={clsx(
                          'form-control',
                          {
                            'is-invalid':
                              formik.touched.description_en && formik.errors.description_en,
                          },
                          {
                            'is-valid':
                              formik.touched.description_en && !formik.errors.description_en,
                          }
                        )}
                        value={formik.values.description_en}
                        rows={4}
                      />
                    </div>
                    <div className='col-md-12 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.SEO_TITLE_EN'})}
                      </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('seo_title_en')}
                        className={clsx(
                          'form-control',
                          {'is-invalid': formik.touched.seo_title_en && formik.errors.seo_title_en},
                          {
                            'is-valid': formik.touched.seo_title_en && !formik.errors.seo_title_en,
                          }
                        )}
                        value={formik.values.seo_title_en}
                      />
                    </div>
                    <div className='col-md-12 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.SEO_KEYWORD_EN'})}
                      </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('seo_keyword_en')}
                        className={clsx(
                          'form-control',
                          {
                            'is-invalid':
                              formik.touched.seo_keyword_en && formik.errors.seo_keyword_en,
                          },
                          {
                            'is-valid':
                              formik.touched.seo_keyword_en && !formik.errors.seo_keyword_en,
                          }
                        )}
                        value={formik.values.seo_keyword_en}
                      />
                    </div>
                    <div className='col-md-12 mb-3'>
                      <label className='form-label'>
                        {intl.formatMessage({id: 'ITEM.SEO_DESCRIPTION_EN'})}
                      </label>
                      <textarea
                        {...formik.getFieldProps('seo_description_en')}
                        className={clsx(
                          'form-control',
                          {
                            'is-invalid':
                              formik.touched.seo_description_en && formik.errors.seo_description_en,
                          },
                          {
                            'is-valid':
                              formik.touched.seo_description_en &&
                              !formik.errors.seo_description_en,
                          }
                        )}
                        value={formik.values.seo_description_en}
                        rows={4}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 my-2'>
              <hr className='f-hr' />
            </div>

            <div className='col-md-12 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.IMAGE'})}</label>
              <div
                {...getRootProps({
                  className: clsx(
                    'form-control c-dropzone',
                    {'is-invalid': formik.touched.file_image && formik.errors.file_image},
                    {
                      'is-valid': formik.touched.file_image && !formik.errors.file_image,
                    }
                  ),
                })}
              >
                <input {...getInputProps()} />
                <p className='m-0'>
                  {acceptedFiles.length > 0
                    ? intl.formatMessage({id: 'COMMON.PICTURE_SELECTED'})
                    : intl.formatMessage({id: 'COMMON.SELECT_PICTURE'})}
                </p>
              </div>
            </div>
            <div className='col-md-12 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.POSITION'})}</label>
              <input
                type='number'
                {...formik.getFieldProps('position')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.position && formik.errors.position},
                  {
                    'is-valid': formik.touched.position && !formik.errors.position,
                  }
                )}
                value={formik.values.position}
              />
            </div>
            <div className='col-md-4 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.IKPU_CODE'})}</label>
              <input
                type='text'
                {...formik.getFieldProps('tax_code')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.tax_code && formik.errors.tax_code},
                  {
                    'is-valid': formik.touched.tax_code && !formik.errors.tax_code,
                  }
                )}
                value={formik.values.tax_code}
              />
            </div>
            <div className='col-md-4 mb-3'>
              <label className='form-label'>
                {intl.formatMessage({id: 'COMMON.PACKAGE_CODE'})}
              </label>
              <input
                type='text'
                {...formik.getFieldProps('tax_package_code')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.tax_package_code && formik.errors.tax_package_code},
                  {
                    'is-valid': formik.touched.tax_package_code && !formik.errors.tax_package_code,
                  }
                )}
                value={formik.values.tax_package_code}
              />
            </div>
            <div className='col-md-4 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.TAX_UNIT'})}</label>
              <input
                type='text'
                {...formik.getFieldProps('tax_unit')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.tax_unit && formik.errors.tax_unit},
                  {
                    'is-valid': formik.touched.tax_unit && !formik.errors.tax_unit,
                  }
                )}
                value={formik.values.tax_unit}
              />
            </div>

            <div className='col-md-4 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.MEASURE'})}</label>
              <input
                type='text'
                {...formik.getFieldProps('measure')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.measure && formik.errors.measure},
                  {
                    'is-valid': formik.touched.measure && !formik.errors.measure,
                  }
                )}
                value={formik.values.measure}
              />
            </div>
            <div className='col-md-4 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.UNIT'})}</label>
              <input
                type='text'
                {...formik.getFieldProps('measureUnit')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.measureUnit && formik.errors.measureUnit},
                  {
                    'is-valid': formik.touched.measureUnit && !formik.errors.measureUnit,
                  }
                )}
                value={formik.values.measureUnit}
              />
            </div>

            <div className='col-12 my-5'>
              <hr className='f-hr' />
            </div>

            <div className='col-md-6 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.AVAILABLE'})}</label>
              <div className='form-check form-check-custom form-check-solid'>
                <div
                  className='btn-group w-100'
                  data-kt-buttons='true'
                  data-kt-buttons-target='[data-kt-button]'
                >
                  {getSelectContent(MAIN_STATUSES, 'patientStatus-')}
                </div>
              </div>
            </div>
            <div className='col-md-6 mb-3'>
              <label className='form-label'>
                {intl.formatMessage({id: 'COMMON.AVAILABLE_ONLINE'})}
              </label>
              <div className='form-check form-check-custom form-check-solid'>
                <div
                  className='btn-group w-100'
                  data-kt-buttons='true'
                  data-kt-buttons-target='[data-kt-button]'
                >
                  {getSelectContentOnlineStatus(MAIN_STATUSES, 'patientStatus-')}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          {/* <button
            type='button'
            className='btn btn-danger me-auto'
            // @ts-ignore
            onClick={() => (handleClose(), deleteModalRef.current.showModal())}
          >
            {intl.formatMessage({id: 'COMMON.DELETE'})}
          </button> */}
          <button type='button' className='btn btn-light' onClick={handleClose}>
            {intl.formatMessage({id: 'COMMON.CANCEL'})}
          </button>
          <button
            type='button'
            className='btn btn-success'
            onClick={() => {
              formik.handleSubmit()
            }}
            disabled={loading}
            data-kt-indicator={loading === true ? 'on' : 'off'}
          >
            <span className='indicator-label'>{intl.formatMessage({id: 'COMMON.SAVE'})}</span>

            <span className='indicator-progress'>
              {intl.formatMessage({id: 'COMMON.PLS_WAIT'})}{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          </button>
        </Modal.Footer>
      </Modal>
      <DeleteModal item={props.item} ref={deleteModalRef} refreshData={props.refreshData} />
    </>
  )
})
