const FooterCopyright = () => {
  return (
    <div className='text-muted order-2 order-md-1 fs-7'>
      <i className='fa-regular fa-copyright'></i> {new Date().getFullYear()}{' '}
      <a href='https://exord.uz/' target='_blank'>
        EXORD
      </a>{' '}
      <span>Гарантия успеха</span>
    </div>
  )
}

export default FooterCopyright
