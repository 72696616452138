import {Chart, registerables} from 'chart.js'
import React from 'react'
import {createRoot} from 'react-dom/client'
import {QueryClient, QueryClientProvider} from 'react-query'
import {AppRoutes} from './app/routing/AppRoutes'
import {AuthProvider} from './app/modules/auth/useAuth'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import './_metronic/assets/sass/style.scss'
import {NuqsAdapter} from 'nuqs/adapters/react'

Chart.register(...registerables)

const queryClient = new QueryClient()
const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <NuqsAdapter>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <AppRoutes />
        </QueryClientProvider>
      </AuthProvider>
    </NuqsAdapter>
  )
}
