import clsx from 'clsx'
import {forwardRef, useImperativeHandle, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import * as Yup from 'yup'

import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../_metronic/helpers'

import {swal} from '../../../../../_metronic/helpers/swal'
import axiosInstance from '../../../../utils/axiosInstance'

export const AddModal = forwardRef((props: any, ref: any) => {
  const intl = useIntl()

  const [show, setShow] = useState(false)

  const handleClose = () => {
    formik.resetForm()
    setShow(false)
  }
  const handleShow = () => {
    formik.resetForm()
    setShow(true)
  }

  useImperativeHandle(ref, () => ({
    showModal() {
      handleShow()
    },
  }))

  const validationSchema = Yup.object().shape({
    file_image: Yup.mixed(),
    url: Yup.string()
      .min(3, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 3}))
      .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
    parent_group: Yup.string().required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
    smile: Yup.string().min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})),
    name_ru: Yup.string()
      .min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1}))
      .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
    name_uz: Yup.string().min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})),
    name_en: Yup.string().min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})),
    description_ru: Yup.string().min(
      1,
      intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})
    ),
    description_uz: Yup.string().min(
      1,
      intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})
    ),
    description_en: Yup.string().min(
      1,
      intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})
    ),
    position: Yup.number().integer(),
    is_active_online: Yup.number().integer(),
  })

  let initialValues = {
    url: '',
    parent_group: '0',
    smile: '',
    name_ru: '',
    name_uz: '',
    name_en: '',
    description_ru: '',
    description_uz: '',
    description_en: '',
    position: 0,
    file_image: '',
    is_active_online: 1,
    image: '',
    list_image: '',
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)

      let data = {
        region_id: props.region_id,
        url: values.url,
        parent_group: values.parent_group || '0',
        smile: values.smile,
        name_ru: values.name_ru,
        name_uz: values.name_uz,
        name_en: values.name_en,
        description_ru: values.description_ru,
        description_uz: values.description_uz,
        description_en: values.description_en,
        is_active_online: values.is_active_online || 0,
        file_image: values.file_image || null,
      }

      if (!!values.position) {
        // @ts-ignore:
        data.position = values.position || ''
      }

      try {
        const res = await axiosInstance.post('/products/', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        swal.fire({
          title: intl.formatMessage({id: 'NOTIFICATION.SUCCESS_ADD'}),
          icon: 'success',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        })
      } catch (err: any) {
        console.log('error', err)
        let msg = (((err || {}).response || {}).data || {}).message

        setStatus(intl.formatMessage({id: 'VALIDATION.API_ERROR'}, {text: msg || err.message}))
        return
      }
      setShow(false)
      setSubmitting(false)
      props.refreshData()
    },
  })

  const getParentGroupDrpdwnBtns = (arr: any) => {
    if (!arr || !arr.length) return []

    let content = [
      <option key={'pgdrp-def'} value={'0'}>
        {intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}
      </option>,
    ]

    for (const [i, value] of arr.entries()) {
      content.push(
        <option key={'pgdrp-' + i} value={value.id}>
          {value.name_ru}
        </option>
      )
    }

    return content
  }

  return (
    <Modal size='lg' show={show} onHide={handleClose} centered>
      <Modal.Header>
        <h2 className='modal-title'>{intl.formatMessage({id: 'COMMON.ADD'})}</h2>
        <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={handleClose}>
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
        </div>
      </Modal.Header>
      <Modal.Body>
        <form className='row' onSubmit={formik.handleSubmit}>
          {formik.status ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          ) : (
            <div></div>
          )}

          <div className='col-md-12 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'COMMON.URL'})}</label>
            <input
              type='text'
              {...formik.getFieldProps('url')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.url && formik.errors.url},
                {
                  'is-valid': formik.touched.url && !formik.errors.url,
                }
              )}
              value={formik.values.url}
            />
          </div>
          <div className='col-12 my-5'>
            <hr className='f-hr' />
          </div>

          <div className='col-md-6 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'ITEM.PARENT_GROUP'})}</label>

            <select
              className={clsx(
                'form-select',
                {'is-invalid': formik.touched.parent_group && formik.errors.parent_group},
                {
                  'is-valid': formik.touched.parent_group && !formik.errors.parent_group,
                }
              )}
              {...formik.getFieldProps('parent_group')}
            >
              {getParentGroupDrpdwnBtns(props.allGroups)}
            </select>
          </div>

          <div className='col-md-6 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'ITEM.SMILE'})}</label>
            <input
              type='text'
              {...formik.getFieldProps('smile')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.smile && formik.errors.smile},
                {
                  'is-valid': formik.touched.smile && !formik.errors.smile,
                }
              )}
              value={formik.values.smile}
            />
          </div>

          <div className='col-12 my-5'>
            <hr className='f-hr' />
          </div>

          <div className='col-md-12 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'ITEM.NAME_RU'})}</label>
            <input
              type='text'
              {...formik.getFieldProps('name_ru')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.name_ru && formik.errors.name_ru},
                {
                  'is-valid': formik.touched.name_ru && !formik.errors.name_ru,
                }
              )}
              value={formik.values.name_ru}
            />
          </div>
          <div className='col-md-12 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'ITEM.DESCRIPTION_RU'})}</label>
            <textarea
              {...formik.getFieldProps('description_ru')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.description_ru && formik.errors.description_ru},
                {
                  'is-valid': formik.touched.description_ru && !formik.errors.description_ru,
                }
              )}
              value={formik.values.description_ru}
              placeholder='Leave a description here'
            ></textarea>
          </div>

          <div className='col-12 my-5'>
            <hr className='f-hr' />
          </div>

          <div className='col-md-12 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'ITEM.NAME_UZ'})}</label>
            <input
              type='text'
              {...formik.getFieldProps('name_uz')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.name_uz && formik.errors.name_uz},
                {
                  'is-valid': formik.touched.name_uz && !formik.errors.name_uz,
                }
              )}
              value={formik.values.name_uz}
            />
          </div>
          <div className='col-md-12 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'ITEM.DESCRIPTION_UZ'})}</label>
            <textarea
              {...formik.getFieldProps('description_uz')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.description_uz && formik.errors.description_uz},
                {
                  'is-valid': formik.touched.description_uz && !formik.errors.description_uz,
                }
              )}
              value={formik.values.description_uz}
              placeholder='Leave a description here'
            ></textarea>
          </div>

          <div className='col-12 my-5'>
            <hr className='f-hr' />
          </div>

          <div className='col-md-12 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'ITEM.NAME_EN'})}</label>
            <input
              type='text'
              {...formik.getFieldProps('name_en')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.name_en && formik.errors.name_en},
                {
                  'is-valid': formik.touched.name_en && !formik.errors.name_en,
                }
              )}
              value={formik.values.name_en}
            />
          </div>
          <div className='col-md-12 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'ITEM.DESCRIPTION_EN'})}</label>
            <textarea
              {...formik.getFieldProps('description_en')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.description_en && formik.errors.description_en},
                {
                  'is-valid': formik.touched.description_en && !formik.errors.description_en,
                }
              )}
              value={formik.values.description_en}
              placeholder='Leave a description here'
            ></textarea>
          </div>

          <div className='col-12 my-5'>
            <hr className='f-hr' />
          </div>

          <div className='col-md-12 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'COMMON.POSITION'})}</label>
            <input
              type='number'
              {...formik.getFieldProps('position')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.position && formik.errors.position},
                {
                  'is-valid': formik.touched.position && !formik.errors.position,
                }
              )}
              value={formik.values.position}
            />
          </div>

          <div className='col-md-6 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'COMMON.IMAGE'})}</label>
            <input
              type='file'
              onChange={(event) => {
                // @ts-ignore
                formik.setFieldValue('file_image', event.currentTarget.files[0])
              }}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.file_image && formik.errors.file_image},
                {
                  'is-valid': formik.touched.file_image && !formik.errors.file_image,
                }
              )}
            />
          </div>

          <div className='col-12 my-5'>
            <hr className='f-hr' />
          </div>

          <div className='col-md-6 mb-3'>
            <input
              className='form-check-input me-2'
              type='checkbox'
              id='is_active_online'
              checked={!!formik.values.is_active_online}
              onChange={(cb) => {
                formik.setFieldValue('is_active_online', !!cb.target.checked ? 1 : 0)
              }}
            />
            <label className='form-check-label' htmlFor='is_active_online'>
              {intl.formatMessage({id: 'COMMON.IS_INCLUDED_IN_MENU'})}
            </label>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button type='button' className='btn btn-light' onClick={handleClose}>
          {intl.formatMessage({id: 'COMMON.CANCEL'})}
        </button>
        <button
          type='button'
          className='btn btn-primary'
          onClick={() => {
            formik.handleSubmit()
          }}
        >
          {intl.formatMessage({id: 'COMMON.SAVE'})}
        </button>
      </Modal.Footer>
    </Modal>
  )
})
